<template>
  <div>
    <!-- <h4 class="header-title">List Quotation</h4> -->
    <vue-element-loading
      :active="isLoadingTable"
      spinner="bar-fade-scale"
      color="#FF6700"
    />
    <div class="row">
      <div class="col-lg-3">
       <b-button
          class="btn btn-create btn-sm"
          style="background: #51C1AD; border: 1px; height: 35px;"
          @click="openModalBuyingDownPayment"
          v-can="'VENDOR_INVOICE_CREATE'"
        >
          <i class="mdi mdi-plus mr-1"></i>
          Create Transaction
        </b-button>
      </div>
      <!-- eslint-disable -->
      <div class="col-lg-3">
        <!-- <label>Status</label> -->
        <select
          v-model="statusType"
          class="form-control"
          name="jobfileType"
          data-live-search="true"
          placeholder="Jobfile Type"
          v-can="'WIP_ENABLE'"
        >
          <option value="All" selected>All</option>
          <option value="WIP">WIP</option>
          <option value="NON_WIP">NON WIP</option>
          <option value="WIP_AVAILABLE">WIP AVAILABLE</option>
          <option value="WIP_NOT_AVAILABLE">WIP NOT AVAILABLE</option>
          <option value="WIP_SUBMITTED_JV">WIP SUBMITTED JV</option>
        </select>
      </div>
      <div class="col-lg-2">
        <b-button
          class="btn btn-create btn-sm"
          style="background: #51C1AD; border: 1px; height: 35px;"
          @click="filterPI"
          v-can="'WIP_ENABLE'"
        >
          <i class="mdi mdi-magnify mr-1"></i>
          Filter Data
        </b-button>
      </div>
      <div :class="'col-sm-12 col-md-12 col-lg-4'">
        <div class="float-lg-right float-left">
          <div class="form-inline">
            <label class="mb-2 mr-sm-2">Search :</label>
            <input
              v-model="filterText"
              type="text"
              class="form-control form-control-sm mb-2"
              placeholder="Search . . ."
              @keyup="doFilter"
            />
            <!-- <button type="button" class="btn btn-primary mb-2 mr-sm-2" @click="doFilter">Go</button> -->
            <!-- <button type="button" class="btn btn-danger mb-2 mr-sm-2" @click="resetFilter">Reset</button> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="table-responsive">
      <vuetable
        ref="vuetable"
        :per-page="perPage"
        :fields="(this.$can('WIP_ENABLE') ? fieldsWIP : fields)"
        :api-url="apiUrl"
        :api-mode="true"
        :http-options="httpOptions"
        :show-sort-icons="true"
        :append-params="moreParams"
        pagination-path
        class="table table-centered table-striped"
        data-path="data"
        @vuetable:loading="loadingTable"
        @vuetable:load-success="loadTableSuccess"
        @vuetable:load-error="loadTableFailed"
        @vuetable:pagination-data="onPaginationData"f
      >
        <template slot="actions" slot-scope="props">
          <div class="table-button-container button-po">
            <span v-show="props.rowData.companyAol === 1" class="mr-1">
              <button class="btn btn-action btn-sm " style="background:rgba(2, 118, 160, 1);" title="Submit to AOL Purchase Invoice" @click="submitToAolPI(props.rowData.id_jobfile_cost)" v-show="props.rowData.id_pi_aol === '0' || props.rowData.id_pi_aol === null || props.rowData.id_pi_aol === 0">
                <img src="@assets/images/icon-export2.svg" id="edit" class="edit" border="0">
              </button>
            </span>
            <span v-can="'SWAP_PPN_VENDOR_INVOICE_ENABLE'" v-if="props.rowData.id_purchase_order === 0 && props.rowData.id_receiveable_item === 0" class="mr-1" @click="showModalSwapItem(props.rowData)" >

                <svg width="45" height="40" viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_13461_14494)">
                <rect x="5" y="5" width="35" height="30" rx="3" fill="#96C151"/>
                </g>
                <path d="M16.5547 15.6875L19.6797 18.8125V16.4688H25.9297C26.3441 16.4688 26.7415 16.6334 27.0345 16.9264C27.3276 17.2194 27.4922 17.6168 27.4922 18.0312C27.4922 18.4457 27.3276 18.8431 27.0345 19.1361C26.7415 19.4291 26.3441 19.5938 25.9297 19.5938H19.6797C18.8509 19.5938 18.056 19.923 17.47 20.509C16.8839 21.0951 16.5547 21.8899 16.5547 22.7188C16.5547 23.5476 16.8839 24.3424 17.47 24.9285C18.056 25.5145 18.8509 25.8438 19.6797 25.8438H25.9297V28.1875L29.0547 25.0625L25.9297 21.9375V24.2812H19.6797C19.2653 24.2812 18.8679 24.1166 18.5748 23.8236C18.2818 23.5306 18.1172 23.1332 18.1172 22.7188C18.1172 22.3043 18.2818 21.9069 18.5748 21.6139C18.8679 21.3209 19.2653 21.1562 19.6797 21.1562H25.9297C26.7585 21.1562 27.5533 20.827 28.1394 20.241C28.7254 19.6549 29.0547 18.8601 29.0547 18.0312C29.0547 17.2024 28.7254 16.4076 28.1394 15.8215C27.5533 15.2355 26.7585 14.9062 25.9297 14.9062H19.6797V12.5625L16.5547 15.6875Z" fill="white"/>
                <defs>
                <filter id="filter0_dd_13461_14494" x="0" y="0" width="45" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="10" operator="erode" in="SourceAlpha" result="effect1_dropShadow_13461_14494"/>
                <feOffset dx="2" dy="2"/>
                <feGaussianBlur stdDeviation="4.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.756863 0 0 0 0 0.678431 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13461_14494"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_dropShadow_13461_14494"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="3"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.756863 0 0 0 0 0.678431 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_13461_14494" result="effect2_dropShadow_13461_14494"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_13461_14494" result="shape"/>
                </filter>
                </defs>
                </svg>
            </span>
            <!-- <b-dropdown id="dropdown-1" dropup  size="sm" variant="btn-drop" style="padding:0px !important; " v-show="props.rowData.id_pi_aol !== null || props.rowData.companyAol === 0"> -->
            <b-dropdown id="dropdown-1" right size="sm" variant="btn-drop">
              <template v-slot:button-content>
                <svg width="30pt" height="22pt" version="1.1" viewBox="350 90 1 400" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g>
                    <path fill="#51c1ad"
                      d="m278.77 280c0 18.988-15.395 34.383-34.387 34.383-18.988 0-34.383-15.395-34.383-34.383s15.395-34.383 34.383-34.383c18.992 0 34.387 15.395 34.387 34.383" />
                    <path fill="#51c1ad"
                      d="m384.38 280c0 18.988-15.395 34.383-34.383 34.383s-34.383-15.395-34.383-34.383 15.395-34.383 34.383-34.383 34.383 15.395 34.383 34.383" />
                    <path fill="#51c1ad"
                      d="m490 280c0 18.988-15.395 34.383-34.383 34.383-18.992 0-34.387-15.395-34.387-34.383s15.395-34.383 34.387-34.383c18.988 0 34.383 15.395 34.383 34.383" />

                  </g>
                </svg>

              </template>
              <!-- <b-dropdown-header id="dropdown-header-1"> <i class="fas fa-download"></i> Download</b-dropdown-header> -->
              <b-dropdown-item v-can="'VENDOR_INVOICE_UPDATE'" @click="editInfoBuying(props.rowData.id_jobfile_cost)">
                <!-- <button v-can="'VENDOR_INVOICE_UPDATE'" class="btn btn-action mr-1" style="background: #51C1AD;" href="javascript:void(0)" title="View Detail" @click="editInfoBuying(props.rowData.id_jobfile_cost)"> -->
                   <i class="fas fa-edit"></i> Edit
                <!-- </button> -->
              </b-dropdown-item>
              <b-dropdown-item v-can="'VENDOR_INVOICE_DELETE'" @click="deleteBuyingData(props.rowData.id_jobfile_cost)">
                <!-- <button v-can="'VENDOR_INVOICE_UPDATE'" class="btn btn-action mr-1" style="background: #51C1AD;" href="javascript:void(0)" title="View Detail" @click="editInfoBuying(props.rowData.id_jobfile_cost)"> -->
                   <i class="fas fa-trash"></i> Delete
                <!-- </button> -->
              </b-dropdown-item>
              <b-dropdown-item  v-can="'WIP_ENABLE'" @click="openModalJournalVoucher(props.rowData.id_jobfile_cost)">
                <!-- <button v-can="'VENDOR_INVOICE_UPDATE'" class="btn btn-action mr-1" style="background: #51C1AD;" href="javascript:void(0)" title="View Detail" @click="editInfoBuying(props.rowData.id_jobfile_cost)"> -->
                   <i class="fas fa-paper-plane"></i> Send To Journal Voucher
                <!-- </button> -->
              </b-dropdown-item>

              <b-dropdown-item  href="#" @click.prevent="getSettlementAdvance(props.rowData)">
                <i class="fas fa-download"></i> Settlement Advance
              </b-dropdown-item>
              <b-dropdown-item  href="#" @click.prevent="getVendorExpense(props.rowData)">

                <i class="fas fa-download"></i> Vendor Expense
              </b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

            </b-dropdown>


          </div>
        </template>
        <template slot="count_item" slot-scope="props">
          <a
              href="javascript:void(0);"
              class="action-icon  mr-1"
              @click="onAction('DetailPI', props.rowData, props.rowIndex)"
              style="font-size: 12px;"
            >
            <div>
              {{props.rowData.count_item}} Item
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z" fill="#FFA800"/>
              </svg>
            </div>
          </a>
        </template>
        <div slot="statusWIP" slot-scope="props" class="button-po">
					<div v-html="props.rowData.statusWIP"></div>
				</div>
        <template slot="counting_number" slot-scope="props">
          <span>{{ ((pageActive - 1) * perPage) + props.rowIndex + 1 }}</span>
        </template>
        <template slot="grandTotal" slot-scope="props">
          <div style="width: 100px;">
            <span>{{ props.rowData.symbol_default }}. {{ formatRupiah(props.rowData.grandTotal.toString(), '') }}</span>
          </div>
        </template>
        <template slot="ppn_cost" slot-scope="props">
          <div style="width: 70px;">
            <span>{{ props.rowData.ppn_cost }} %</span>
          </div>
        </template>
        <template slot="pph_cost" slot-scope="props">
          <div style="width: 70px;">
            <span>{{ props.rowData.pph_cost }} %</span>
          </div>
        </template>
        <template slot="JVNumber" slot-scope="props">
          <div>
            <a :href="urlCi + `/admin/journal_voucher`" style="font-weight: 600;">{{props.rowData.JVNumber}}</a>
          </div>
        </template>
        <template slot="number_ori" slot-scope="props">
          <div >
            <a :href="`/purchase-order/detail-applied-transaction/${props.rowData.id_purchase_order}`" v-show="props.rowData.count_reff <= 1 && props.rowData.id_purchase_order !== null && props.rowData.id_purchase_order !== undefined && props.rowData.id_purchase_order !== 0 && props.rowData.id_purchase_order !== ''">
              <span>{{props.rowData.number_ori}}</span>
            </a>
            <a :href="`/ReceivableItem/detail-applied-transaction/${props.rowData.id_receiveable_item}`" v-show="props.rowData.count_reff <= 1 && props.rowData.id_receiveable_item !== null && props.rowData.id_receiveable_item !== undefined && props.rowData.id_receiveable_item !== 0 && props.rowData.id_receiveable_item !== ''">
              <span>{{props.rowData.number_ori}}</span>
            </a>

            <span v-show="props.rowData.count_reff <= 1 && (props.rowData.id_receiveable_item === null || props.rowData.id_receiveable_item === undefined || props.rowData.id_receiveable_item === 0 || props.rowData.id_receiveable_item === '') && (props.rowData.id_purchase_order === null || props.rowData.id_purchase_order === undefined || props.rowData.id_purchase_order === 0 || props.rowData.id_purchase_order === '')">{{ props.rowData.number_ori }}</span>
            <a
              href="javascript:void(0);"
              class="action-icon  mr-1"
              @click="showOriginalNumber(props.rowData)"
              style="font-size: 12px;"
            >
              <div v-show="props.rowData.count_reff > 1">
                Show Original Number
                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z" fill="#FFA800"/>
                </svg>
              </div>
            </a>
          </div>
        </template>
      </vuetable>

    </div>
    <!-- end table -->

    <!-- pagination -->
    <div class="row">
      <div class="col-md-5">
        <div class="row no-gutters">
          <div class="col-lg-3">
            <div class="form-group float-lg-left">
              <select
                v-model="perPage"
                class="form-control form-control-sm"
                @change="onChangeTableLength"
              >
                <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                  {{ opt }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-8">
            <vuetable-pagination-info
              ref="paginationInfo"
              class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              "
            >
            </vuetable-pagination-info>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-7">
        <vuetable-pagination
          ref="pagination"
          :css="css.pagination"
          class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          "
          @vuetable-pagination:change-page="onChangePage"
        >
        </vuetable-pagination>
      </div>
    </div>
    <!-- end pagination -->

    <b-modal
        id="modalTransactionType"
        size="sm"
        title="Create Vendor Invoice / Settlement Transaction"
        style="max-width: 90% !important;"
        @ok="chooseTransaction"
        ok-title="Next"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
      >
      <div>
        <div style="display: grid; justify-content: center; align-items: center;text-align:center;">
          <b-button
            class="btn btn-create btn-sm"
            style="background: #51C1AD; border: 1px; height: 35px;"
            @click="openModalBuying"
            v-can="'VENDOR_INVOICE_CREATE'"
          >
            <i class="mdi mdi-plus mr-1"></i>
            Create New
          </b-button>
          <br>
          <span class="mt-3">or</span>
        </div>
        <br>
        <div class="form-group">
          <label for="">Create From</label>
          <v-select v-model="createFrom" :options="typeCopies" :reduce="(option) => option.id" label="name">
          </v-select>
        </div>
      </div>
    </b-modal>

    <b-modal
        id="modalBuyingDownPayment"
        size="xl"
        title="Choose Transaction"
        style="max-width: 90% !important;"
        @ok="submitBuying"
        ok-title="Submit"
        no-close-on-backdrop
        no-close-on-esc
        @ok-disabled="isLoadingForm"
      >
      <div>
        <form-wizard :start-index="0" ref="wizard" color="#51C1AD" style="margin-top: 2rem;" @on-complete="onComplete" :on-complete-disabled="true">
          <!-- Tab Content 1 -->
          <tab-content title="Choose Transaction" :before-change="validateBuyingDownPayment">
              <!--  -->
              <template>
                <div class="row">
                  <div class="col-lg-3">
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-9">
                    <div class="float-lg-right float-left">
                      <div class="form-inline">
                        <label class="mb-2 mr-sm-2">Search :</label>
                        <input
                          v-model="filterTextDP"
                          type="text"
                          class="form-control form-control-sm mb-2"
                          placeholder="Search . . ."
                          @keyup="doFilterDP"
                        />
                        <!-- <button type="button" class="btn btn-primary mb-2 mr-sm-2" @click="doFilter">Go</button> -->
                        <!-- <button type="button" class="btn btn-danger mb-2 mr-sm-2" @click="resetFilter">Reset</button> -->
                      </div>
                    </div>
                  </div>
                </div>
                <vuetable
                  ref="vuetableDP"
                  :per-page="perPageDP"
                  :fields="fieldsDP"
                  :api-url="apiUrlDP"
                  :api-mode="true"
                  :http-options="httpOptionsDP"
                  :show-sort-icons="true"
                  :append-params="moreParamsDP"
                  pagination-path
                  class="table table-centered table-striped"
                  data-path="data"
                  @vuetable:loading="loadingTableDP"
                  @vuetable:load-success="loadTableSuccessDP"
                  @vuetable:load-error="loadTableFailedDP"
                  @vuetable:pagination-data="onPaginationDataDP"
                >
                  <template slot="count_pi" slot-scope="props">
                    <a
                        href="javascript:void(0);"
                        class="action-icon  mr-1"
                        @click="onAction('DetailPIDP', props.rowData, props.rowIndex)"
                        style="font-size: 12px;"
                      >
                      <div style="color: #67C8B7;">
                        {{props.rowData.count_pi}} PI
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z" fill="#67C8B7"/>
                        </svg>
                      </div>
                    </a>
                  </template>
                  <template slot="down_payment" slot-scope="props">
                    <div style="width: 70px;">
                      <span>{{ props.rowData.symbol }}. {{ formatRupiah(props.rowData.down_payment.toString(), '') }}</span>
                    </div>
                  </template>
                  <template slot="chk_select" slot-scope="props">
                    <b-form-checkbox @change="selectTrx(props.rowData.id, props.rowData.id_vendor, props.rowData.down_payment, props.rowData.date, props.rowData.ppn)" :checked="trxSelected === props.rowData.id" :disabled="!((props.rowData.totalClaimed < props.rowData.grandTotal) && (props.rowData.id_aol_pp !== '0' && props.rowData.id_aol_pp !== null && props.rowData.id_aol_pp !== 0) && (props.rowData.id_aol !== '0' && props.rowData.id_aol !== null && props.rowData.id_aol !== 0))"></b-form-checkbox>
                  </template>
                </vuetable>
              </template>

              <!-- pagination -->
              <div class="row">
                <div class="col-md-5">
                  <div class="row no-gutters">
                    <div class="col-lg-3">
                      <div class="form-group float-lg-left">
                        <select
                          v-model="perPageDP"
                          class="form-control form-control-sm"
                          @change="onChangeTableLengthDP"
                        >
                          <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                            {{ opt }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <vuetable-pagination-info
                        ref="paginationInfoDP"
                        class="
                          justify-content-center justify-content-lg-start
                          text-center text-lg-left
                        "
                      >
                      </vuetable-pagination-info>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <vuetable-pagination
                    ref="paginationDP"
                    :css="css.pagination"
                    class="
                      pagination pagination-rounded
                      justify-content-center justify-content-lg-end
                    "
                    @vuetable-pagination:change-page="onChangePageDP"
                  >
                  </vuetable-pagination>
                </div>
              </div>
              <!-- end pagination -->

              <div class="wizard-footer-left">
                  <b-button class="btn-cancel" style="position: absolute; margin-top: 11px;"
                      @click.prevent="closeModalDownPayment">
                      Cancel
                  </b-button>
              </div>

          </tab-content>
          <!-- End Tab Content 1 -->

          <!-- Tab Content 2 -->
          <tab-content title="Data Send to Vendor Invoice / Settlement" :before-change="validateBuying">
              <template>
                <b-form>
                  <div class="information-dp mt-2">
                    <img src="@assets/images/money-bag.png" width="24" />
                    <span class="ml-2" style="color: #898989;">Down Payment : </span>
                    <span class="ml-1" style="color: #565656; font-weight: bold;">{{formatRupiah(this.dataBuyingCopy.downPayment.toString(), this.dataBalanceDP.data !== undefined ? this.dataBalanceDP.data.symbol : '')}}</span>
                    <span class="ml-3" style="color: #898989;">Date : </span>
                    <span class="ml-1" style="color: #565656; font-weight: bold;">{{this.dataBuyingCopy.dateDownPayment}}</span>
                  </div>
                  <div style="padding: 20px;">
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="form-row">
                          <div class="col-md-2">
                            <label for="">Number <span aria-required="true" class="text-danger"> * </span></label>
                            <div class="input-group">
                              <v-select v-show="!isManualNumberBuyingCopy" v-model="dataBuyingCopy.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;">
                              </v-select>
                              <input v-show="isManualNumberBuyingCopy" v-model="dataBuyingCopy.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                              <b-input-group-append style="width: 19%; float: right;">
                                <b-button
                                  class="btn btn-create btn-sm"
                                  style="background: #51C1AD;"
                                  @click="triggerManualNumberBuyingCopy"
                                >
                                  <i v-show="!isManualNumberBuyingCopy" class="mdi mdi-pencil"></i>
                                  <i v-show="isManualNumberBuyingCopy" class="mdi mdi-close"></i>
                                </b-button>
                              </b-input-group-append>
                            </div>
                          </div>
                          <!-- <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Vendor</label>
                              <v-select v-model="dataBuyingCopy.vendor" :options="listVendor" :reduce="(option) => option.id" label="name" @input="changeVendor">
                              </v-select>
                            </div>
                          </div> -->
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Invoice <span aria-required="true" class="text-danger"> * </span></label>
                              <input v-model="dataBuyingCopy.invoice" type="text" class="form-control form-control-sm" placeholder="Invoice" required />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Term <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTermBuying">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Currency <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.currency" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" @input="changeCurrency" disabled>
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <label for="">Date (yyyy/mm/dd) <span aria-required="true" class="text-danger"> * </span></label>
                            <date-picker v-model="dataBuyingCopy.date" :format="momentFormatBuying" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglBuying" >
                            </date-picker>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">PPN <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" @input="changePpnBuying">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2" v-if="this.companyIsUsingPPH === 1">
                            <div class="form-group">
                              <label for="">PPH <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.pph" :reduce="(option) => option.id" :options="listPPH" label="description" @input="changePphBuying">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0)">
                            <div class="form-group">
                              <label for="">Document Code <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.docCode" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCodeBuying" item-text="text" item-value="code">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0 && showTaxType === true)">
                            <div class="form-group">
                              <label for="">Tax Type <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.taxType" :options="listTaxTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Remarks</label>
                              <input v-model="dataBuyingCopy.remarks" type="text" class="form-control form-control-sm" placeholder="" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showTaxDetail">
                            <label for=""></label>
                            <div class="form-check mt-2">
                              <input id="setInvoiceTax" v-model="isTaxDateChecked" @input="activeTax($event)" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                              <label for="setInvoiceTax">Set Invoice Tax Date & No</label>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showTaxInput">
                            <div class="form-group">
                              <label for="">Tax Date</label>
                              <date-picker v-model="dataBuyingCopy.taxDate" :format="dateFormatingTax" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglTaxBuying" >
                              </date-picker>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showTaxInput">
                            <div class="form-group">
                              <label for="">Tax No</label>
                              <input v-model="dataBuyingCopy.taxNo" type="text" class="form-control form-control-sm" placeholder="Tax Number" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showRate">
                            <div class="form-group">
                              <label for="">Commercial Rate <span aria-required="true" class="text-danger"> * </span></label>
                              <input v-model="dataBuyingCopy.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required @keypress="isNumber($event)" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showRate">
                            <div class="form-group">
                              <label for="">Fiscal Rate <span aria-required="true" class="text-danger"> * </span></label>
                              <input v-model="dataBuyingCopy.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required @keypress="isNumber($event)" />
                            </div>
                          </div>
                          <!-- <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Attn</label>
                              <input v-model="dataBuyingCopy.pic_name" type="text" class="form-control form-control-sm" placeholder="Attn" required />
                            </div>
                          </div> -->
                          <div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0)">
                            <label for=""></label>
                            <div class="form-check mt-2">
                              <input id="inclusiveTax" v-model="dataBuyingCopy.inclusiveTax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                              <label for="inclusiveTax">Inclusive Tax</label>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div v-for="(isiDetail, d) in itemBuyingCopy" :key="d" class="row">
                        <!-- <div class="row"> -->

                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Jobfile <span aria-required="true" class="text-danger"> * </span></label>
                            <v-select v-model="isiDetail.idJobfile" :options="listJobfile" :reduce="(option) => option.id" label="jobfile_no" item-text="jobfile_no" item-value="id" required>
                              </v-select>
                            <!-- <select v-model="isiDetail.idJobfile" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                              <option v-for="(isi, key) in listJobfile" :key="key" :value="isi.id">
                                {{isi.jobfile_no}}
                              </option>
                            </select> -->
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Item <span aria-required="true" class="text-danger"> * </span></label>
                            <v-select v-model="isiDetail.item" :options="listItem" :reduce="(option) => option.id" label="item_name" item-text="item_name" item-value="id" required>
                              </v-select>
                            <!-- <select v-model="isiDetail.item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                              <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                                {{isi.item_name}}
                              </option>
                            </select> -->
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Qty <span aria-required="true" class="text-danger"> * </span></label>
                            <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" />
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Price <span aria-required="true" class="text-danger"> * </span></label>
                            <input v-model="isiDetail.price" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" />
                          </div>
                        </div>
                        <div style="width:130px; margin-right:14px">
                          <div class="form-group">
                            <label for="">Remark</label>
                            <input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required />
                          </div>
                        </div>
                        <!-- <div class="col-md-2">
                          <div class="form-group">
                            <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruck(d)">
                            <label for="includeTax" style="margin-bottom: 4px;">
                              Department
                            </label>
                            <a v-show="d || (!d && itemBuyingCopy.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d, 'createNewFromDp')" style="float: right;"> <i class="fas fa-trash"></i>
                            </a> &nbsp;
                            <a v-show="d === itemBuyingCopy.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1" @click="addDetail('createNewFromDp')" style="float: right;"><i class="fas fa-plus"></i></a>
                            <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                              <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                                {{isi.truck_no}}
                              </option>
                            </select>
                          </div>
                        </div> -->
                        <div style="width:210px">
                          <div class="form-group">
                            <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruck(d)">
                            <label for="includeTax" style="margin-bottom: 4px;">
                              Department
                            </label>
                            <a v-show="d || (!d && itemBuyingCopy.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d,'createNewFromDp')" style="float: right;"> <i class="fas fa-trash"></i>
                            </a> &nbsp;
                            <a v-show="d === itemBuyingCopy.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1" @click="addDetail('createNewFromDp')" style="float: right;"><i class="fas fa-plus"></i></a>
                            <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                              <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                                {{isi.truck_no}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- <div class="col-md-1 pt-1">
                          <br>
                          <a v-show="d || (!d && itemBuying.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d)"> <i class="fas fa-trash"></i>
                          </a> &nbsp;
                          <a v-show="d === itemBuying.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm" @click="addDetail('ok')"><i class="fas fa-plus"></i></a>
                          &nbsp;
                        </div> -->
                      </div>
                    </div>

                    <!-- <div class="row">
                      <div class="col-md-12">
                        <label for=""></label>
                        <div class="form-check mt-2 p-0">
                          <label for="inclusiveTax">Copy Transaction</label>
                          <input id="inclusiveTax" v-model="isCopyTransaction" type="checkbox" true-value="1" false-value="0" class="form-check-input ml-2" style="width: 15px; height: 15px;">
                        </div>
                      </div>
                    </div>

                    <div class="row mt-3" v-show="isCopyTransaction === '1'">
                      <div class="col-12">
                        <div class="form-row">
                          <div class="col-md-2">
                            <div class="form-group">
                              <v-select v-model="typeCopy" :options="listType" :reduce="(option) => option.id" label="name" disabled>
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <v-select v-model="joborderCopy" taggable :reduce="(option) => option.id" :options="listJobOrderCopy" label="name" item-text="name" item-value="id">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <v-select v-model="numberCopy" taggable :options="listNumberCopy" :reduce="(option) => option.id" label="code" item-text="code" item-value="id">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <input v-model="remarksCopy" type="text" class="form-control form-control-sm" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </b-form>
              </template>
          </tab-content>
          <!-- End Tab Content 2 -->

          <!-- Tab Content 3 -->
          <tab-content title="Create Purchase Payment Tax" v-if="isEnableTabTax && parseFloat(this.dataBuyingDownPayment.ppn) > 0" :before-change="validatePurchasePaymentTax">
              <template>
                <b-form>
                  <div class="row mt-3">
                    <div class="col-12">
                      <div class="form-row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="">Bank</label>
                            <v-select v-model="dataBuyingPurchasePaymentTax.bank" :options="listItemBank" :reduce="(option) => option.id" label="item_name" @input="changeBankTax">
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Payment Method</label>
                            <v-select v-model="dataBuyingPurchasePaymentTax.paymentMethod" :options="paymentMethods" :reduce="(option) => option.id" label="name">
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <label for="">Number</label>
                          <div class="input-group">
                            <v-select v-show="!isManualNumberBuyingPurchasePaymentTax" v-model="dataBuyingPurchasePaymentTax.number" :reduce="(option) => option.id" :options="listAutonumberPPTax" label="name" style="width: 81%; float: left;">
                            </v-select>
                            <input v-show="isManualNumberBuyingPurchasePaymentTax" v-model="dataBuyingPurchasePaymentTax.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                            <b-input-group-append style="width: 19%; float: right;">
                              <b-button
                                class="btn btn-create btn-sm"
                                style="background: #51C1AD;"
                                @click="triggerManualNumberBuyingPurchasePaymentTax"
                              >
                                <i v-show="!isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-pencil"></i>
                                <i v-show="isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-close"></i>
                              </b-button>
                            </b-input-group-append>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <label for="">Date (yyyy/mm/dd)</label>
                          <date-picker v-model="dataBuyingPurchasePaymentTax.date" :format="momentFormatPPTax" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglPPTax" >
                          </date-picker>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Discount Account</label>
                            <v-select v-model="dataBuyingPurchasePaymentTax.discountAccount" taggable :reduce="(option) => option.id" :options="listItemBank" label="item_name" @input="changeDiscTax" item-text="item_name" item-value="id">
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-1">
                          <div class="form-group">
                            <label for="">.</label>
                            <v-select v-model="dataBuyingPurchasePaymentTax.discountType" taggable :reduce="(option) => option.id" :options="discountTypes" label="name" item-text="name" item-value="id">
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Discount</label>
                            <input v-model="dataBuyingPurchasePaymentTax.discount" type="text" class="form-control form-control-sm" placeholder="Discount" required />
                          </div>
                        </div>
                        <!-- <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Currency</label>
                            <v-select v-model="dataBuyingPurchasePaymentTax.currencyBank" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" disabled>
                            </v-select>
                          </div>
                        </div> -->
                        <!-- <div class="col-md-2" v-show="showRatePP">
                          <div class="form-group">
                            <label for="">Commercial Rate</label>
                            <input v-model="dataBuyingPurchasePaymentTax.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required />
                          </div>
                        </div>
                        <div class="col-md-2" v-show="showRatePP">
                          <div class="form-group">
                            <label for="">Fiscal Rate</label>
                            <input v-model="dataBuyingPurchasePaymentTax.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required />
                          </div>
                        </div> -->
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Remarks</label>
                            <textarea v-model="dataBuyingPurchasePaymentTax.remarks" class="form-control form-control-sm" placeholder="Remarks" cols="30" rows="3"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </template>
          </tab-content>
          <!-- End Tab Content 2 -->

          <button slot="prev" class="btn-cancel">Previous</button>
          <button slot="next" class="btn-next">Next</button>


          <button slot="finish" class="btn-finish" :disabled="isLoadingForm">Finish</button>
        </form-wizard>
      </div>
    </b-modal>
    <!-- Modal Copy From Purchase Order -->
    <b-modal
        id="modalPurchaseOrder"
        size="xl"
        title="Choose Transaction"
        style="max-width: 90% !important;"
        @ok="submitBuying"
        ok-title="Submit"
        no-close-on-backdrop
        no-close-on-esc
        @ok-disabled="isLoadingForm"
      >
      <div>
        <form-wizard :start-index="0" ref="wizard" color="#51C1AD" style="margin-top: 2rem;" @on-complete="onCompletePO" :on-complete-disabled="true">
          <!-- Tab Content 1 -->
          <tab-content title="Data Send to Vendor Invoice / Settlement" :before-change="validateBuying">
              <template>
                <b-form>
                  <div style="padding: 20px;">
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="form-row">
                          <div class="col-md-2">
                            <label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
                            <div class="input-group">
                              <v-select v-show="!isManualNumberBuyingCopy" v-model="dataBuyingCopy.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;">
                              </v-select>
                              <input v-show="isManualNumberBuyingCopy" v-model="dataBuyingCopy.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                              <b-input-group-append style="width: 19%; float: right;">
                                <b-button
                                  class="btn btn-create btn-sm"
                                  style="background: #51C1AD;"
                                  @click="triggerManualNumberBuyingCopy"
                                >
                                  <i v-show="!isManualNumberBuyingCopy" class="mdi mdi-pencil"></i>
                                  <i v-show="isManualNumberBuyingCopy" class="mdi mdi-close"></i>
                                </b-button>
                              </b-input-group-append>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Vendor <span class="text-danger" aria-required="true"> * </span></label>
                              <v-select v-model="dataBuyingCopy.vendor" :options="listVendor" :reduce="(option) => option.id" label="name" @input="changeVendor">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Invoice <span class="text-danger" aria-required="true"> * </span></label>
                              <input v-model="dataBuyingCopy.invoice" type="text" class="form-control form-control-sm" placeholder="Invoice" required />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Term <span class="text-danger" aria-required="true"> * </span></label>
                              <v-select v-model="dataBuyingCopy.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTermBuying">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Currency <span class="text-danger" aria-required="true"> * </span></label>
                              <v-select v-model="dataBuyingCopy.currency" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" @input="changeCurrency">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
                            <date-picker v-model="dataBuyingCopy.date" :format="momentFormatBuying" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglBuying" >
                            </date-picker>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">PPN <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" @input="changePpnBuying">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2" v-if="this.companyIsUsingPPH === 1">
                            <div class="form-group">
                              <label for="">PPH <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.pph" :reduce="(option) => option.id" :options="listPPH" label="description" @input="changePphBuying">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0)">
                            <div class="form-group">
                              <label for="">Document Code</label>
                              <v-select v-model="dataBuyingCopy.docCode" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCodeBuying" item-text="text" item-value="code">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0 && showTaxType === true)">
                            <div class="form-group">
                              <label for="">Tax Type</label>
                              <v-select v-model="dataBuyingCopy.taxType" :options="listTaxTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Remarks</label>
                              <input v-model="dataBuyingCopy.remarks" type="text" class="form-control form-control-sm" placeholder="" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showTaxDetail">
                            <label for=""></label>
                            <div class="form-check mt-2">
                              <input id="setInvoiceTax" v-model="isTaxDateChecked" @input="activeTax($event)" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                              <label for="setInvoiceTax">Set Invoice Tax Date & No</label>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showTaxInput">
                            <div class="form-group">
                              <label for="">Tax Date</label>
                              <date-picker v-model="dataBuyingCopy.taxDate" :format="dateFormatingTax" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglTaxBuying" >
                              </date-picker>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showTaxInput">
                            <div class="form-group">
                              <label for="">Tax No</label>
                              <input v-model="dataBuyingCopy.taxNo" type="text" class="form-control form-control-sm" placeholder="Tax Number" />
                            </div>
                          </div>
                          
                          
                          <div class="col-md-2" v-show="showRate">
                            <div class="form-group">
                              <label for="">Commercial Rate <span class="text-danger" aria-required="true"> * </span></label>
                              <input v-model="dataBuyingCopy.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required @keypress="isNumber($event)" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showRate">
                            <div class="form-group">
                              <label for="">Fiscal Rate <span class="text-danger" aria-required="true"> * </span></label>
                              <input v-model="dataBuyingCopy.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required @keypress="isNumber($event)" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0)">
                            <label for=""></label>
                            <div class="form-check mt-2">
                              <input id="inclusiveTax" v-model="dataBuyingCopy.inclusiveTax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                              <label for="inclusiveTax">Inclusive Tax</label>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </template>

              <div class="wizard-footer-left">
                <b-button class="btn-cancel" style="position: absolute; margin-top: 11px;"
                    @click.prevent="closeModalPurchaseOrder">
                    Cancel
                </b-button>
              </div>
          </tab-content>
          <!-- End Tab Content 1 -->

          <!-- Tab Content 2 -->
          <tab-content title="Choose Transaction" :before-change="validateBuyingDownPayment">
              <!--  -->
              <template>
                <div class="row">
                  <div class="col-lg-3">
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-9">
                    <div class="float-lg-right float-left">
                      <div class="form-inline">
                        <label class="mb-2 mr-sm-2">Search :</label>
                        <input
                          v-model="filterTextPO"
                          type="text"
                          class="form-control form-control-sm mb-2"
                          placeholder="Search . . ."
                          @keyup="doFilterPO"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Table -->
                <div class="table-responsive">
                  <vuetable ref="vuetablePO" :per-page="perPagePO" :fields="fieldsPO" :api-url="apiUrlPO" :api-mode="true"
                    :http-options="httpOptionsPO" :show-sort-icons="true" :append-params="moreParamsPO" pagination-path
                    class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTablePO"
                    @vuetable:load-success="loadTableSuccessPO" @vuetable:load-error="loadTableFailedPO"
                    @vuetable:pagination-data="onPaginationDataPO">

                    <div slot="item" slot-scope="props" class="button-po">
                      <a :href="`/purchase-order/detail/${props.rowData.id}`" class="mr-1"> {{
                          props.rowData.item
                      }}
                        Item

                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                            fill="#FFA800" />
                        </svg>
                      </a>
                    </div>
                    <div slot="applied_transaction" slot-scope="props" class="button-po">
                      <a :href="`/purchase-order/detail-applied-transaction/${props.rowData.id}`" class="mr-1"> {{
                          props.rowData.applied_transaction
                      }}
                        Transaction

                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                            fill="#FFA800" />
                        </svg>
                      </a>
                    </div>
                    <template slot="chk_select" slot-scope="props">
                      <!-- <b-form-checkbox :id="'receiveItem_' + props.rowData.id" class="chkReceiveItem" :checked="checkedTrx(props)" @change="selectTrx(props.rowData.id, props.rowData.id_vendor, props.rowData.down_payment, props.rowData.date, props.rowData.ppn)" :disabled="!((props.rowData.aol_id !== '0' && props.rowData.aol_id !== null && props.rowData.aol_id !== 0) && props.rowData.available_to_copy)"></b-form-checkbox> -->
                      <b-form-checkbox :id="'purchaseOrder_' + props.rowData.id" @change="selectTrx(props.rowData.id, props.rowData.id_vendor, props.rowData.down_payment, props.rowData.date, props.rowData.ppn)" :checked="checkedTrxPO(props)" :disabled="!((props.rowData.aol_id !== '0' && props.rowData.aol_id !== null && props.rowData.aol_id !== 0) && props.rowData.available_to_copy)"></b-form-checkbox>
                    </template>
                  </vuetable>
                </div>
                <!-- end table -->
              </template>

              <!-- pagination -->
              <div class="row">
                <div class="col-md-5">
                  <div class="row no-gutters">
                    <div class="col-lg-3">
                      <div class="form-group float-lg-left">
                        <select
                          v-model="perPagePO"
                          class="form-control form-control-sm"
                          @change="onChangeTableLengthPO"
                        >
                          <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                            {{ opt }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <vuetable-pagination-info
                        ref="paginationInfoPO"
                        class="
                          justify-content-center justify-content-lg-start
                          text-center text-lg-left
                        "
                      >
                      </vuetable-pagination-info>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <vuetable-pagination
                    ref="paginationPO"
                    :css="css.pagination"
                    class="
                      pagination pagination-rounded
                      justify-content-center justify-content-lg-end
                    "
                    @vuetable-pagination:change-page="onChangePagePO"
                  >
                  </vuetable-pagination>
                </div>
              </div>
              <!-- end pagination -->
          </tab-content>
          <!-- End Tab Content 2 -->

          <!-- Tab Content 3 -->
					<tab-content title="Item Send to Vendor Invoice / Settlement" :before-change="validateBuyingItemPO">
						<template>
							<b-form>
								<div class="mt-3">
									<div v-for="(isiDetail, d) in itemBuyingCopy" :key="d" class="row">
										<!-- <div class="col-md-1" style="display: flex;justify-content: center;align-items: center;">
											<input type="checkbox" v-model="isiDetail.isChecked" true-value="1" false-value="0" @change="selectItem(d)">
										</div> -->
										<div class="col-md-2" style="display: flex;justify-content: center;align-items: center; margin-right: 20px">
                      <input type="checkbox" :id="`selectedPO${d}`" v-model="isiDetail.isChecked" true-value="1" false-value="0" @change="selectItem(d)" :disabled="isiDetail.stockQty === 0 && (isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)" v-show="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)">
                      <div class="form-group ml-2" style="width: 100%;">
                        <label for="">Jobfile <span class="text-danger" aria-required="true"> * </span></label>
                        <v-select v-model="isiDetail.idJobfile" :options="listJobfile" :reduce="(option) => option.id" label="jobfile_no" item-text="jobfile_no" item-value="id" required :disabled="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)" >
                        </v-select>
                      </div>
										</div>
										<div class="col-md-2">
										<div class="form-group">
											<label for="">Item <span class="text-danger" aria-required="true"> * </span></label>
											<v-select v-model="isiDetail.item" :options="listItem" :reduce="(option) => option.id" label="item_name" item-text="item_name" item-value="id" required :disabled="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)" >
											</v-select>
										</div>
										</div>
										<div class="col-md-1" v-show="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)">
										<div class="form-group">
											<label for="">Stock Item</label>
											<input v-model="isiDetail.stockQty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" disabled />
										</div>
										</div>
										<div class="col-md-1">
										<div class="form-group">
											<label for="">Qty <span class="text-danger" aria-required="true"> * </span></label>
											<input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" @keyup="isChangeQty(d)" :disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined) ? true : false" />
										</div>
										</div>
                    <div class="col-md-2" v-show="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)">
										<div class="form-group">
											<label for="">Amount <span class="text-danger" aria-required="true"> * </span></label>
											<input v-model="isiDetail.actualAmount" type="text" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" :disabled="true" />
										</div>
										</div>
										<div class="col-md-2">
										<div class="form-group">
											<label for="">Actual Amount</label>
											<input v-model="isiDetail.price" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" @keyup="isChangePrice(d)" :disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined) ? true : false" />
										</div>
										</div>
                    <div class="col-md-1" style="width:150px">
										<div class="form-group">
											<label for="">Sub Total</label>
											<input v-model="isiDetail.subTotal" type="text" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" disabled />
										</div>
										</div>
										<div class="col-md-2">
										<div class="form-group">
											<label for="">Remark</label>
											<input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required :disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined) ? true : false" />
										</div>
										</div>
										<div class="col-md-2">
										<div class="form-group">
											<input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruckCopy(d)" :disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined) ? true : false">
											<label for="includeTax" style="margin-bottom: 4px;">
											Department
											</label>
											<a v-show="(d || (!d && itemBuyingCopy.length > 1) && (isiDetail.id_detail_po === 0 || isiDetail.id_detail_po === null || isiDetail.id_detail_po === undefined))" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d, 'createNewFromPO')" style="float: right;"> <i class="fas fa-trash"></i>
											</a> &nbsp;
												<a v-show="d === itemBuyingCopy.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1" @click="addDetail('createNewFromPO')" style="float: right;"><i class="fas fa-plus"></i></a>
											<select v-show="isiDetail.isTruck.toString() === '1'" v-model="isiDetail.truck" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required :disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined) ? true : false">
											<option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
												{{isi.truck_no}}
											</option>
											</select>
										</div>
										</div>
									</div>
                  <div class="col-11 d-flex">
									<div class="form-row ml-auto mb-1 mr-3">
										<!-- Total : {{ this.totalOfSubTotal }} -->
									</div>
								</div>
								</div>
							</b-form>
						</template>
					</tab-content>
					<!-- End Tab Content 3 -->

          <button slot="prev" class="btn-cancel">Previous</button>
          <button slot="next" class="btn-next">Next</button>


          <button slot="finish" class="btn-finish" :disabled="isLoadingForm">Finish</button>
        </form-wizard>
      </div>
    </b-modal>
    <!-- Modal Copy From Receive Item -->
    <b-modal
        id="modalReceiveItem"
        size="xl"
        title="Choose Transaction"
        style="max-width: 90% !important;"
        @ok="submitBuying"
        ok-title="Submit"
        no-close-on-backdrop
        no-close-on-esc
        @ok-disabled="isLoadingForm"
      >
      <div>
        <form-wizard :start-index="0" ref="wizard" color="#51C1AD" style="margin-top: 2rem;" @on-complete="onCompletePO" :on-complete-disabled="true">
          <!-- Tab Content 1 -->
          <tab-content title="Data Send to Vendor Invoice / Settlement" :before-change="validateBuying">
              <template>
                <b-form>
                  <div style="padding: 20px;">
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="form-row">
                          <div class="col-md-2">
                            <label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
                            <div class="input-group">
                              <v-select v-show="!isManualNumberBuyingCopy" v-model="dataBuyingCopy.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;">
                              </v-select>
                              <input v-show="isManualNumberBuyingCopy" v-model="dataBuyingCopy.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                              <b-input-group-append style="width: 19%; float: right;">
                                <b-button
                                  class="btn btn-create btn-sm"
                                  style="background: #51C1AD;"
                                  @click="triggerManualNumberBuyingCopy"
                                >
                                  <i v-show="!isManualNumberBuyingCopy" class="mdi mdi-pencil"></i>
                                  <i v-show="isManualNumberBuyingCopy" class="mdi mdi-close"></i>
                                </b-button>
                              </b-input-group-append>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Vendor <span class="text-danger" aria-required="true"> * </span></label>
                              <v-select v-model="dataBuyingCopy.vendor" :options="listVendor" :reduce="(option) => option.id" label="name" @input="changeVendor">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Invoice <span class="text-danger" aria-required="true"> * </span></label>
                              <input v-model="dataBuyingCopy.invoice" type="text" class="form-control form-control-sm" placeholder="Invoice" required />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Term <span class="text-danger" aria-required="true"> * </span></label>
                              <v-select v-model="dataBuyingCopy.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTermBuying">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Currency <span class="text-danger" aria-required="true"> * </span></label>
                              <v-select v-model="dataBuyingCopy.currency" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" @input="changeCurrency">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
                            <date-picker v-model="dataBuyingCopy.date" :format="momentFormatBuying" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglBuying" >
                            </date-picker>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">PPN <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" @input="changePpnBuying">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2" v-if="this.companyIsUsingPPH === 1">
                            <div class="form-group">
                              <label for="">PPH <span aria-required="true" class="text-danger"> * </span></label>
                              <v-select v-model="dataBuyingCopy.pph" :reduce="(option) => option.id" :options="listPPH" label="description" @input="changePphBuying">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0)">
                            <div class="form-group">
                              <label for="">Document Code</label>
                              <v-select v-model="dataBuyingCopy.docCode" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCodeBuying" item-text="text" item-value="code">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0 && showTaxType === true)">
                            <div class="form-group">
                              <label for="">Tax Type</label>
                              <v-select v-model="dataBuyingCopy.taxType" :options="listTaxTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="">Remarks</label>
                              <input v-model="dataBuyingCopy.remarks" type="text" class="form-control form-control-sm" placeholder="" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showTaxDetail">
                            <label for=""></label>
                            <div class="form-check mt-2">
                              <input id="setInvoiceTax" v-model="isTaxDateChecked" @input="activeTax($event)" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                              <label for="setInvoiceTax">Set Invoice Tax Date & No</label>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showTaxInput">
                            <div class="form-group">
                              <label for="">Tax Date</label>
                              <date-picker v-model="dataBuyingCopy.taxDate" :format="dateFormatingTax" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglTaxBuying" >
                              </date-picker>
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showTaxInput">
                            <div class="form-group">
                              <label for="">Tax No</label>
                              <input v-model="dataBuyingCopy.taxNo" type="text" class="form-control form-control-sm" placeholder="Tax Number" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showRate">
                            <div class="form-group">
                              <label for="">Commercial Rate <span class="text-danger" aria-required="true"> * </span></label>
                              <input v-model="dataBuyingCopy.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required @keypress="isNumber($event)" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="showRate">
                            <div class="form-group">
                              <label for="">Fiscal Rate <span class="text-danger" aria-required="true"> * </span></label>
                              <input v-model="dataBuyingCopy.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required @keypress="isNumber($event)" />
                            </div>
                          </div>
                          <div class="col-md-2" v-show="(dataBuyingCopy.ppn !== undefined && dataBuyingCopy.ppn > 0)">
                            <label for=""></label>
                            <div class="form-check mt-2">
                              <input id="inclusiveTax" v-model="dataBuyingCopy.inclusiveTax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                              <label for="inclusiveTax">Inclusive Tax</label>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </template>

              <div class="wizard-footer-left">
                <b-button class="btn-cancel" style="position: absolute; margin-top: 11px;"
                    @click.prevent="closeModalReceiveItem">
                    Cancel
                </b-button>
              </div>
          </tab-content>
          <!-- End Tab Content 1 -->

          <!-- Tab Content 2 -->
          <tab-content title="Choose Transaction" :before-change="validateBuyingDownPayment">
              <!--  -->
              <template>
                <div class="row">
                  <div class="col-lg-3">
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-9">
                    <div class="float-lg-right float-left">
                      <div class="form-inline">
                        <label class="mb-2 mr-sm-2">Search :</label>
                        <input
                          v-model="filterTextRI"
                          type="text"
                          class="form-control form-control-sm mb-2"
                          placeholder="Search . . ."
                          @keyup="doFilterRI"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Table -->
                <div class="table-responsive">
                  <vuetable ref="vuetableRI" :per-page="perPageRI" :fields="fieldsRI" :api-url="apiUrlRI" :api-mode="true"
                    :http-options="httpOptionsRI" :show-sort-icons="true" :append-params="moreParamsRI" pagination-path
                    class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTableRI"
                    @vuetable:load-success="loadTableSuccessRI" @vuetable:load-error="loadTableFailedRI"
                    @vuetable:pagination-data="onPaginationDataRI">

                    <div slot="item" slot-scope="props" class="button-ri">
                      <a :href="`/ReceivableItem/detail/${props.rowData.id}`" class="mr-1"> {{
                          props.rowData.item
                      }}
                        item

                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z" fill="#FFA800" />
                        </svg>
                      </a>
                    </div>
                    <div slot="po_number" slot-scope="props">
                      <div>
                        <span v-show="props.rowData.count_reff <= 1">{{ props.rowData.po_details[0].po_number }}</span>
                        <a
                        href="javascript:void(0);"
                        class="action-icon  mr-1"
                        @click="showOriginalNumber(props.rowData.po_details)"
                        style="font-size: 12px;"
                        >
                        <div v-show="props.rowData.count_reff > 1">
                          Show Original Number
                          <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z" fill="#FFA800"/>
                          </svg>
                        </div>
                        </a>
                      </div>
                    </div>
                    <div slot="applied_transaction" slot-scope="props" class="button-po">
                      <a :href="`/ReceivableItem/detail-applied-transaction/${props.rowData.id}`" class="mr-1"> {{
                        props.rowData.applied_transaction
                      }}
                        Transaction
                      </a>
                      <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                          fill="#FFA800" />
                      </svg>
                    </div>
                    <template slot="chk_select" slot-scope="props">
                      <b-form-checkbox :id="'receiveItem_' + props.rowData.id" class="chkReceiveItem" :checked="checkedTrx(props)" @change="selectTrx(props.rowData.id, props.rowData.id_vendor, props.rowData.down_payment, props.rowData.date, props.rowData.ppn)" :disabled="!((props.rowData.aol_id !== '0' && props.rowData.aol_id !== null && props.rowData.aol_id !== 0) && props.rowData.available_to_copy)"></b-form-checkbox>
                    </template>
                  </vuetable>
                </div>
                <!-- end table -->
              </template>

              <!-- pagination -->
              <div class="row">
                <div class="col-md-5">
                  <div class="row no-gutters">
                    <div class="col-lg-3">
                      <div class="form-group float-lg-left">
                        <select
                          v-model="perPageRI"
                          class="form-control form-control-sm"
                          @change="onChangeTableLengthRI"
                        >
                          <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                            {{ opt }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <vuetable-pagination-info
                        ref="paginationInfoRI"
                        class="
                          justify-content-center justify-content-lg-start
                          text-center text-lg-left
                        "
                      >
                      </vuetable-pagination-info>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <vuetable-pagination
                    ref="paginationRI"
                    :css="css.pagination"
                    class="
                      pagination pagination-rounded
                      justify-content-center justify-content-lg-end
                    "
                    @vuetable-pagination:change-page="onChangePageRI"
                  >
                  </vuetable-pagination>
                </div>
              </div>
              <!-- end pagination -->
          </tab-content>
          <!-- End Tab Content 2 -->

          <!-- Tab Content 3 -->
					<tab-content title="Item Send to Vendor Invoice / Settlement" :before-change="validateBuyingItemRI">
						<template>
							<b-form>
								<div class="mt-3">
									<div v-for="(isiDetail, d) in itemBuyingCopy" :key="d" class="row">
										<!-- <div class="col-md-1" style="display: flex;justify-content: center;align-items: center;">
											<input type="checkbox" v-model="isiDetail.isChecked" true-value="1" false-value="0" @change="selectItem(d)">
										</div> -->
										<div class="col-md-2" style="display: flex;justify-content: center;align-items: center; margin-right: 20px">
											<input type="checkbox" :id="`selectedRI${d}`" v-model="isiDetail.isChecked" true-value="1" false-value="0"
												@change="selectItem(d)" :disabled="isiDetail.stockQty === 0 && (isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)" v-show="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)">
											<div class="form-group ml-2" style="width: 100%">
												<label for="">Jobfile <span class="text-danger" aria-required="true"> * </span></label>
												<v-select v-model="isiDetail.idJobfile" :options="listJobfile" :reduce="(option) => option.id"
													label="jobfile_no" item-text="jobfile_no" item-value="id" required :disabled="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)">
												</v-select>
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group">
												<label for="">Item <span class="text-danger" aria-required="true"> * </span></label>
												<v-select v-model="isiDetail.item" :options="listItem" :reduce="(option) => option.id"
													label="item_name" item-text="item_name" item-value="id" required :disabled="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)">
												</v-select>
											</div>
										</div>
										<div class="col-md-1" v-show="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)">
											<div class="form-group">
												<label for="">Stock Item</label>
												<input v-model="isiDetail.stockQty" type="text" class="form-control form-control-sm"
													placeholder="Qty" required @keypress="isNumber($event)" disabled />
											</div>
										</div>
										<div class="col-md-1">
											<div class="form-group">
												<label for="">Qty <span class="text-danger" aria-required="true"> * </span></label>
												<input v-model="isiDetail.qty" type="text" class="form-control form-control-sm"
													placeholder="Qty" required @keypress="isNumber($event)" @keyup="isChangeQty(d)"
													:disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined) ? true : false" />
											</div>
										</div>
										<div class="col-md-2" v-show="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)">
											<div class="form-group">
												<label for="">Amount</label>
												<input v-model="isiDetail.actualAmount" type="number" class="form-control form-control-sm"
													placeholder="Price" required @keypress="isNumber($event)" :disabled="true" />
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group">
												<label for="">Actual Amount <span class="text-danger" aria-required="true"> * </span></label>
												<input v-model="isiDetail.price" type="number" class="form-control form-control-sm"
													placeholder="Price" required @keypress="isNumber($event)" @keyup="isChangePrice(d)" :disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined) ? true : false" />
											</div>
										</div>

										<div class="col-md-1" style="width:150px">
											<div class="form-group">
												<label for="">Sub Total</label>
												<input v-model="isiDetail.subTotal" type="text" class="form-control form-control-sm"
													placeholder="Price" required @keypress="isNumber($event)" disabled />
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group">
												<label for="">Remark</label>
												<input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm"
													placeholder="Remarks" required :disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined) ? true : false" />
											</div>
										</div>
										<div class="col-md-2">
											<div class="form-group">
												<input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0"
													class="form-check-input"
													style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;"
													@change="cekIsTruck(d)" :disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined) ? true : false">
												<label for="includeTax" style="margin-bottom: 4px;">
													Department
												</label>
												<a v-show="(d || (!d && itemBuyingCopy.length > 1) && (isiDetail.id_detail_ri === 0 || isiDetail.id_detail_ri === null || isiDetail.id_detail_ri === undefined))" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d, 'createNewFromRI')" style="float: right;"> <i class="fas fa-trash"></i>
											</a> &nbsp;
												<a v-show="d === itemBuyingCopy.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1" @click="addDetail('createNewFromRI')" style="float: right;"><i class="fas fa-plus"></i></a>
												<select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck"
													class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Department"
													:disabled="parseInt(isiDetail.isChecked) === 0 && (isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined) ? true : false">
													<option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
														{{ isi.truck_no }}
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</b-form>
						</template>
					</tab-content>
					<!-- End Tab Content 3 -->

          <button slot="prev" class="btn-cancel">Previous</button>
          <button slot="next" class="btn-next">Next</button>


          <button slot="finish" class="btn-finish" :disabled="isLoadingForm">Finish</button>
        </form-wizard>
      </div>
    </b-modal>
    <b-modal
        id="modalBuyingDownPaymentEdit"
        size="xl"
        title="Edit Transaction"
        style="max-width: 90% !important;"
        @ok="submitInfoBuying"
        ok-title="Save"
        no-close-on-backdrop
      >
      <div>
        <span style="font-weight: bold;font-size: 16px;">Down Payment</span>
        <div class="row mt-3 mb-3">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <label for="">Date (yyyy/mm/dd)</label>
                <date-picker v-model="dataSingleBuyingDownPayment.date" :format="momentFormatEdit" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglEdit" >
                </date-picker>
              </div>
              <div class="col-md-2">
                <label for="">Number</label>
                <div class="input-group">
                  <input v-model="dataSingleBuyingDownPayment.number" type="text" class="form-control form-control-sm" placeholder="Number" />
                </div>
              </div>
              <div class="input-group">
                            <v-select v-show="!isManualNumberBuyingPurchasePaymentTax" v-model="dataBuyingPurchasePaymentTax.number" :reduce="(option) => option.id" :options="listAutonumberPPTax" label="name" style="width: 81%; float: left;">
                            </v-select>
                            <input v-show="isManualNumberBuyingPurchasePaymentTax" v-model="dataBuyingPurchasePaymentTax.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                            <b-input-group-append style="width: 19%; float: right;">
                              <b-button
                                class="btn btn-create btn-sm"
                                style="background: #51C1AD;"
                                @click="triggerManualNumberBuyingPurchasePaymentTax"
                              >
                                <i v-show="!isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-pencil"></i>
                                <i v-show="isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-close"></i>
                              </b-button>
                            </b-input-group-append>
                          </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Invoice No</label>
                  <input v-model="dataSingleBuyingDownPayment.invoice_no" type="text" class="form-control form-control-sm" placeholder="Invoice No" required />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Term</label>
                  <v-select v-model="dataSingleBuyingDownPayment.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
                  </v-select>
                </div>
              </div>
               <div class="col-md-2">
                <div class="form-group">
                  <label for="">Down Payment</label>
                  <input v-model="dataSingleBuyingDownPayment.down_payment" type="text" class="form-control form-control-sm" placeholder="Down Payment" required />
                </div>
              </div>
              <div class="col-md-2">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="taxable" v-model="dataSingleBuyingDownPayment.taxable" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="taxable">Taxable</label>
                </div>
              </div>
              <div class="col-md-2" v-show="parseInt(dataSingleBuyingDownPayment.taxable) === 1">
                <div class="form-group">
                  <label for="">PPN</label>
                  <v-select v-model="dataSingleBuyingDownPayment.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" @input="changePpn">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-if="this.companyIsUsingPPH === 1">
                <div class="form-group">
                  <label for="">PPH <span aria-required="true" class="text-danger"> * </span></label>
                  <v-select v-model="dataSingleBuyingDownPayment.pph" :reduce="(option) => option.id" :options="listPPH" label="description" @input="changePph">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataSingleBuyingDownPayment.ppn !== undefined && dataSingleBuyingDownPayment.ppn > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1)">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="inclusiveTax" v-model="dataSingleBuyingDownPayment.inclusive_tax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="inclusiveTax">Inclusive Tax</label>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataSingleBuyingDownPayment.ppn !== undefined && dataSingleBuyingDownPayment.ppn > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1)">
                <div class="form-group">
                  <label for="">Document Code</label>
                  <v-select v-model="dataSingleBuyingDownPayment.doc_code" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCodeEdit" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-3" v-show="(dataSingleBuyingDownPayment.ppn !== undefined && dataSingleBuyingDownPayment.ppn > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1 && showTaxType === true)">
                <div class="form-group">
                  <label for="">Tax Type</label>
                  <v-select v-model="dataSingleBuyingDownPayment.tax_type" :taggable="false" :options="listTaxTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Remarks</label>
                  <input v-model="dataSingleBuyingDownPayment.remarks" type="text" class="form-control form-control-sm" placeholder="" />
                </div>
              </div>
              <div class="col-md-2" v-show="showTaxDetail">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="setInvoiceTax" v-model="isTaxDateChecked" @input="activeTax($event)" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="setInvoiceTax">Set Invoice Tax Date & No</label>
                </div>
              </div>
              <div class="col-md-2" v-show="showTaxInput">
                <div class="form-group">
                  <label for="">Tax Date</label>
                  <date-picker v-model="dataSingleBuyingDownPayment.taxDate" :format="dateFormatingTax" type="date" lang="en" style="height: 31px;" :placeholder="'Select Datess'" @input="getTglTaxEdit" >
                  </date-picker>
                </div>
              </div>
              <div class="col-md-2" v-show="showTaxInput">
                <div class="form-group">
                  <label for="">Tax No</label>
                  <input v-model="dataSingleBuyingDownPayment.taxNo" type="text" class="form-control form-control-sm" placeholder="Tax Number" />
                </div>
              </div>
              
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Description</label>
                  <textarea v-model="dataSingleBuyingDownPayment.description" class="form-control form-control-sm" placeholder="Description" cols="30" rows="3"></textarea>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Currency</label>
                  <input v-model="dataSingleBuyingDownPayment.symbol" type="text" class="form-control form-control-sm" placeholder="Currency" required disabled />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Commercial Rate</label>
                  <input v-model="dataSingleBuyingDownPayment.price_kurs" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" disabled @keypress="isNumber($event)" />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Fiscal Rate</label>
                  <input v-model="dataSingleBuyingDownPayment.fiscal_kurs" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" disabled @keypress="isNumber($event)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <span style="font-weight: bold;font-size: 16px;">Purchase Payment</span>
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Bank</label>
                  <v-select v-model="dataSingleBuyingPurchasePayment.bank" :options="listItemBank" :reduce="(option) => option.id" label="item_name" @change="changeBankEdit">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Payment Method</label>
                  <v-select v-model="dataSingleBuyingPurchasePayment.payment_method" :options="paymentMethods" :reduce="(option) => option.id" label="name">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Number</label>
                <div class="input-group">
                  <input v-model="dataSingleBuyingPurchasePayment.number" type="text" class="form-control form-control-sm" placeholder="Number" />
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Date (yyyy/mm/dd)</label>
                <date-picker v-model="dataSingleBuyingPurchasePayment.date" :format="momentFormatPPEdit" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglPPEdit" >
                </date-picker>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Discount Account</label>
                  <v-select v-model="dataSingleBuyingPurchasePayment.discount_account" taggable :reduce="(option) => option.id" :options="listItemNonInventory" label="item_name" @input="changeDiscEdit" item-text="item_name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="">.</label>
                  <v-select v-model="dataSingleBuyingPurchasePayment.discount_type" taggable :reduce="(option) => option.id" :options="discountTypes" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Discount</label>
                  <input v-model="dataSingleBuyingPurchasePayment.discount" type="text" class="form-control form-control-sm" placeholder="Discount" required />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Bank Currency</label>
                  <v-select v-model="dataSingleBuyingPurchasePayment.id_currency_bank" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" disabled>
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Commercial Rate</label>
                  <input v-model="dataSingleBuyingPurchasePayment.price_kurs" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required disabled @keypress="isNumber($event)" />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Fiscal Rate</label>
                  <input v-model="dataSingleBuyingPurchasePayment.fiscal_kurs" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required disabled @keypress="isNumber($event)" />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Remarks</label>
                  <textarea v-model="dataSingleBuyingPurchasePayment.remarks" class="form-control form-control-sm" placeholder="Remarks" cols="30" rows="3"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span style="font-weight: bold;font-size: 16px;" v-show="editTaxAble && parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1">Purchase Payment Tax</span>
        <div class="row mt-3" v-show="editTaxAble && parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Bank</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.bank" :options="listItemBank" :reduce="(option) => option.id" label="item_name">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Payment Method</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.payment_method" :options="paymentMethods" :reduce="(option) => option.id" label="name">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Number</label>
                <div class="input-group">
                  <input v-model="dataSingleBuyingPurchasePaymentTax.number" type="text" class="form-control form-control-sm" placeholder="Number" />
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Date (yyyy/mm/dd)</label>
                <date-picker v-model="dataSingleBuyingPurchasePaymentTax.date" :format="momentFormatPPTaxEdit" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglPPTaxEdit" >
                </date-picker>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Discount Account</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.discount_account" taggable :reduce="(option) => option.id" :options="listItemNonInventory" label="item_name" @input="changeDiscTaxEdit" item-text="item_name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="">.</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.discount_type" taggable :reduce="(option) => option.id" :options="discountTypes" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Discount</label>
                  <input v-model="dataSingleBuyingPurchasePaymentTax.discount" type="text" class="form-control form-control-sm" placeholder="Discount" required />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Bank Currency</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.id_currency_bank" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" disabled>
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Remarks</label>
                  <textarea v-model="dataSingleBuyingPurchasePaymentTax.remarks" class="form-control form-control-sm" placeholder="Remarks" cols="30" rows="3"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span style="font-weight: bold;font-size: 16px;" v-show="!editTaxAble && parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1 && parseInt(this.dataSingleBuyingDownPayment.id_currency) !== 0">Purchase Payment Tax</span>
        <div class="row mt-3" v-show="!editTaxAble && parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0 && parseInt(dataSingleBuyingDownPayment.taxable) === 1 && parseInt(this.dataSingleBuyingDownPayment.id_currency) !== 0">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Bank</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.bank" :options="listItemBank" :reduce="(option) => option.id" label="item_name" @input="changeBankTax">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Payment Method</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.payment_method" :options="paymentMethods" :reduce="(option) => option.id" label="name">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Number</label>
                <div class="input-group">
                  <v-select v-show="!isManualNumberBuyingPurchasePaymentTax" v-model="dataSingleBuyingPurchasePaymentTax.id_autonumber" :reduce="(option) => option.id" :options="listAutonumberPPTax" label="name" style="width: 81%; float: left;">
                  </v-select>
                  <input v-show="isManualNumberBuyingPurchasePaymentTax" v-model="dataSingleBuyingPurchasePaymentTax.number" type="text" class="form-control form-control-sm" placeholder="Number" required />
                  <b-input-group-append style="width: 19%; float: right;">
                    <b-button
                      class="btn btn-create btn-sm"
                      style="background: #51C1AD;"
                      @click="triggerManualNumberBuyingPurchasePaymentTax"
                    >
                      <i v-show="!isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-pencil"></i>
                      <i v-show="isManualNumberBuyingPurchasePaymentTax" class="mdi mdi-close"></i>
                    </b-button>
                  </b-input-group-append>
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Date (yyyy/mm/dd)</label>
                <date-picker v-model="dataSingleBuyingPurchasePaymentTax.date" :format="momentFormatPPTaxEdit" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglPPTaxEdit" >
                </date-picker>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Discount Account</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.discount_account" taggable :reduce="(option) => option.id" :options="listItemBank" label="item_name" @input="changeDiscTaxEdit" item-text="item_name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="">.</label>
                  <v-select v-model="dataSingleBuyingPurchasePaymentTax.discount_type" taggable :reduce="(option) => option.id" :options="discountTypes" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Discount</label>
                  <input v-model="dataSingleBuyingPurchasePaymentTax.discount" type="text" class="form-control form-control-sm" placeholder="Discount" required />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Remarks</label>
                  <textarea v-model="dataSingleBuyingPurchasePaymentTax.remarks" class="form-control form-control-sm" placeholder="Remarks" cols="30" rows="3"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- new PI -->
    <b-modal
        id="modalBuying"
        size="xl"
        title="Create New Transaction Vendor Invoice / Settlement"
        style="max-width: 90% !important;"
        @ok="submitBuyingManual"
        ok-title="Submit"
        no-close-on-backdrop
        @ok-disabled="isLoadingForm"
      >
      <!-- <div class="information-dp mt-2">
        <img src="@assets/images/money-bag.png" width="24" />
        <span class="ml-2" style="color: #898989;">Down Payment : </span>
        <span class="ml-1" style="color: #565656; font-weight: bold;">{{formatRupiah(this.dataBuying.downPayment.toString(), this.dataBalanceDP.data !== undefined ? this.dataBalanceDP.data.symbol : '')}}</span>
        <span class="ml-3" style="color: #898989;">Date : </span>
        <span class="ml-1" style="color: #565656; font-weight: bold;">{{this.dataBuying.dateDownPayment}}</span>
      </div> -->
      <div style="padding: 20px;">
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
                <div class="input-group">
                  <v-select v-show="!isManualNumberBuyingCopy" v-model="dataBuying.number" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;">
                  </v-select>
                  <input v-show="isManualNumberBuyingCopy" v-model="dataBuying.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                  <b-input-group-append style="width: 19%; float: right;">
                    <b-button
                      class="btn btn-create btn-sm"
                      style="background: #51C1AD;"
                      @click="triggerManualNumberBuyingCopy"
                    >
                      <i v-show="!isManualNumberBuyingCopy" class="mdi mdi-pencil"></i>
                      <i v-show="isManualNumberBuyingCopy" class="mdi mdi-close"></i>
                    </b-button>
                  </b-input-group-append>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Vendor <span class="text-danger" aria-required="true"> * </span></label>
                  <v-select v-model="dataBuying.vendor" :options="listVendor" :reduce="(option) => option.id" label="name" @input="changeVendorBuying">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Invoice <span class="text-danger" aria-required="true"> * </span></label>
                  <input v-model="dataBuying.invoice" type="text" class="form-control form-control-sm" placeholder="Invoice" required />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Term <span class="text-danger" aria-required="true"> * </span></label>
                  <v-select v-model="dataBuying.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTermBuyingCopy">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Currency <span class="text-danger" aria-required="true"> * </span></label>
                  <v-select v-model="dataBuying.currency" taggable :options="listCurrency" :reduce="(option) => option.id" label="code" item-text="code" item-value="id" @input="changeCurrency">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
                <date-picker v-model="dataBuying.date" :format="momentFormatBuyingCopy" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglBuyingCopy" >
                </date-picker>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">PPN <span class="text-danger" aria-required="true"> * </span></label>
                  <v-select v-model="dataBuying.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" @input="changePpnCopy">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-if="this.companyIsUsingPPH === 1">
                <div class="form-group">
                  <label for="">PPH <span class="text-danger" aria-required="true"> * </span></label>
                  <v-select v-model="dataBuying.pph" :reduce="(option) => option.id" :options="listPPH" label="description" @input="changePphCopy">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataBuying.ppn !== undefined && dataBuying.ppn > 0)">
                <div class="form-group">
                  <label for="">Document Code</label>
                  <v-select v-model="dataBuying.docCode" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCodeCopy" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2" v-show="(dataBuying.ppn !== undefined && dataBuying.ppn > 0 && showTaxType === true)">
                <div class="form-group">
                  <label for="">Tax Type</label>
                  <v-select v-model="dataBuying.taxType" :options="listTaxTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Remarks</label>
                  <input v-model="dataBuying.remarks" type="text" class="form-control form-control-sm" placeholder="" />
                </div>
              </div>
              <div class="col-md-2" v-show="showTaxDetail">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="setInvoiceTax" v-model="isTaxDateChecked" @input="activeTax($event)" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="setInvoiceTax">Set Invoice Tax Date & No</label>
                </div>
              </div>
              <div class="col-md-2" v-show="showTaxInput">
                <div class="form-group">
                  <label for="">Tax Date</label>
                  <date-picker v-model="dataBuying.taxDate" :format="dateFormatingTax" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglTaxBuyingCopy" >
                  </date-picker>
                </div>
              </div>
              <div class="col-md-2" v-show="showTaxInput">
                <div class="form-group">
                  <label for="">Tax No</label>
                  <input v-model="dataBuying.taxNo" type="text" class="form-control form-control-sm" placeholder="Tax Number" />
                </div>
              </div>        
              <div class="col-md-2" v-show="showRate">
                <div class="form-group">
                  <label for="">Commercial Rate <span class="text-danger" aria-required="true"> * </span></label>
                  <input v-model="dataBuying.commercialRate" type="text" class="form-control form-control-sm" placeholder="Commercial Rate" required @keypress="isNumber($event)" />
                </div>
              </div>
              <div class="col-md-2" v-show="showRate">
                <div class="form-group">
                  <label for="">Fiscal Rate <span class="text-danger" aria-required="true"> * </span></label>
                  <input v-model="dataBuying.fiscalRate" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate" required @keypress="isNumber($event)" />
                </div>
              </div>
              <!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="">Attn</label>
                  <input v-model="dataBuying.pic_name" type="text" class="form-control form-control-sm" placeholder="Attn" required />
                </div>
              </div> -->
              <div class="col-md-2" v-show="(dataBuying.ppn !== undefined && dataBuying.ppn > 0)">
                <label for=""></label>
                <div class="form-check mt-2">
                  <input id="inclusiveTax" v-model="dataBuying.inclusiveTax" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                  <label for="inclusiveTax">Inclusive Tax</label>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="mt-3">
          <div v-for="(isiDetail, d) in itemBuying" :key="d" class="row">
            <!-- <div class="row"> -->

            <div class="col-md-2">
              <div class="form-group">
                <label for="">Jobfile <span class="text-danger" aria-required="true"> * </span></label>
                <v-select v-model="isiDetail.idJobfile" :options="listJobfile" :reduce="(option) => option.id" label="jobfile_no" item-text="jobfile_no" item-value="id" required>
                  </v-select>
                <!-- <select v-model="isiDetail.idJobfile" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                  <option v-for="(isi, key) in listJobfile" :key="key" :value="isi.id">
                    {{isi.jobfile_no}}
                  </option>
                </select> -->
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Item <span class="text-danger" aria-required="true"> * </span></label>
                <v-select v-model="isiDetail.item" :options="listItem" :reduce="(option) => option.id" label="item_name" item-text="item_name" item-value="id" required>
                  </v-select>
                <!-- <select v-model="isiDetail.item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                  <option v-for="(isi, key) in listItem" :key="key" :value="isi.id">
                    {{isi.item_name}}
                  </option>
                </select> -->
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Qty <span class="text-danger" aria-required="true"> * </span></label>
                <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Price <span class="text-danger" aria-required="true"> * </span></label>
                <input v-model="isiDetail.price" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Remark</label>
                <input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruck(d)">
                <label for="includeTax" style="margin-bottom: 4px;">
                  Department
                </label>
                <a v-show="d || (!d && itemBuying.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d,'createNew')" style="float: right;"> <i class="fas fa-trash"></i>
                </a> &nbsp;
                <a v-show="d === itemBuying.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1" @click="addDetail('createNew')" style="float: right;"><i class="fas fa-plus"></i></a>
                <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.truck" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                  <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                    {{isi.truck_no}}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="col-md-1 pt-1">
              <br>
              <a v-show="d || (!d && itemBuying.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetail(d)"> <i class="fas fa-trash"></i>
              </a> &nbsp;
              <a v-show="d === itemBuying.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm" @click="addDetail('ok')"><i class="fas fa-plus"></i></a>
              &nbsp;
            </div> -->
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <label for=""></label>
            <div class="form-check mt-2 p-0">
              <label for="inclusiveTax">Copy Transaction</label>
              <input id="inclusiveTax" v-model="isCopyTransaction" type="checkbox" true-value="1" false-value="0" class="form-check-input ml-2" style="width: 15px; height: 15px;">
            </div>
          </div>
        </div>

        <div class="row mt-3" v-show="isCopyTransaction === '1'">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <div class="form-group">
                  <v-select v-model="typeCopy" :options="listType" :reduce="(option) => option.id" label="name" disabled>
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="joborderCopy" taggable :reduce="(option) => option.id" :options="listJobOrderCopy" label="name" item-text="name" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <v-select v-model="numberCopy" taggable :options="listNumberCopy" :reduce="(option) => option.id" label="code" item-text="code" item-value="id">
                  </v-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <input v-model="remarksCopy" type="text" class="form-control form-control-sm" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </b-modal>

    <!-- modal edit PI -->
    <b-modal
        id="modalBuyingEdit"
        size="xl"
        title="Edit Vendor Invoice / Settlement"
        style="max-width: 90% !important;"
        @ok="submitInfoBuyingUpdate"
        ok-title="Save"
        no-close-on-backdrop
        @ok-disabled="isLoadingForm"
      >
      <div>
        <form-wizard :start-index="0" ref="wizardBuying" color="#51C1AD" style="
          margin-top: 2rem;
          " @on-complete="onCompleteBuying">
          <!-- Tab Content 1 -->
          <tab-content title="Edit Info Vendor Invoice / Settlement" :before-change="validateBuyingUpdate">
              <!--  -->
              <template>
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="form-row">
                      <div class="col-md-4">
                        <label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
                        <date-picker v-model="dataSingleBuying.cost_date" :format="momentFormatEditBuying" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglEditBuying" >
                        </date-picker>
                      </div>
                      <div class="col-md-4">
                        <label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
                        <!-- <div class="input-group">
                          <input v-model="dataSingleBuying.pi_number" type="text" class="form-control form-control-sm" placeholder="Number" />
                        </div>
                      </div> -->
                      <div class="input-group">
                         <v-select v-show="!isManualNumberBuyingEdit" v-model="dataSingleBuyingNumber" :reduce="(option) => option.id" :options="listAutonumber" label="name" style="width: 81%; float: left;">
                  </v-select>
                      <input v-show="isManualNumberBuyingEdit" v-model="dataSingleBuying.numberText" type="text" class="form-control form-control-sm" placeholder="Number" />
                      <b-input-group-append style="width: 19%; float: right;">
                        <b-button
                          class="btn btn-create btn-sm"
                          style="background: #51C1AD;"
                          @click="triggerManualNumberBuyingEdit"
                        >
                          <i v-show="!isManualNumberBuyingEdit" class="mdi mdi-pencil"></i>
                          <i v-show="isManualNumberBuyingEdit" class="mdi mdi-close"></i>
                        </b-button>
                      </b-input-group-append>
                      </div>
                    </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Invoice <span class="text-danger" aria-required="true"> * </span></label>
                          <input v-model="dataSingleBuying.invoice_no" type="text" class="form-control form-control-sm" placeholder="Invoice" required />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Term</label>
                          <v-select v-model="dataSingleBuying.term" taggable :reduce="(option) => option.id" :options="listTerm" label="name" item-text="name" item-value="id" @input="changeTerm">
                          </v-select>
                        </div>
                      </div>
                      <div class="col-md-4" v-show="(dataSingleBuying.ppn_cost !== undefined && dataSingleBuying.ppn_cost > 0)">
                        <div class="form-group">
                          <label for="">Document Code</label>
                          <v-select v-model="dataSingleBuying.doc_code" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCodeEditBuying" item-text="text" item-value="code">
                          </v-select>
                        </div>
                      </div>
                      <div class="col-md-4" v-show="(dataSingleBuying.ppn_cost !== undefined && dataSingleBuying.ppn_cost > 0 && showTaxType === true)">
                        <div class="form-group">
                          <label for="">Tax Type</label>
                          <v-select v-model="dataSingleBuying.tax_type" :taggable="false" :options="listTaxTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                          </v-select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="">Remarks</label>
                          <input v-model="dataSingleBuying.remarks" type="text" class="form-control form-control-sm" placeholder="" />
                        </div>
                      </div>
                      <div class="col-md-2" v-show="showTaxDetail">
                        <label for=""></label>
                        <div class="form-check mt-2">
                          <input id="setInvoiceTax" v-model="isTaxDateChecked" @input="activeTax($event)" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                          <label for="setInvoiceTax">Set Invoice Tax Date & No</label>
                        </div>
                      </div>
                      <div class="col-md-2" v-show="showTaxInput">
                        <div class="form-group">
                          <label for="">Tax Date</label>
                          <date-picker v-model="dataSingleBuying.tax_invoice_date" :format="dateFormatingTax" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglTaxEditBuying" >
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-md-2" v-show="showTaxInput">
                        <div class="form-group">
                          <label for="">Tax No</label>
                          <input v-model="dataSingleBuying.tax_invoice_number" type="text" class="form-control form-control-sm" placeholder="Tax Number" />
                        </div>
                      </div>
                      <div class="col-md-4" v-show="(dataSingleBuying.ppn_cost !== undefined && parseInt(dataSingleBuying.ppn_cost) > 0)">
                        <label for=""></label>
                        <div class="form-check mt-2">
                          <input id="inclusiveTax" v-model="dataSingleBuying.inclusive_tax_cost" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                          <label for="inclusiveTax">Inclusive Tax</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Currency
                          </label>
                          <input v-model="dataSingleBuying.symbol" type="text" class="form-control form-control-sm" placeholder="Invoice" required disabled />
                        </div>
                      </div>
                      <div class="col-md-4" v-if="dataSingleBuying.id_default !== parseInt(dataSingleBuying.currency_cost)">
                        <div class="form-group">
                          <label for="">Commercial Rate</label>
                          <input v-model="dataSingleBuying.price_kurs" type="text" class="form-control form-control-sm" placeholder="Commercial Rate"   @keypress="isNumber($event)" />
                        </div>
                      </div>
                      <div class="col-md-4" v-if="dataSingleBuying.id_default !== parseInt(dataSingleBuying.currency_cost)">
                        <div class="form-group">
                          <label for="">Fiscal Rate</label>
                          <input v-model="dataSingleBuying.fiscal_kurs" type="text" class="form-control form-control-sm" placeholder="Fiscal Rate"  @keypress="isNumber($event)" />
                        </div>
                      </div>
											<div class="col-md-4">
												<div class="form-group">
													<label for="">PPN <span class="text-danger" aria-required="true"> * </span></label>
													<v-select v-model="dataSingleBuying.ppn_cost" :reduce="(option) => option.id" :options="listPercentage" label="description" item-text="description" item-value="id" disabled>
													</v-select>
												</div>
											</div>
                      <div class="col-md-4">
                        <!-- <label for=""></label> -->
                        <div class="form-group">
                          <div class="row ml-1">
                            <label for="dpCheck">Down Payment</label>
                            <input id="dpCheck" v-model="checkDp" type="checkbox" class="ml-1" style="width: 15px; height: 15px; margin-top:2px">
                          </div>
                          <v-select v-model="dataSingleBuying.id_down_payment" :disabled="!checkDp" :taggable="false" :options="listDpByVendor" :reduce="(option) => option.id" label="number" item-text="text" item-value="id">
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
          </tab-content>
          <!-- Tab Content 2 -->
          <tab-content title="Choose Item" :before-change="validateBuyingItem">
              <!--  -->
              <template>
                <div class="mt-3">
                  <div v-for="(isiDetail, d) in dataSingleBuying.items" :key="d" class="row" v-show="(dataSingleBuying.id_purchase_order === 0 || dataSingleBuying.id_purchase_order === '' || dataSingleBuying.id_purchase_order === null || dataSingleBuying.id_purchase_order === undefined) && (dataSingleBuying.id_receiveable_item === 0 || dataSingleBuying.id_receiveable_item === '' || dataSingleBuying.id_receiveable_item === null || dataSingleBuying.id_receiveable_item === undefined)">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Jobfile <span class="text-danger" aria-required="true"> * </span></label>
                        <v-select v-model="isiDetail.id_jobfile" :options="listJobfile" :reduce="(option) => option.id" label="jobfile_no" item-text="jobfile_no" item-value="id" required>
                          </v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Item <span class="text-danger" aria-required="true"> * </span></label>
                        <v-select v-model="isiDetail.id_item" :options="listItem" :reduce="(option) => option.id" label="item_name" item-text="item_name" item-value="id" required>
                          </v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Qty <span class="text-danger" aria-required="true"> * </span></label>
                        <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Price <span class="text-danger" aria-required="true"> * </span></label>
                        <input v-model="isiDetail.price" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Remark</label>
                        <input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruckBuying(d)">
                        <label for="includeTax" style="margin-bottom: 4px;">
                          Department
                        </label>
                        <a v-show="d || (!d && dataSingleBuying.items.length > 1)" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetailEdit(d)" style="float: right;"> <i class="fas fa-trash"></i>
                        </a> &nbsp;
                        <a v-show="d === dataSingleBuying.items.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1" @click="addDetailBuyingEdit('ok')" style="float: right;"><i class="fas fa-plus"></i></a>
                        <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.id_department" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required>
                          <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                            {{isi.truck_no}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div v-for="(isiDetail, d) in dataSingleBuying.items" :key="'B' + d" class="row" v-show="(dataSingleBuying.id_purchase_order !== 0 && dataSingleBuying.id_purchase_order !== '' && dataSingleBuying.id_purchase_order !== null && dataSingleBuying.id_purchase_order !== undefined)">
										<!-- <div class="col-md-1" style="display: flex;justify-content: center;align-items: center;">
										</div> -->
										<div class="col-md-2" style="display: flex;justify-content: center;align-items: center;">
											<input type="checkbox" :id="`selectedPOEdit${d}`" v-model="isiDetail.isChecked" true-value="1" false-value="0" @change="selectItemEdit(d)" v-show="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)">
                      <div class="form-group ml-2" style="width: 100%;">
                        <label for="">Jobfile <span class="text-danger" aria-required="true"> * </span></label>
                        <v-select v-model="isiDetail.id_jobfile" :options="listJobfile" :reduce="(option) => option.id" label="jobfile_no" item-text="jobfile_no" item-value="id" required :disabled="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)" >
                        </v-select>
                      </div>
										</div>
										<div class="col-md-2">
                      <div class="form-group">
                        <label for="">Item <span class="text-danger" aria-required="true"> * </span></label>
                        <v-select v-model="isiDetail.id_item" :options="listItem" :reduce="(option) => option.id" label="item_name" item-text="item_name" item-value="id" required :disabled="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)" >
                        </v-select>
                      </div>
										</div>
										<div class="col-md-1" v-show="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)">
                      <div class="form-group">
                        <label for="">Stock Item</label>
                        <input v-model="isiDetail.stockQty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" disabled />
                      </div>
										</div>
										<div class="col-md-1">
                      <div class="form-group">
                        <label for="">Qty <span class="text-danger" aria-required="true"> * </span></label>
                        <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" @keyup="isChangeQtyEdit(d)" :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false" />
                      </div>
										</div>
                    <div class="col-md-1" v-show="(isiDetail.id_detail_po !== 0 && isiDetail.id_detail_po !== null && isiDetail.id_detail_po !== undefined)">
                      <div class="form-group">
                        <label for="">Amount</label>
                        <input v-model="isiDetail.actualAmount" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" disabled />
                      </div>
										</div>
										<div class="col-md-2">
                      <div class="form-group">
                        <label for="">Actual Amount <span class="text-danger" aria-required="true"> * </span></label>
                        <input v-model="isiDetail.price" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" @keyup="isChangePriceEdit(d)" :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false" />
                      </div>
										</div>
                    <div class="col-md-1">
                      <div class="form-group">
                        <label for="">Sub Total</label>
                        <input v-model="isiDetail.subTotal" type="text" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" disabled />
                      </div>
										</div>
										<div class="col-md-1">
                      <div class="form-group">
                        <label for="">Remark</label>
                        <input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false" />
                      </div>
										</div>
										<div :class="(isiDetail.id_detail_po === 0 || isiDetail.id_detail_po === null || isiDetail.id_detail_po === undefined) ? 'col-md-2' : 'col-md-1'">
                      <div class="form-group">
                        <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruckBuying(d)" :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false">
                        <label for="includeTax" style="margin-bottom: 4px;">
                        Department
                        </label>
                        <a v-show="((d || (!d && dataSingleBuying.items.length > 1)) && (isiDetail.id_detail_po === 0 || isiDetail.id_detail_po === null || isiDetail.id_detail_po === undefined) && (isiDetail.id_detail_ri === 0 || isiDetail.id_detail_ri === null || isiDetail.id_detail_ri === undefined))" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetailEdit(d)" style="float: right;"> <i class="fas fa-trash"></i>
                        </a> &nbsp;
                          <a v-show="d === dataSingleBuying.items.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1" @click="addDetailBuyingEdit('createNewFromPO')" style="float: right;"><i class="fas fa-plus"></i></a>
                        <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.id_department" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false">
                        <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                          {{isi.truck_no}}
                        </option>
                        </select>
                      </div>
										</div>
                    <div class="col-11 d-flex">
                      <div class="form-row ml-auto mb-1 mr-3">
                        <!-- Total : {{ this.totalOfSubTotal ? this.totalOfSubTotal : 0 }} -->
                      </div>
                    </div>
                  </div>
                  <div v-for="(isiDetail, d) in dataSingleBuying.items" :key="'C' + d" class="row" v-show="(dataSingleBuying.id_receiveable_item !== 0 && dataSingleBuying.id_receiveable_item !== '' && dataSingleBuying.id_receiveable_item !== null && dataSingleBuying.id_receiveable_item !== undefined)">
										<!-- <div class="col-md-1" style="display: flex;justify-content: center;align-items: center;">
										</div> -->
										<div class="col-md-2" style="display: flex;justify-content: center;align-items: center;">
											<input type="checkbox" :id="`selectedPOEdit${d}`" v-model="isiDetail.isChecked" true-value="1" false-value="0" @change="selectItemEdit(d)" v-show="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)">
                      <div class="form-group ml-2" style="width: 100%;">
                        <label for="">Jobfile <span class="text-danger" aria-required="true"> * </span></label>
                        <v-select v-model="isiDetail.id_jobfile" :options="listJobfile" :reduce="(option) => option.id" label="jobfile_no" item-text="jobfile_no" item-value="id" required :disabled="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)" >
                        </v-select>
                      </div>
										</div>
										<div class="col-md-2">
                      <div class="form-group">
                        <label for="">Item <span class="text-danger" aria-required="true"> * </span></label>
                        <v-select v-model="isiDetail.id_item" :options="listItem" :reduce="(option) => option.id" label="item_name" item-text="item_name" item-value="id" required :disabled="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)" >
                        </v-select>
                      </div>
										</div>
										<div class="col-md-1" v-show="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)">
                      <div class="form-group">
                        <label for="">Stock Item</label>
                        <input v-model="isiDetail.stockQty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" disabled />
                      </div>
										</div>
										<div class="col-md-1">
                      <div class="form-group">
                        <label for="">Qty <span class="text-danger" aria-required="true"> * </span></label>
                        <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" @keyup="isChangeQtyEdit(d)" :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false" />
                      </div>
										</div>
                    <div class="col-md-1" v-show="(isiDetail.id_detail_ri !== 0 && isiDetail.id_detail_ri !== null && isiDetail.id_detail_ri !== undefined)">
                      <div class="form-group">
                        <label for="">Amount</label>
                        <input v-model="isiDetail.actualAmount" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" disabled />
                      </div>
										</div>
										<div class="col-md-2">
                      <div class="form-group">
                        <label for="">Actual Amount <span class="text-danger" aria-required="true"> * </span></label>
                        <input v-model="isiDetail.price" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" @keyup="isChangePriceEdit(d)" :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false" />
                      </div>
										</div>
                    <div class="col-md-1">
                      <div class="form-group">
                        <label for="">Sub Total</label>
                        <input v-model="isiDetail.subTotal" type="text" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)" disabled />
                      </div>
										</div>
										<div class="col-md-1">
                      <div class="form-group">
                        <label for="">Remark</label>
                        <input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false" />
                      </div>
										</div>
										<div :class="(isiDetail.id_detail_ri === 0 || isiDetail.id_detail_ri === null || isiDetail.id_detail_ri === undefined) ? 'col-md-2' : 'col-md-1'">
                      <div class="form-group">
                        <input id="isTruck" v-model="isiDetail.isTruck" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;position: relative;margin-left: 0; margin-right: 5px;" @change="cekIsTruckBuying(d)" :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false">
                        <label for="includeTax" style="margin-bottom: 4px;">
                        Department
                        </label>
                        <a v-show="((d || (!d && dataSingleBuying.items.length > 1)) && (isiDetail.id_detail_ri === 0 || isiDetail.id_detail_ri === null || isiDetail.id_detail_ri === undefined) && (isiDetail.id_detail_po === 0 || isiDetail.id_detail_po === null || isiDetail.id_detail_po === undefined))" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="removeDetailEdit(d)" style="float: right;"> <i class="fas fa-trash"></i>
                        </a> &nbsp;
                          <a v-show="d === dataSingleBuying.items.length - 1" href="javascript:void(0)" class="btn btn-create btn-sm mr-1" @click="addDetailBuyingEdit('createNewFromPO')" style="float: right;"><i class="fas fa-plus"></i></a>
                        <select v-show="isiDetail.isTruck === '1'" v-model="isiDetail.id_department" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Truck" required :disabled="parseInt(isiDetail.isChecked) === 0 ? true : false">
                        <option v-for="(isi, key) in listTruck" :key="key" :value="isi.id">
                          {{isi.truck_no}}
                        </option>
                        </select>
                      </div>
										</div>
                    <div class="col-11 d-flex">
                      <div class="form-row ml-auto mb-1 mr-3">
                        <!-- Total : {{ this.totalOfSubTotal ? this.totalOfSubTotal : 0 }} -->
                      </div>
                    </div>
                  </div>
                </div>
              </template>
          </tab-content>
          <button slot="prev" class="btn-cancel">Previous</button>
          <button slot="next" class="btn-next">Next</button>
          <button slot="finish" class="btn-finish" :disabled="isLoadingForm">Finish</button>
        </form-wizard>
      </div>
    </b-modal>

    <!-- modal number reff -->
    <b-modal
        id="modalNumberReff"
        size="md"
        title="Original Number"
        style="max-width: 90% !important;"
      >
      <div>
        <div v-for="(isiDetail, d) in listNumberReff" :key="'C' + d" class="mt-2">
          <a :href="`/purchase-order/detail-applied-transaction/${isiDetail.id_purchase_order}`" v-show="isiDetail.id_purchase_order !== null && isiDetail.id_purchase_order !== undefined && isiDetail.id_purchase_order !== 0 && isiDetail.id_purchase_order !== ''">
            <span>{{isiDetail.number_code}}</span>
          </a>
          <a :href="`/ReceivableItem/detail-applied-transaction/${isiDetail.id_receiveable_item}`" v-show="isiDetail.id_receiveable_item !== null && isiDetail.id_receiveable_item !== undefined && isiDetail.id_receiveable_item !== 0 && isiDetail.id_receiveable_item !== ''">
            <span>{{isiDetail.number}}</span>
          </a>
        </div>
      </div>
    </b-modal>

    <!-- modal journal voucher -->
    <b-modal
        id="modalJournalVoucher"
        size="xl"
        title="Journal Voucher"
        style="max-width: 90% !important;"
        @ok="sendToJournalVoucher"
        ok-title="Submit"
        no-close-on-backdrop
        :ok-disabled="isLoadingForm"
      >
      <div style="padding: 20px;">
        <div class="row mt-3">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-2">
                <label for="">Number <span class="text-danger" aria-required="true"> * </span></label>
                <div class="input-group">
                  <v-select v-show="!isManualNumberJV" v-model="dataJournalVoucher.number" :reduce="(option) => option.id" :options="listAutonumberJV" label="name" style="width: 81%; float: left;">
                  </v-select>
                  <input v-show="isManualNumberJV" v-model="dataJournalVoucher.numberText" type="text" class="form-control form-control-sm" placeholder="Number" required />
                  <b-input-group-append style="width: 19%; float: right;">
                    <b-button
                      class="btn btn-create btn-sm"
                      style="background: #51C1AD;"
                      @click="triggerManualNumberJV"
                    >
                      <i v-show="!isManualNumberJV" class="mdi mdi-pencil"></i>
                      <i v-show="isManualNumberJV" class="mdi mdi-close"></i>
                    </b-button>
                  </b-input-group-append>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Memo </label>
                  <input v-model="dataJournalVoucher.memo" type="text" class="form-control form-control-sm" placeholder="Memo" />
                </div>
              </div>
              <div class="col-md-2">
                <label for="">Date (yyyy/mm/dd) <span class="text-danger" aria-required="true"> * </span></label>
                <date-picker v-model="dataJournalVoucher.date" :format="momentFormatJV" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglJV" >
                </date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- modal setup taxes -->
    <b-modal
        id="modalSetupTaxes"
        size="sm"
        title="Set up taxes"

        @ok="nextSwapItem"
        ok-title="Submit"
        no-close-on-backdrop
      >
      <div>
        <div class="row">
          <div class="col-12">
            <div class="form-row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="">PPN <span class="text-danger" aria-required="true"> * </span></label>
                  <v-select v-model="swap.ppn" :reduce="(option) => option.id" :options="listPercentage" label="description" >
                  </v-select>
                </div>
              </div>
              <div class="col-md-12" v-show="(swap.ppn !== undefined && swap.ppn > 0)">
                <div class="form-group">
                  <label for="">Document Code</label>
                  <v-select v-model="swap.docCode" :options="listDocCode" :reduce="(option) => option.code" label="text" @input="changeDocCodeSwap" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-12" v-show="(swap.ppn !== undefined && swap.ppn > 0 && showTaxType === true)">
                <div class="form-group">
                  <label for="">Tax Type</label>
                  <v-select v-model="swap.taxType" :options="listTaxTypeBuying" :reduce="(option) => option.code" label="text" item-text="text" item-value="code">
                  </v-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="">Remarks</label>
                  <input v-model="swap.remarks" type="text" class="form-control form-control-sm" placeholder="" />
                </div>
              </div>
              <div class="col-md-12" v-show="showTaxDetail">
                  <label for=""></label>
                  <div class="form-check mt-2">
                    <input id="setInvoiceTax" v-model="isTaxDateChecked"  @input="activeTax($event)" type="checkbox" true-value="1" false-value="0" class="form-check-input" style="width: 15px; height: 15px;">
                    <label for="setInvoiceTax">Set Invoice Tax Date & No</label>
                  </div>
                </div>
              <div class="col-md-12" v-show="showTaxInput">
                <div class="form-group">
                  <label for="">Tax Date</label>
                  <date-picker v-model="swap.taxDate" :format="dateFormatingTax" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'" @input="getTglTaxBuyingSwap" >
                  </date-picker>
                </div>
              </div>
              <div class="col-md-12" v-show="showTaxInput">
                <div class="form-group">
                  <label for="">Tax No</label>
                  <input v-model="swap.taxNo" type="text" class="form-control form-control-sm" placeholder="Tax number" />
                </div>
              </div>
              <div class="col-md-12" v-if="this.companyIsUsingPPH === 1">
                <div class="form-group">
                  <label for="">PPH <span class="text-danger" aria-required="true"> * </span></label>
                  <v-select v-model="swap.pph" :reduce="(option) => option.id" :options="listPPH" label="description" >
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end modal setup taxes -->

    <!-- modal swap item -->
    <b-modal
        id="modalSwapItem"
        size="xl"
        title="Swap the Item"

        @ok="swapItem"
        ok-title="Submit"
        no-close-on-backdrop
      >
      <div>
        <div class="row">
          <div class="col-12">
            <div class="form-row">
              <div v-for="(isiDetail, d) in dataSingleBuying.items" :key="d" class="row" >
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Jobfile </label>
                      <!-- <v-select v-model="isiDetail.id_jobfile" :options="listJobfile" :reduce="(option) => option.id" label="jobfile_no" item-text="jobfile_no" item-value="id" required disabled>
                      </v-select> -->
                      <select v-model="isiDetail.id_jobfile" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" disabled>
                        <option v-for="(isi, key) in listJobfile" :key="key" :value="isi.id">
                          {{isi.jobfile_no }}
                        </option>
                      </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Current Item </label>
                    <!-- <v-select v-model="isiDetail.id_item" :options="listItems" :reduce="(option) => option.id" item-text="item_name" item-value="id" required disabled>
                      <template slot="option" slot-scope="option">
                          {{ option.item_name }} {{option.item_number}}
                      </template>
                      <template slot="selected-option" slot-scope="option">
                          {{ option.item_name }} - {{option.item_number}}
                      </template>
                    </v-select> -->
                    <select v-model="isiDetail.id_item" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" disabled>
                      <option v-for="(isi, key) in listItems" :key="key" :value="isi.id">
                        {{isi.item_name }} - {{ isi.item_number }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">

                    <label for="">Change to Item <span class="text-danger" aria-required="true"> * </span></label>
                    <!-- <v-select v-model="isiDetail.id_item_new" :options="listItemTax" :reduce="(option) => option.id" label="item_name" item-text="item_name" item-value="id" required>

                    </v-select> -->
                    <select v-model="isiDetail.id_item_new" class="form-control form-control-sm" data-live-search="true" placeholder="Choose an Item" required>
                      <option v-for="(isi, key) in listItemTax" :key="key" :value="isi.id">
                        {{isi.item_name }} - {{ isi.item_number }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group">
                    <label for="">Qty </label>
                    <input v-model="isiDetail.qty" type="text" class="form-control form-control-sm" placeholder="Qty" required @keypress="isNumber($event)" disabled />
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group">
                    <label for="">Price </label>
                    <input v-model="isiDetail.price" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)"  disabled/>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Remark</label>
                    <input v-model="isiDetail.remarks" type="text" class="form-control form-control-sm" placeholder="Remarks" required disabled/>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Sub Total </label>
                    <input v-model="isiDetail.price" type="number" class="form-control form-control-sm" placeholder="Price" required @keypress="isNumber($event)"  disabled/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </b-modal>
    <!-- end modal swap item -->
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

var urlDP = '/multiBuyingDownPayment'
var url = '/multiPurchaseInvoice'
var urlPO = '/purchase-order'
var urlRI = '/receiveable-item'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
  // const element = array[i];
  // if (! JSON.parse(localStorage.getItem('userPermissions'))
  //       .includes(binding.expression
  //         .replace(/'/g, "")
  //           .replace(/"/g, ""))) {
  //      url = '/quotation';
  // }else{
  //   url = '/quotation?admin';
  // }
  // console.log(JSON.parse(localStorage.getItem('userPermissions'))[i])
  if (
    JSON.parse(localStorage.getItem('userPermissions'))[i] ===
    'CRM_ADMIN_ENABLE'
  ) {
    url = '/multiPurchaseInvoice'
  }
}
console.log(url, 'url')

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    DatePicker,
    FormWizard,
    TabContent,
  },
  props: {
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moreParams: {},
      searchItem: '',
      listItemTaxCopy: [],
      filterText: '',
      deleteData: {},
      isLoadingTable: false,
      isLoadingTableDP: false,
      isLoadingTablePO: false,
      isLoadingTableRI: false,
      statusModal: '',
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrl: this.$store.state.rootApi + url + '/getBuyingListAll?status=All',
      urlPdf: '#',
      items: [
        {
          text: 'Master',
          href: '/',
        },
        {
          text: 'DownPayment',
          href: '/multiBuyingDownPayment',
        },
        {
          text: 'List',
          active: true,
        },
      ],
      lengthMenu: [5, 10, 25, 50, 100],
      perPage: 5,
      fieldsWIP: [
        {
          name: 'counting_number',
          title: 'No',
          // sortField: 'vendor_name',
        },
        {
          name: 'number_ori',
          title: 'Original Number',
          sortField: 'number_ori',
        },
        {
          name: 'pi_number',
          title: 'Vendor Invoice / Settlement Number',
          sortField: 'pi_number',
        },
        {
          name: 'vendor_name',
          title: 'Vendor Name',
          sortField: 'vendor_name',
        },
        {
          name: 'cost_date',
          title: 'Date',
          sortField: 'cost_date',
        },
        {
          name: 'count_item',
          title: 'Item',
          // sortField: 'count_item',
        },
        {
          name: 'total_qty',
          title: 'Qty',
          // sortField: 'total_qty',
        },
        {
          name: 'ppn_cost',
          title: 'PPN',
          // sortField: 'ppn_cost',
        },
        // {
        //   name: 'pph_cost',
        //   title: 'PPH',
        //   // sortField: 'ppn_cost',
        // },
        {
          name: 'grandTotal',
          title: 'Grand Total',
          // sortField: 'grandTotal',
        },
        {
          name: 'statusWIP',
          title: 'Status WIP',
          // sortField: 'grandTotal',
        },
        {
          name: 'JVNumber',
          title: 'Journal Voucher Number',
          // sortField: 'grandTotal',
        },
        // {
        //   name: 'count_pi',
        //   title: 'Purchase Invoice',
        //   sortField: 'count_pi',
        // },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],
      fields: [
        {
          name: 'counting_number',
          title: 'No',
          // sortField: 'vendor_name',
        },
        {
          name: 'number_ori',
          title: 'Original Number',
          sortField: 'number_ori',
        },
        {
          name: 'pi_number',
          title: 'Vendor Invoice / Settlement Number',
          sortField: 'pi_number',
        },
        {
          name: 'vendor_name',
          title: 'Vendor Name',
          sortField: 'vendor_name',
        },
        {
          name: 'cost_date',
          title: 'Date',
          sortField: 'cost_date',
        },
        {
          name: 'count_item',
          title: 'Item',
          // sortField: 'count_item',
        },
        {
          name: 'total_qty',
          title: 'Qty',
          // sortField: 'total_qty',
        },
        {
          name: 'ppn_cost',
          title: 'PPN',
          // sortField: 'ppn_cost',
        },
        // {
        //   name: 'pph_cost',
        //   title: 'PPH',
        //   // sortField: 'ppn_cost',
        // },
        {
          name: 'grandTotal',
          title: 'Grand Total',
          // sortField: 'grandTotal',
        },
        // {
        //   name: 'count_pi',
        //   title: 'Purchase Invoice',
        //   sortField: 'count_pi',
        // },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],
      moreParamsDP: {},
      filterTextDP: '',
      deleteDataDP: {},
      httpOptionsDP: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrlDP: this.$store.state.rootApi + urlDP + '/getBuyingDownPaymentList?id_job_order=0&not_finished=1',
      urlPdfDP: '#',
      lengthMenuDP: [5, 10, 25, 50, 100],
      perPageDP: 5,
      fieldsDP: [
        {
          name: 'chk_select',
          title: 'Choose',
          sortField: 'chk_select',
        },
        {
          name: 'vendor_name',
          title: 'Vendor',
          sortField: 'vendor_name',
        },
        {
          name: 'down_payment',
          title: 'Cash Advance',
          sortField: 'down_payment',
        },
        {
          name: 'number',
          title: 'Cash Advance Number',
          sortField: 't_job_order_buying_down_payment.number',
        },
        {
          name: 'PPNumber',
          title: 'PP Number',
          sortField: 'PPNumber',
        },
        {
          name: 'PPNumber_tax',
          title: 'PP Number Tax',
          sortField: 'PPNumber_tax',
        },
        {
          name: 'payment_method',
          title: 'Payment Method',
          sortField: 'payment_method',
        },
        {
          name: 'disc_acc_name',
          title: 'Discount Account',
          sortField: 'disc_acc_name',
        },
        {
          name: 'discount',
          title: 'Discount',
          sortField: 'discount',
        },
        {
          name: 'count_pi',
          title: 'Vendor Invoice / Settlement',
          sortField: 'count_pi',
        },
      ],
      moreParamsPO: {},
      filterTextPO: '',
      deleteDataPO: {},
      httpOptionsPO: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrlPO: this.$store.state.rootApi + urlPO,
      urlPdfPO: '#',
      lengthMenuPO: [5, 10, 25, 50, 100],
      perPagePO: 5,
      fieldsPO: [
        {
          name: 'chk_select',
          title: 'Choose',
          sortField: 'chk_select',
        },
				{
					name: 'no',
					title: 'No'
				},
				{
					name: 'po_number',
					title: 'Create Accrual Cost Step 1',
					sortField: 'po_number',
				},
				{
					name: 'vendor',
					title: 'Vendor',
					sortField: 'vendor',
				},
				{
					name: 'pic',
					title: 'PIC',
				},
				{
					name: 'date',
					title: 'Date',
					sortField: 'date',
				},
				{
					name: 'item',
					title: 'Item',
				},
				{
					name: 'ppn',
					title: 'PPN',
					sortField: 'ppn',
				},
        {
					name: 'currency',
					title: 'Currency'
				},
				{
					name: 'grand_total',
					title: 'Grand Total',
				},
				{
					name: 'applied_transaction',
					title: 'Applied Transaction',
				}, {
					name: 'status',
					title: 'Status',
				},
				{
					name: 'info',
					title: 'Info',
				}
			],
      moreParamsRI: {},
      filterTextRI: '',
      deleteDataRI: {},
      httpOptionsRI: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrlRI: this.$store.state.rootApi + urlRI,
      urlPdfRI: '#',
      lengthMenuRI: [5, 10, 25, 50, 100],
      perPageRI: 5,
      urlCi: process.env.VUE_APP_CI_URL,
      fieldsRI: [
        {
          name: 'chk_select',
          title: 'Choose',
          // sortField: 'chk_select',
        },
        {
          name: 'no',
          title: 'No',
          //   sortField: 'no',
        },
        {
          name: 'po_number',
          title: 'Create Accrual Cost Step 1 Number',
          // sortField: 'po_number',
          titleClass: 'ml-5',
          dataClass: 'ml-5',
        },
        {
          name: 'number',
          title: 'Create Accrual Cost Step 2 Number',
          sortField: 'number',
        },
        {
          name: 'vendor',
          title: 'Vendor',
          sortField: 'vendor',
        },
        {
          name: 'date',
          title: 'Date',
          sortField: 'date',
        },

        {
          name: 'item',
          title: 'Item',
          // sortField: 'item',
        },
        {
          name: 'applied_transaction',
          title: 'Apply Transaction',
          // sortField: 'apply_transaction',
        },
        {
          name: 'status',
          title: 'Status',
          // sortField: 'status',
        },
      ],
      css: {
        pagination: {
          activeClass: 'btn-primary text-white',
          pageClass: 'btn btn-sm ',
          linkClass: 'btn btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
      momentFormat: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataBuyingDownPayment.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatEdit: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataSingleBuyingDownPayment.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatEditBuying: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataSingleBuying.cost_date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatBuyingCopy: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataBuying.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatBuying: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataBuyingCopy.date).format('YYYY-MM-DD') : ''
        },
      },
      dateFormating(date) {
        return date ? moment(date).format('DD MMM YYYY') : ''
      },
      dateFormatingTax: {
        stringify: (date) => {
          console.log(date)
          return date ? moment(date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatPP: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataBuyingPurchasePayment.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatPPTax: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataBuyingPurchasePaymentTax.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatPPEdit: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataSingleBuyingPurchasePayment.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatPPTaxEdit: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataSingleBuyingPurchasePaymentTax.date).format('YYYY-MM-DD') : ''
        },
      },
      momentFormatJV: {
        // this.dataPayload.year
        stringify: (date) => {
          console.log(date)
          return date ? moment(this.dataJournalVoucher.date).format('YYYY-MM-DD') : ''
        },
      },
      dataBuyingDownPayment: {
        currency: '',
        term: '',
        number: '',
        numberText: '',
        invoice: '',
        vendor: '',
        date: '',
        ppn: '',
        docCode: '',
        taxType: '',
        taxNo: '',
        taxDate: '',
        commercialRate: '',
        remarks: '',
        fiscalRate: '',
        inclusiveTax: 0,
        downPayment: 0,
        taxable: 0
      },
      dataBuyingPurchasePayment: {
        currency: '',
        bank: '',
        number: '',
        numberText: '',
        paymentMethod: '',
        vendor: '',
        date: '',
        discountAccount: '',
        discount: 0,
        remarks: '',
        commercialRate: '',
        fiscalRate: '',
        discountType: '',
        // inclusiveTax: 0,
        currencyBank: '',
        amount: 0,
        taxable: 0
      },
      dataBuyingPurchasePaymentTax: {
        currency: '',
        bank: '',
        number: '',
        numberText: '',
        paymentMethod: '',
        vendor: '',
        date: '',
        discountAccount: '',
        discount: 0,
        remarks: '',
        commercialRate: '',
        fiscalRate: '',
        discountType: '',
        // inclusiveTax: 0,
        currencyBank: '',
        amount: 0,
        taxable: 0
      },
      dataJournalVoucher: {
        number: '',
        numberText: '',
        date: '',
        memo: '',
        id_buying: 0
      },
      showRate: false,
      showRatePP: false,
      showRatePPTax: false,
      isManualNumberBuyingDownPayment: false,
      isManualNumberBuyingPurchasePayment: false,
      isManualNumberBuyingPurchasePaymentTax: false,
      isManualNumberBuying: false,
      isManualNumberBuyingEdit: false,
      isManualNumberBuyingCopy: false,
      isManualNumberJV: false,
      isTruck: false,
      isCopyTransaction: "0",
      inclusiveTax: 0,
      showTaxType: true,
      dataSingleBuyingNumber: '',
      paymentMethods: [
        {
          id: "CASH_OTHER",
          name: "Cash/Other"
        },
        {
          id: "BANK_CHEQUE",
          name: "Cheque/Clearing"
        },
        {
          id: "BANK_TRANSFER",
          name: "Transfer Bank"
        },
      ],
      typeCopies: [
        {
          id: "PURCHASE_ORDER",
          name: "Create Accrual Cost Step 1"
        },
        {
          id: "RECEIVE_ITEM",
          name: "Create Accrual Cost Step 2"
        },
        {
          id: "DOWN_PAYMENT_MULTI_JOBFILE",
          name: "Cash Advance"
        },
      ],
      dataBuying: {
        currency: '',
        term: '',
        number: '',
        numberText: '',
        invoice: '',
        vendor: '',
        date: '',
        ppn: '',
        docCode: '',
        taxType: '',
        taxNo: '',
        taxDate: '',
        commercialRate: '',
        remarks: '',
        fiscalRate: '',
        inclusiveTax: false,
        idDownPayment: 0,
        downPayment: 0,
        dateDownPayment: ''
      },
      itemBuying: [{
        item: '',
        price: '',
        qty: '',
        remarks: '',
        truck: '',
        isTruck: 0,
        idJobfile: 0,
      }],
      dataBuyingCopy: {
        currency: '',
        term: '',
        number: '',
        numberText: '',
        invoice: '',
        vendor: '',
        date: '',
        ppn: '',
        docCode: '',
        taxType: '',
        taxNo: '',
        taxDate: '',
        remarks: '',
        commercialRate: '',
        fiscalRate: '',
        inclusiveTax: false,
        idDownPayment: 0,
        downPayment: 0,
        dateDownPayment: ''
      },
      itemBuyingCopy: [{
        item: '',
        price: '',
        qty: '',
        remarks: '',
        truck: '',
        isTruck: 0,
        idJobfile: '',
      }],
      discountTypes: [{
        id: 2,
        name: "%"
      },
      {
        id: 3,
        name: "Nominal"
      }],
      isEnableTabTax: false,
      editTaxAble: false,
      createFrom: '',
      trxSelected: '',
      isLoadingForm: false,
      pageActive: 1,
      totalOfSubTotal: 0,
      sendKurs: {},
      statusType: 'All',
      checkDp: false,
      swap:{
        ppn:'',
        pph:'',
        docCode:'',
        taxType:'',
        taxNo: '',
        taxDate: '',
        id_jobfile_cost:'',
        id_down_payment:'',
        remarks: ''
      },
      listItemNew:[],
      showTaxDetail: false,
      showTaxInput: false,
      isTaxDateChecked: false
    }
  },
  computed: {
    ...mapState('port', ['listPort']),
    ...mapState('vendor', ['listVendor', 'dataVendor']),
    ...mapState('truck', ['listTruck']),
    ...mapState('autonumber', ['listAutonumber', 'listAutonumberPP', 'listAutonumberPPTax', 'listAutonumberJV']),
    ...mapState('currency', ['listCurrency','dataCurrency', 'dataDefaultCurrency', 'kursData']),
    ...mapState('item', ['listItem', 'listItemNonInventory', 'listItemBank', 'dataItem','listItems','listItemTax']),
    ...mapState('term', ['listTerm']),
    ...mapState('company', ['companyIsAol', 'companyIsUsingPPH']),
    ...mapState('multiPurchaseInvoice', ['listBuyingDownPayment', 'dataSingleBuyingDownPayment', 'dataSingleBuyingPurchasePayment', 'dataSingleBuyingPurchasePaymentTax', 'dataBalanceDP', 'dataSingleBuying', 'listNumberReff']),
    ...mapState('jobOrderBuying', ['listDocCode', 'listTaxTypeBuying']),
    ...mapState('percentage', ['listPercentage', 'listPPH']),
    ...mapState('multiBuyingDownPayment', ['listDpByVendor']),
    ...mapState('jobfile', ['listJobfile']),
  },
  mounted() {
    this.getListPort()
    this.getListVendor()
    this.p_getDefaultCurrency()
    this.initCurrency()
    this.getItemNonInventoryDiffVar()
    // this.getListTerm()
    // this.getListItem()
    this.getListPercentage()
    this.getListPPH()
    this.getListTruck()
    // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
    this.getListBuyingDownPayment(null)
    this.isAol();
    this.isUsingPPH();
    this.getItemByCategoryBank({
      itemCategory: 3
    });
  },
  methods: {
    ...authMethods,
    loginByToken() {
      console.log('login by token')
    },
    ...mapActions('port', ['getListPort']),
    ...mapActions('vendor', ['getListVendor', 'getVendorById']),
    ...mapActions('truck', ['getListTruck']),
    ...mapActions('autonumber', ['getListAutonumber', 'getListAutonumberFlexmod', 'getListAutonumberPP', 'getListAutonumberPPTax', 'getListAutonumberJV']),
    ...mapActions('currency', ['getListCurrency', 'getDefaultCurrency', 'getKurs', 'getKursWithDate']),
    ...mapActions('item', ['getItemByPpn', 'getItemByCategory', 'getItemNonInventoryDiffVar', 'getItemByCategoryBank', 'getItem', 'getListItem', 'getItemByTax']),
    ...mapActions('term', ['getListTerm']),
    // ...mapActions('quotation', ['saveQuotation']),
    ...mapActions('percentage', ['getListPercentage', 'getListPPH']),
    ...mapActions('company', ['isAol', 'isUsingPPH']),
    ...mapActions('jobfile', ['getListJobfile']),
    // ...mapActions('quotation', ['deleteQuotation', 'generatePdf']),
    // ...mapActions('favoriteJobOrder', ['saveNewFavorite', 'loadFavorite', 'deleteFavJobOrder']),
    ...mapActions('multiPurchaseInvoice', ['saveBuyingDownPayment', 'getListBuyingDownPayment',
                  'getBuyingDownPayment', 'getBuying', 'getBuyingPurchasePayment', 'getBuyingPurchasePaymentTax',
                  'getBuyingPurchasePaymentTaxDefault', 'updateBuyingDownPayment', 'deleteBuyingDownPayment', 'deleteBuying',
                  'submitAOL', 'submitAOLPP', 'submitAOLPPTax', 'submitAOLPI', 'saveBuyingCopy', 'saveBuyingManual',
                  'deleteBuyingDownPaymentWithBuying', 'getBalanceDP', 'updateBuying', 'getListNumberReff', 'sendToJV' , 'swapItems','hardDeleteBuying']),
    ...mapActions('jobOrderBuying', ['getListDocCode', 'getListTaxTypeBuying', 'getListBuying']),
    ...mapActions('purchaseOrder', ['getlistBalancePO', 'getlistBalancePOMulti']),
    ...mapActions('multiBuyingDownPayment', ['getListDpByVendor']),
    ...mapActions('receiveableItem', ['getlistBalanceRI', 'getlistBalanceRIMulti']),

    searchItems(e) {
      if (!this.searchItem) {
        this.listItemTax = this.listItemTaxCopy;
      }

      this.listItemTax = this.listItemTaxCopy.filter((fruit) => {
        return fruit.toLowerCase().indexOf(this.searchItem.toLowerCase()) > -1;
      });
    },
    doFilter() {
      this.onFilterSet(this.filterText)
    },
    doFilterDP() {
      this.onFilterSetDP(this.filterTextDP)
    },
    doFilterPO() {
      this.onFilterSetPO(this.filterTextPO)
    },
    doFilterRI() {
      this.onFilterSetRI(this.filterTextRI)
    },
    /**
     * Table Length Change
     *
     * @return  {[type]}  [return description]
     */
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    onChangeTableLengthDP() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetableDP.refresh())
    },
    onChangeTableLengthPO() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetablePO.refresh())
    },
    onChangeTableLengthRI() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetableRI.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
      this.pageActive = page
    },

    onChangePageDP(page) {
      this.$refs.vuetableDP.changePage(page)
    },

    onChangePagePO(page) {
      this.$refs.vuetablePO.changePage(page)
    },

    onChangePageRI(page) {
      this.$refs.vuetableRI.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },

    onPaginationDataDP(paginationData) {
      this.$refs.paginationDP.setPaginationData(paginationData)
      this.$refs.paginationInfoDP.setPaginationData(paginationData)
    },
    onPaginationDataPO(paginationData) {
      this.$refs.paginationPO.setPaginationData(paginationData)
      this.$refs.paginationInfoPO.setPaginationData(paginationData)
    },
    onPaginationDataRI(paginationData) {
      this.$refs.paginationRI.setPaginationData(paginationData)
      this.$refs.paginationInfoRI.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet(filterText) {
      this.moreParams = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterSetDP(filterText) {
      this.moreParamsDP = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetableDP.refresh())
    },
    onFilterSetPO(filterText) {
      this.moreParamsPO = {
        search: filterText,
        id_vendor: this.dataBuyingCopy.vendor,
        id_currency: this.dataBuyingCopy.currency,
        ppn: this.dataBuyingCopy.ppn
      }
      setTimeout(() => {
        this.$nextTick(() => this.$refs.vuetablePO.refresh())
      }, 100)
    },

    onFilterSetRI(filterText) {
      this.moreParamsRI = {
        search: filterText,
        id_vendor: this.dataBuyingCopy.vendor,
        id_currency: this.dataBuyingCopy.currency,
        ppn: this.dataBuyingCopy.ppn
      }
      setTimeout(() => {
        this.$nextTick(() => this.$refs.vuetableRI.refresh())
      }, 100)
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTable() {
      this.isLoadingTable = true
    },

    loadingTableDP() {
      this.isLoadingTableDP = true
    },
    loadingTablePO() {
      this.isLoadingTablePO = true
    },
    loadingTableRI() {
      this.isLoadingTableRI = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccess() {
      this.isLoadingTable = false
    },

    loadTableSuccessDP() {
      this.isLoadingTableDP = false
    },

    loadTableSuccessPO() {
      this.isLoadingTablePO = false
    },

    loadTableSuccessRI() {
      this.isLoadingTableRI = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailed(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },

    loadTableFailedDP(error) {
      console.log(error)

      this.isLoadingTableDP = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },

    loadTableFailedPO(error) {
      console.log(error)

      this.isLoadingTablePO = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },

    loadTableFailedRI(error) {
      console.log(error)

      this.isLoadingTableRI = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },

    nextSwapItem(bvModalEvent){
      bvModalEvent.preventDefault()

      if(this.companyIsUsingPPH === 1){
        if(this.swap.ppn === "" || this.swap.pph === "" ) {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field PPN , PPH",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false;
        }
      }else{
        if(this.swap.ppn === "" ) {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field PPN",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false;
        }
      }
      //  this.isLoadingForm = true
      console.log(this.swap, 'testing swap')
      if(parseInt(this.swap.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }


      this.$nextTick(() => {
        this.$bvModal.hide('modalSetupTaxes')
      })

      try {
        this.getBuying(this.swap.id_jobfile_cost)
          .then((res) => {


          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
       this.getListJobfile()
       this.getListItem()

      if(this.companyIsUsingPPH === 1) {
        if(this.swap.ppn !== "" && this.swap.ppn !== undefined && this.swap.ppn !== null && this.swap.pph !== "" && this.swap.pph !== undefined && this.swap.pph !== null) {
          this.getItemByTax({
            ppn: this.swap.ppn,
            pph: this.swap.pph === undefined ? 0 : this.swap.pph,
            itemCategory: 1
          })

        }
      } else {
        this.getItemByPpn({
          ppn: this.swap.ppn,
          itemCategory: 1
        })
      }

      this.$bvModal.show('modalSwapItem')

    },
    swapItem(bvModalEvent){
      bvModalEvent.preventDefault()
      if(this.companyIsUsingPPH === 1){
        if(this.swap.ppn === "" || this.swap.pph === "" ) {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field PPN , PPH",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false;
        }
      }else{
        if(this.swap.ppn === "" ) {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field PPN",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false;
        }
      }
      // console.log(this.swap , 'testingsdfsd')
      // console.log(this.dataSingleBuying.items,' list item new')
      this.swap.items = this.dataSingleBuying.items
      console.log(this.swap.items, 'isi swap items')

      let headers = {
        'Content-Type': 'application/json'
      }

      this.swapItems({
          payload: this.swap,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })

              this.$nextTick(() => {
                this.$bvModal.hide('modalSwapItem')
              })

              this.$nextTick(() => this.$refs.vuetable.refresh())
            } else {
              this.$nextTick(() => {
                this.$bvModal.hide('modalSwapItem')
              })


              Swal.fire({
                title: "Failed!",
                text: res.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
              this.$nextTick(() => this.$refs.vuetable.refresh())

            }

        })
        .catch((err) => {
          // this.isLoadingForm = false
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },

    showConfirmDelete(id) {
      this.deleteData.id = id
      let self = this
      // this.$swal({
      // 	title: 'Are you sure?',
      // 	text: 'You will not be able to recover this!',
      // 	type: 'warning',
      // 	showCancelButton: true,
      // 	confirmButtonText: 'Yes, delete it!',
      // 	cancelButtonText: 'No, keep it',
      // }).then((result) => {
      // 	if (result.value) {
      // 		this.$swal('Deleted!', 'Your data has been deleted.', 'error')
      // 		self.onDelete()
      // 	} else if (result.dismiss) {
      // 		this.$swal('Cancelled', 'Your data is safe :)', 'error')
      // 	}
      // })
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          self.onDelete()
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        }
      })
    },
    /**
     * Delete Brand
     *
     * @return  {[type]}  [return description]
     */
    onDelete() {
      // this.isLoadingTable = true
      this.deleteQuotation(this.deleteData.id)
        .then((res) => {
          this.successAlert()
          // this.isLoadingTable = false;
          this.$refs.vuetable.refresh()
        })
        .catch((err) => {
          this.errorAlert()
          console.log(err.response.message)

          // this.isLoadingTable = false;
        })
    },
    openModalBuyingDownPayment() {
      // this.getListDocCode()
      // this.listAutonumber = []
      // this.getListAutonumber(31)
      // this.getListAutonumberPP(32)
      // this.getListAutonumberPPTax(35)
      // console.log('OKEEE')
      // // this.dataBuyingDownPayment = {
      // //   currency: '',
      // //   term: '',
      // //   number: '',
      // //   numberText: '',
      // //   po: '',
      // //   customer: '',
      // //   date: '',
      // //   ppn: '',
      // //   docCode: '',
      // //   taxType: '',
      // //   commercialRate: '',
      // //   fiscalRate: '',
      // //   inclusiveTax: false
      // // }

      // this.dataBuyingDownPayment.term = ''
      // this.dataBuyingDownPayment.number = ''
      // this.dataBuyingDownPayment.numberText = ''
      // this.dataBuyingDownPayment.invoice = ''
      // this.dataBuyingDownPayment.vendor = ''
      // this.dataBuyingDownPayment.date = ''
      // this.dataBuyingDownPayment.ppn = ''
      // this.dataBuyingDownPayment.docCode = ''
      // this.dataBuyingDownPayment.taxType = ''
      // this.dataBuyingDownPayment.commercialRate = ''
      // this.dataBuyingDownPayment.fiscalRate = ''
      // this.dataBuyingDownPayment.downPayment = 0
      // this.dataBuyingDownPayment.taxable = '0'
      // this.dataBuyingDownPayment.description = ''
      // this.dataBuyingDownPayment.inclusiveTax = false

      // this.itemBuying = [
      //   {
      //     item: '',
      //     price: '',
      //     qty: '',
      //     itemDescription: '',
      //     truck: '',
      //     isTruck: 0,
      //     idJobfile: '',
      //   }
      // ]

      // this.dataBuyingPurchasePayment = {
      //   currency: '',
      //   bank: '',
      //   number: '',
      //   numberText: '',
      //   paymentMethod: '',
      //   vendor: '',
      //   date: '',
      //   discountAccount: '',
      //   discount: 0,
      //   remarks: '',
      //   commercialRate: '',
      //   fiscalRate: '',
      //   discountType: '',
      //   // inclusiveTax: 0,
      //   currencyBank: '',
      //   amount: 0,
      //   taxable: 0
      // }

      // this.dataBuyingPurchasePaymentTax = {
      //   currency: '',
      //   bank: '',
      //   number: '',
      //   numberText: '',
      //   paymentMethod: '',
      //   vendor: '',
      //   date: '',
      //   discountAccount: '',
      //   discount: 0,
      //   remarks: '',
      //   commercialRate: '',
      //   fiscalRate: '',
      //   discountType: '',
      //   // inclusiveTax: 0,
      //   currencyBank: '',
      //   amount: 0,
      //   taxable: 0
      // }
      this.createFrom = ''
      this.isTaxDateChecked = false
      this.$bvModal.show('modalTransactionType')
    },
    chooseTransaction(bvModalEvent) {
      bvModalEvent.preventDefault()     
      if(this.createFrom === '') {
        Swal.fire({
          title: "Failed!",
          text: "Please choose Transaction Type",
          icon: 'warning',
          confirmButtonColor: 'rgb(221, 107, 85)',
          confirmButtonText: 'Ok'
        })

        return false;
      }

      this.trxSelected = ''

      this.itemBuying = [
        {
          item: '',
          price: '',
          qty: '',
          itemDescription: '',
          truck: (this.listTruck[0] !== undefined && this.listTruck[0] !== null && this.listTruck[0] !== '') ? this.listTruck[0].id : '',
          isTruck: 0,
          idJobfile: '',
        }
      ]

      if(this.createFrom === "DOWN_PAYMENT_MULTI_JOBFILE") {
        this.statusModal = 'createDownPaymentMultiJobfile'
        this.showTaxDetail = false
         this.showTaxInput = false
        this.$bvModal.show('modalBuyingDownPayment')
      } else if(this.createFrom === "PURCHASE_ORDER") {
        this.statusModal = 'createPo'
        // this.listAutonumber = []
        // this.listItem = []
        this.getListAutonumber(1)
        this.getListDocCode()
        this.getListJobfile()
        this.totalOfSubTotal = 0;
        this.dataBuyingCopy.term = ''
         this.dataBuyingCopy.number = ''
         this.dataBuyingCopy.numberText = ''
         this.dataBuyingCopy.invoice = ''
         this.dataBuyingCopy.vendor = ''
         this.dataBuyingCopy.date = ''
         this.dataBuyingCopy.taxDate = ''
         this.dataBuyingCopy.taxNo = ''
         this.dataBuyingCopy.ppn = ''
         this.dataBuyingCopy.pph = ''
         this.dataBuyingCopy.docCode = ''
         this.dataBuyingCopy.taxType = ''
         this.dataBuyingCopy.commercialRate = ''
         this.dataBuyingCopy.fiscalRate = ''
         this.dataBuyingCopy.inclusiveTax = false
         this.dataBuyingCopy.idPurchaseOrder = 0
         this.dataBuyingCopy.downPayment = 0
         this.dataBuyingCopy.dateDownPayment = ''
         this.dataBuyingCopy.remarks = ''
         this.showTaxDetail = false
         this.showTaxInput = false

        this.dataBuyingCopy.currency = this.dataDefaultCurrency.id

        this.$bvModal.show('modalPurchaseOrder')
      } else if(this.createFrom === "RECEIVE_ITEM") {
        this.statusModal = 'createRi'
        this.getListAutonumber(1)
        this.getListDocCode()
        this.getListJobfile()

        this.dataBuyingCopy.term = ''
         this.dataBuyingCopy.number = ''
         this.dataBuyingCopy.numberText = ''
         this.dataBuyingCopy.invoice = ''
         this.dataBuyingCopy.vendor = ''
         this.dataBuyingCopy.date = ''
         this.dataBuyingCopy.ppn = ''
         this.dataBuyingCopy.pph = ''
         this.dataBuyingCopy.docCode = ''
         this.dataBuyingCopy.taxType = ''
         this.dataBuyingCopy.commercialRate = ''
         this.dataBuyingCopy.fiscalRate = ''
         this.dataBuyingCopy.inclusiveTax = false
         this.dataBuyingCopy.idPurchaseOrder = 0
         this.dataBuyingCopy.downPayment = 0
         this.dataBuyingCopy.dateDownPayment = ''
         this.dataBuyingCopy.remarks = ''
         this.showTaxDetail = false
         this.showTaxInput = false

        this.dataBuyingCopy.currency = this.dataDefaultCurrency.id

        this.$bvModal.show('modalReceiveItem')
      }

    },
    getTgl() {
      this.dataBuyingDownPayment.date = moment(this.dataBuyingDownPayment.date).format('YYYY-MM-DD')
    },
    getTglPP() {
      this.dataBuyingPurchasePayment.date = moment(this.dataBuyingPurchasePayment.date).format('YYYY-MM-DD')
    },
    getTglPPTax() {
      this.dataBuyingPurchasePaymentTax.date = moment(this.dataBuyingPurchasePaymentTax.date).format('YYYY-MM-DD')
    },
    getTglPPEdit() {
      this.dataSingleBuyingPurchasePayment.date = moment(this.dataSingleBuyingPurchasePayment.date).format('YYYY-MM-DD')
    },
    getTglPPTaxEdit() {
      this.dataSingleBuyingPurchasePaymentTax.date = moment(this.dataSingleBuyingPurchasePaymentTax.date).format('YYYY-MM-DD')
    },
    getTglEdit() {
      this.dataSingleBuyingDownPayment.date = moment(this.dataSingleBuyingDownPayment.date).format('YYYY-MM-DD')
    },
    getTglEditBuying() {
      this.dataSingleBuying.cost_date = moment(this.dataSingleBuying.cost_date).format('YYYY-MM-DD')
    },
    getTglBuyingCopy() {
      this.changeCurrency()
      this.dataBuying.date = moment(this.dataBuying.date).format('YYYY-MM-DD')
    },
    getTglBuying() {
      this.changeCurrency()
      this.dataBuyingCopy.date = moment(this.dataBuyingCopy.date).format('YYYY-MM-DD')
    },
    getTglTaxBuying() {
      this.dataBuyingCopy.taxDate = moment(this.dataBuyingCopy.taxDate).format('YYYY-MM-DD')
    },
    getTglTaxBuyingSwap() {
      this.swap.taxDate = moment(this.swap.taxDate).format('YYYY-MM-DD')
    },
    getTglTaxBuyingCopy() {
      this.dataBuying.taxDate = moment(this.dataBuying.taxDate).format('YYYY-MM-DD')
    },
    getTglTaxEdit() {
      this.dataSingleBuyingDownPayment.taxDate = moment(this.dataSingleBuyingDownPayment.taxDate).format('YYYY-MM-DD')
    },
    getTglTaxEditBuying() {
      this.dataSingleBuying.taxDate = moment(this.dataSingleBuying.taxDate).format('YYYY-MM-DD')
    },
    getTglJV() {
      this.dataJournalVoucher.date = moment(this.dataJournalVoucher.date).format('YYYY-MM-DD')
    },
    initCurrency() {
      try {
        this.getListCurrency()
          .then((res) => {
            // this.dataBuyingDownPayment.currency = 2
            // console.log(this.dataDefaultCurrency, 'This Data Default')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    p_getDefaultCurrency() {
      try {
        this.getDefaultCurrency()
          .then((res) => {
            this.dataBuyingDownPayment.currency = this.dataDefaultCurrency.id
            // console.log(this.dataDefaultCurrency, 'This Data Default')
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    submitBuying(bvModalEvent) {
      bvModalEvent.preventDefault()
      console.log(this.dataBuyingDownPayment, 'THIS DATA Selling')
      console.log(this.itemBuying, 'THIS DATA ITEM Selling')
      console.log('OKEE')

      if(this.dataBuyingDownPayment.number === "" && this.dataBuyingDownPayment.numberText === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Number",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuyingDownPayment.vendor === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Vendor",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuyingDownPayment.invoice === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Invoice No",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuyingDownPayment.date === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Date",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      // this.dataBuyingDownPayment.currency = this.dataDefaultCurrency.id

      if(this.dataBuyingDownPayment.currency !== this.dataDefaultCurrency.id) {
        if(this.dataBuyingDownPayment.commercialRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Commercial Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
        if(this.dataBuyingDownPayment.fiscalRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Fiscal Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
      }

      if(parseInt(this.dataBuyingDownPayment.taxable) === 0) {
        this.dataBuyingDownPayment.ppn = 0
        this.dataBuyingDownPayment.inclusiveTax = 'false'
      }

      this.isLoadingForm = true

      let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let mergeData = {
        id_joborder: idJoborder,
        id_default: this.dataDefaultCurrency.id,
        buying: this.dataBuyingDownPayment,
        itemBuying: this.itemBuying
      }

      console.log(mergeData, 'THIS MERGE DATA');

      let headers = {
					'Content-Type': 'application/json',

				}

				this.saveBuyingDownPayment({
						payload: mergeData,
						headers: headers
					})
					.then((res) => {
            this.isLoadingForm = false
						if (res.data.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })

              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })

              this.$nextTick(() => {
                this.$bvModal.hide('modalBuyingDownPayment')
              })

              this.$nextTick(() => this.$refs.vuetable.refresh())
            } else {
              Swal.fire({
                title: "Failed!",
                text: res.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            }
					})
					.catch((err) => {
            this.isLoadingForm = false
						Swal.fire({
              title: "Failed!",
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
					})
    },
    triggerManualNumberBuying() {
      this.isManualNumberBuyingDownPayment = !this.isManualNumberBuyingDownPayment
    },
    triggerManualNumberBuyingCopy() {
      this.isManualNumberBuyingCopy = !this.isManualNumberBuyingCopy
    },
    triggerManualNumberBuyingPurchasePayment() {
      this.isManualNumberBuyingPurchasePayment = !this.isManualNumberBuyingPurchasePayment
    },
    triggerManualNumberBuyingPurchasePaymentTax() {
      this.isManualNumberBuyingPurchasePaymentTax = !this.isManualNumberBuyingPurchasePaymentTax
    },
    triggerManualNumberBuyingEdit() {
      this.isManualNumberBuyingEdit = !this.isManualNumberBuyingEdit
    },
    triggerManualNumberJV() {
      this.isManualNumberJV = !this.isManualNumberJV
      this.dataJournalVoucher.number = ''
      this.dataJournalVoucher.numberText = ''
    },
    cekIsTruck(idx) {
      // if(this.itemBuying[idx].isTruck) {
      //   this.itemBuying[idx].isTruck = '0'
      // } else {
      //   this.itemBuying[idx].isTruck = '1'
      // }

      console.log(this.itemBuying[idx].isTruck)
    },
    cekIsTruckCopy(idx) {
      // if(this.itemBuying[idx].isTruck) {
      //   this.itemBuying[idx].isTruck = '0'
      // } else {
      //   this.itemBuying[idx].isTruck = '1'
      // }

      this.itemBuyingCopy[idx].truck= (this.listTruck[0] !== undefined && this.listTruck[0] !== null && this.listTruck[0] !== '') ? this.listTruck[0].id : ''
      this.itemBuyingCopy[idx].id_department= (this.listTruck[0] !== undefined && this.listTruck[0] !== null && this.listTruck[0] !== '') ? this.listTruck[0].id : ''
    },
    cekIsTruckBuying(idx) {
      // if(this.dataSingleBuying.items[idx].isTruck === '1') {
      //   this.dataSingleBuying.items[idx].isTruck = '1'
      //   console.log(this.dataSingleBuying.items[idx], 'this data buying')
      // } else {
      //   this.dataSingleBuying.items[idx].isTruck = '0'
      // }

      this.dataSingleBuying.items[idx].id_department = ''

      console.log(this.dataSingleBuying.items[idx])
    },
    changeVendor() {
      let getVendor = this.listVendor.find(x => x.id === this.dataBuyingDownPayment.vendor)
      this.getListTerm()
        .then((res) => {
          if(getVendor !== undefined) {
            this.dataBuyingDownPayment.term = parseInt(getVendor.id_term)
          }
        })
        .then(() => {
          this.getVendorById(this.dataBuyingCopy.vendor)
          .then(() => {
            this.dataBuyingCopy.currency = this.dataVendor.id_currency
            this.changeCurrency(this.dataBuyingCopy.currency)
          })
        })
    },
    changeVendorBuying() {
      let getVendor = this.listVendor.find(x => x.id === this.dataBuying.vendor)
      this.getListTerm()
        .then((res) => {
          if(getVendor !== undefined) {
            this.dataBuying.term = parseInt(getVendor.id_term)
          }
        })
        .then(() => {
          this.getVendorById(this.dataBuying.vendor)
          .then(() => {
            this.dataBuying.currency = this.dataVendor.id_currency
            this.changeCurrency(this.dataBuying.currency)
          })
        })
    },
    changeVendorCopy() {
      let getVendor = this.listVendor.find(x => x.id === this.dataBuyingCopy.customer)
      // this.dataBuyingDownPayment.term = this.dataBuyingDownPayment.customer.id_term
      this.getListTerm()
        .then((res) => {
          if(getVendor !== undefined) {
            this.dataBuyingCopy.term = parseInt(getVendor.id_term)
          }
          // this.dataBuyingDownPayment.term = this.dataBuyingDownPayment.customer.id_term
          // let termDefault = this.listTerm.find(x => x.id === this.dataBuyingDownPayment.customer.id_term)

          // if(termDefault !== undefined) {
          //   this.dataBuyingDownPayment.term = termDefault
          // }
        })
    },
    changePpn() {
      if(parseInt(this.dataBuyingDownPayment.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }

      if(this.companyIsUsingPPH === 1) {
        if(this.dataBuyingDownPayment.ppn !== "" && this.dataBuyingDownPayment.ppn !== undefined && this.dataBuyingDownPayment.ppn !== null && this.dataBuyingDownPayment.pph !== "" && this.dataBuyingDownPayment.pph !== undefined && this.dataBuyingDownPayment.pph !== null) {
          this.getItemByTax({
            ppn: this.dataBuyingDownPayment.ppn,
            pph: this.dataBuyingDownPayment.pph === undefined ? 0 : this.dataBuyingDownPayment.pph,
            itemCategory: 1
          })
        } else {
          // Reset PPN
          this.getItemByPpn({
            ppn: 0,
            itemCategory: 234353465
          })
        }
      } else {
        this.getItemByPpn({
          ppn: this.dataBuyingDownPayment.ppn,
          itemCategory: 1
        })
      }
    },
    changePph() {
      if(parseInt(this.dataBuyingDownPayment.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }

      if(this.companyIsUsingPPH === 1) {
        if(this.dataBuyingDownPayment.ppn !== "" && this.dataBuyingDownPayment.ppn !== undefined && this.dataBuyingDownPayment.ppn !== null && this.dataBuyingDownPayment.pph !== "" && this.dataBuyingDownPayment.pph !== undefined && this.dataBuyingDownPayment.pph !== null) {
          this.getItemByTax({
            ppn: this.dataBuyingDownPayment.ppn,
            pph: this.dataBuyingDownPayment.pph === undefined ? 0 : this.dataBuyingDownPayment.pph,
            itemCategory: 1
          })
        } else {
          // Reset PPN
          this.getItemByPpn({
            ppn: 0,
            itemCategory: 234353465
          })
        }
      } else {
        this.getItemByPpn({
          ppn: this.dataBuyingDownPayment.ppn,
          itemCategory: 1
        })
      }
    },
    changePpnCopy() {
      if(parseInt(this.dataBuying.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }

      if(this.companyIsUsingPPH === 1) {
        if(this.dataBuying.ppn !== "" && this.dataBuying.ppn !== undefined && this.dataBuying.ppn !== null && this.dataBuying.pph !== "" && this.dataBuying.pph !== undefined && this.dataBuying.pph !== null) {
          this.getItemByTax({
            ppn: this.dataBuying.ppn,
            pph: this.dataBuying.pph === undefined ? 0 : this.dataBuying.pph,
            itemCategory: 1
          })
        } else {
          // Reset PPN
          this.getItemByPpn({
            ppn: 0,
            itemCategory: 234353465
          })
        }
      } else {
        this.getItemByPpn({
          ppn: this.dataBuying.ppn,
          itemCategory: 1
        })
      }

    },
    changePpnSwap() {
      if(parseInt(this.swap.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }

      if(this.companyIsUsingPPH === 1) {
        if(this.swap.ppn !== "" && this.swap.ppn !== undefined && this.swap.ppn !== null && this.swap.pph !== "" && this.swap.pph !== undefined && this.swap.pph !== null) {
          this.getItemByTax({
            ppn: this.swap.ppn,
            pph: this.swap.pph === undefined ? 0 : this.swap.pph,
            itemCategory: 1
          })
        } else {
          // Reset PPN
          this.getItemByPpn({
            ppn: 0,
            itemCategory: 234353465
          })
        }
      } else {
        this.getItemByPpn({
          ppn: this.swap.ppn,
          itemCategory: 1
        })
      }

    },
    changePphCopy() {
      if(parseInt(this.dataBuying.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }

      if(this.companyIsUsingPPH === 1) {
        if(this.dataBuying.ppn !== "" && this.dataBuying.ppn !== undefined && this.dataBuying.ppn !== null && this.dataBuying.pph !== "" && this.dataBuying.pph !== undefined && this.dataBuying.pph !== null) {
          this.getItemByTax({
            ppn: this.dataBuying.ppn,
            pph: this.dataBuying.pph === undefined ? 0 : this.dataBuying.pph,
            itemCategory: 1
          })
        } else {
          // Reset PPN
          this.getItemByPpn({
            ppn: 0,
            itemCategory: 234353465
          })
        }
      } else {
        this.getItemByPpn({
          ppn: this.dataBuying.ppn,
          itemCategory: 1
        })
      }

    },
    changePphSwap() {
      if(parseInt(this.swap.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }

      if(this.companyIsUsingPPH === 1) {
        if(this.swap.ppn !== "" && this.swap.ppn !== undefined && this.swap.ppn !== null && this.swap.pph !== "" && this.swap.pph !== undefined && this.swap.pph !== null) {
          this.getItemByTax({
            ppn: this.swap.ppn,
            pph: this.swap.pph === undefined ? 0 : this.swap.pph,
            itemCategory: 1
          })
        } else {
          // Reset PPN
          this.getItemByPpn({
            ppn: 0,
            itemCategory: 234353465
          })
        }
      } else {
        this.getItemByPpn({
          ppn: this.swap.ppn,
          itemCategory: 1
        })
      }

    },
    changePpnBuying() {
      if(parseInt(this.dataBuyingCopy.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }

      if(this.companyIsUsingPPH === 1) {
        if(this.dataBuyingCopy.ppn !== "" && this.dataBuyingCopy.ppn !== undefined && this.dataBuyingCopy.ppn !== null && this.dataBuyingCopy.pph !== "" && this.dataBuyingCopy.pph !== undefined && this.dataBuyingCopy.pph !== null) {
          this.getItemByTax({
            ppn: this.dataBuyingCopy.ppn,
            pph: this.dataBuyingCopy.pph === undefined ? 0 : this.dataBuyingCopy.pph,
            itemCategory: 1
          })
        } else {
          // Reset PPN
          this.getItemByPpn({
            ppn: 0,
            itemCategory: 234353465
          })
        }
      } else {
        this.getItemByPpn({
          ppn: this.dataBuyingCopy.ppn,
          itemCategory: 1
        })
      }
    },
    changePphBuying() {
      if(parseInt(this.dataBuyingCopy.ppn) > 0) {
        this.showTaxType = true;
      } else {
        this.showTaxType = false;
      }

      if(this.companyIsUsingPPH === 1) {
        if(this.dataBuyingCopy.ppn !== "" && this.dataBuyingCopy.ppn !== undefined && this.dataBuyingCopy.ppn !== null && this.dataBuyingCopy.pph !== "" && this.dataBuyingCopy.pph !== undefined && this.dataBuyingCopy.pph !== null) {
          this.getItemByTax({
            ppn: this.dataBuyingCopy.ppn,
            pph: this.dataBuyingCopy.pph === undefined ? 0 : this.dataBuyingCopy.pph,
            itemCategory: 1
          })
        } else {
          // Reset PPN
          this.getItemByPpn({
            ppn: 0,
            itemCategory: 234353465
          })
        }
      } else {
        this.getItemByPpn({
          ppn: this.dataBuyingCopy.ppn,
          itemCategory: 1
        })
      }
    },
    changeDocCode() {
      if(this.dataBuyingDownPayment.docCode === 'DIGUNGGUNG') {
        this.showTaxType = false;
        this.showTaxDetail = false;
        this.showTaxInput = false;
        this.dataBuyingDownPayment.taxDate = ''
        this.dataBuyingDownPayment.taxNo = ''
      } else {
        this.showTaxType = true;
        this.showTaxDetail = false;
      }
      this.dataBuyingDownPayment.taxType = ''
      this.getListTaxTypeBuying(this.dataBuyingDownPayment.docCode)
    },
    changeDisc() {
      this.getItem(this.dataBuyingPurchasePayment.discountAccount)
       .then((res) => {
        if(this.dataItem.diskon_type === 0) {
          this.dataBuyingPurchasePayment.discountType = ''
        } else {
          this.dataBuyingPurchasePayment.discountType = this.dataItem.diskon_type
        }
        this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
        // if(this.dataItem.diskon_type === 2) {
        //   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
        // } else {
        //   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
        // }
       })
    },
    changeDiscTax() {
      this.getItem(this.dataBuyingPurchasePaymentTax.discountAccount)
       .then((res) => {
        if(this.dataItem.diskon_type === 0) {
          this.dataBuyingPurchasePaymentTax.discountType = ''
        } else {
          this.dataBuyingPurchasePaymentTax.discountType = this.dataItem.diskon_type
        }
        this.dataBuyingPurchasePaymentTax.discount = this.dataItem.diskon
        // if(this.dataItem.diskon_type === 2) {
        //   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
        // } else {
        //   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
        // }
       })
    },
    changeBank() {
      this.getItem(this.dataBuyingPurchasePayment.bank)
       .then((res) => {
        if(this.dataItem.currency_is_active === 1) {
          this.dataBuyingPurchasePayment.currencyBank = this.dataItem.currency

          if(this.dataBuyingDownPayment.currency !== this.dataBuyingPurchasePayment.currencyBank) {
            this.showRatePP = true
          } else {
            this.showRatePP = false
          }
        } else {
          this.dataBuyingPurchasePayment.currencyBank = this.dataBuyingDownPayment.currency
          this.showRatePP = false
        }
        // if(this.dataItem.diskon_type === 2) {
        //   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
        // } else {
        //   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
        // }
       })
    },
    changeBankTax() {
      // this.getItem(this.dataBuyingPurchasePaymentTax.bank)
      //  .then((res) => {
      //   if(this.dataItem.currency_is_active === 1) {
      //     this.dataBuyingPurchasePaymentTax.currencyBank = this.dataItem.currency

      //     if(this.dataBuyingDownPayment.currency !== this.dataBuyingPurchasePaymentTax.currencyBank) {
      //       this.showRatePP = true
      //     } else {
      //       this.showRatePP = false
      //     }
      //   } else {
      //     this.dataBuyingPurchasePaymentTax.currencyBank = this.dataBuyingDownPayment.currency
      //     this.showRatePP = false
      //   }
      //   // if(this.dataItem.diskon_type === 2) {
      //   //   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
      //   // } else {
      //   //   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
      //   // }
      //  })
    },
    changeBankEdit() {
      this.getItem(this.dataSingleBuyingPurchasePayment.bank)
       .then((res) => {
        this.dataSingleBuyingPurchasePayment.price_kurs = 0
        this.dataSingleBuyingPurchasePayment.fiscal_kurs = 0
        if(this.dataItem.currency_is_active === 1) {
          this.dataSingleBuyingPurchasePayment.id_currency_bank = this.dataItem.currency

          if(this.dataBuyingDownPayment.id_currency !== this.dataSingleBuyingPurchasePayment.id_currency_bank) {
            this.showRatePP = true
          } else {
            this.showRatePP = false
          }
        } else {
          this.dataSingleBuyingPurchasePayment.id_currency_bank = this.dataBuyingDownPayment.id_currency
          this.showRatePP = false
        }
        // if(this.dataItem.diskon_type === 2) {
        //   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
        // } else {
        //   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
        // }
       })
    },
    changeDiscEdit() {
      this.getItem(this.dataSingleBuyingPurchasePayment.discount_account)
       .then((res) => {
        if(this.dataItem.diskon_type === 0) {
          this.dataSingleBuyingPurchasePayment.discount_type = ''
        } else {
          this.dataSingleBuyingPurchasePayment.discount_type = this.dataItem.diskon_type
        }
        this.dataSingleBuyingPurchasePayment.discount = this.dataItem.diskon
        // if(this.dataItem.diskon_type === 2) {
        //   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
        // } else {
        //   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
        // }
       })
      // this.getItem(this.dataSingleBuyingPurchasePayment.discount_account)
      //  .then((res) => {
      //   if(this.dataItem.diskon_type === 2) {
      //     this.dataSingleBuyingPurchasePayment.discount = parseInt(this.dataSingleBuyingDownPayment.down_payment) * parseFloat(this.dataItem.diskon) / 100
      //   } else {
      //     this.dataSingleBuyingPurchasePayment.discount = this.dataItem.diskon
      //   }
      //  })
    },
    changeDiscTaxEdit() {
      this.getItem(this.dataSingleBuyingPurchasePaymentTax.discount_account)
       .then((res) => {
        if(this.dataItem.diskon_type === 0) {
          this.dataSingleBuyingPurchasePaymentTax.discount_type = ''
        } else {
          this.dataSingleBuyingPurchasePaymentTax.discount_type = this.dataItem.diskon_type
        }
        this.dataSingleBuyingPurchasePaymentTax.discount = this.dataItem.diskon
        // if(this.dataItem.diskon_type === 2) {
        //   this.dataBuyingPurchasePayment.discount = parseInt(this.dataBuyingDownPayment.downPayment) * parseFloat(this.dataItem.diskon) / 100
        // } else {
        //   this.dataBuyingPurchasePayment.discount = this.dataItem.diskon
        // }
       })
      // this.getItem(this.dataSingleBuyingPurchasePayment.discount_account)
      //  .then((res) => {
      //   if(this.dataItem.diskon_type === 2) {
      //     this.dataSingleBuyingPurchasePayment.discount = parseInt(this.dataSingleBuyingDownPayment.down_payment) * parseFloat(this.dataItem.diskon) / 100
      //   } else {
      //     this.dataSingleBuyingPurchasePayment.discount = this.dataItem.diskon
      //   }
      //  })
    },
    changeDocCodeCopy() {
      if(this.dataBuying.docCode === 'DIGUNGGUNG') {
        this.showTaxType = false;
        this.showTaxDetail = false;
        this.showTaxInput = false;
        this.dataBuying.taxDate = ''
        this.dataBuying.taxNo = ''
      } else {
        this.showTaxDetail = true;
        this.showTaxType = true;
      }
      this.dataBuying.taxType = ''
      this.getListTaxTypeBuying(this.dataBuying.docCode)
    },
    changeDocCodeSwap() {
      if(this.swap.docCode === 'DIGUNGGUNG') {
        this.showTaxType = false;
        this.showTaxDetail = false;
        this.showTaxInput = false;
        this.swap.taxDate = ''
        this.swap.taxNo = ''
      } else {
        this.showTaxDetail = true;
        this.showTaxType = true;
      }
      this.swap.taxType = ''
      this.getListTaxTypeBuying(this.swap.docCode)
    },
    changeDocCodeBuying() {
      if(this.dataBuyingCopy.docCode === 'DIGUNGGUNG') {
        this.showTaxType = false;
        this.showTaxDetail = false;
        this.showTaxInput = false;
        this.dataBuyingCopy.taxDate = ''
        this.dataBuyingCopy.taxNo = ''
      } else {
        this.showTaxDetail = true;
        this.showTaxType = true;
      }
      this.dataBuyingCopy.taxType = ''
      this.getListTaxTypeBuying(this.dataBuyingCopy.docCode)
    },
    changeDocCodeEdit() {
      if(this.dataSingleBuyingDownPayment.doc_code === 'DIGUNGGUNG') {
        this.showTaxType = false;
        this.showTaxDetail = false;
        this.showTaxInput = false;
        this.dataSingleBuyingDownPayment.taxDate = ''
        this.dataSingleBuyingDownPayment.taxNo = ''
      } else {
        this.showTaxDetail = true;
        this.showTaxType = true;
        
      }
      this.dataSingleBuyingDownPayment.tax_type = ''
      this.getListTaxTypeBuying(this.dataSingleBuyingDownPayment.doc_code)
    },
    changeDocCodeEditBuying() {
      if(this.dataSingleBuying.doc_code === 'DIGUNGGUNG') {
        this.showTaxType = false;
        this.showTaxDetail = false;
        this.showTaxInput = false;
         this.dataSingleBuying.tax_invoice_date = ''
        this.dataSingleBuying.tax_invoice_number = ''
      } else {
        this.showTaxDetail = true;
        this.showTaxType = true;
      }
      this.dataSingleBuying.tax_type_cost = ''
      this.getListTaxTypeBuying(this.dataSingleBuying.doc_code)
    },

    activeTax(event){
      const isChecked = event.target.checked;
      console.log(isChecked)
      if(isChecked){
        this.showTaxInput = true
      } else {
        this.showTaxInput = false
      }
    },

    changeCurrency() {
      // this.dataBuyingDownPayment.currency = value
      let buyingCurr, copyCurr, buyingDate, copyDate;
      [buyingCurr, copyCurr, buyingDate, copyDate] =
      [this.dataBuying.currency,
      this.dataBuyingCopy.currency,
      this.dataBuying.date,
      this.dataBuyingCopy.date]

      // if(copyCurr !== this.dataDefaultCurrency.id || buyingCurr !== this.dataDefaultCurrency.id) {
      this.dataBuyingCopy.commercialRate = 0
      this.dataBuyingCopy.fiscalRate = 0
      this.dataBuying.commercialRate = 0
			this.dataBuying.fiscalRate = 0
      this.sendKurs={}

        if(this.statusModal === 'createBuying'){
          if(buyingCurr !== this.dataDefaultCurrency.id){
            this.sendKurs = {
              date: buyingDate,
              id: buyingCurr
            }

            this.getKursWithDate(this.sendKurs)
            .then(() => {
                if(this.kursData){
                  let commercialRate = parseInt(this.kursData.commercial_rate)
                  let fiscalRate = parseInt(this.kursData.fiscal_rate)
                  this.dataBuying.commercialRate = commercialRate
                  this.dataBuying.fiscalRate = fiscalRate
                }
              })
              this.showRate = true
          } else {
              this.showRate = false
          }
        } else if(this.statusModal === 'createPo' || this.statusModal === 'createRi' || this.statusModal === 'createDownPaymentMultiJobfile'){
          if(copyCurr !== this.dataDefaultCurrency.id){
            this.sendKurs = {
              date: copyDate,
              id: copyCurr
            }
            this.getKursWithDate(this.sendKurs)
              .then(() => {
                  if(this.kursData){
                    let commercialRate = parseInt(this.kursData.commercial_rate)
                    let fiscalRate = parseInt(this.kursData.fiscal_rate)
                    this.dataBuyingCopy.commercialRate = commercialRate
                    this.dataBuyingCopy.fiscalRate = fiscalRate
                  }
                })
                this.showRate = true
        } else {
              this.showRate = false
        }
      }
      // } else {
      //   this.showRate = false
      // }

      if(copyCurr !== 0 || buyingCurr !== 0) {
        this.isEnableTabTax = true
      } else {
        this.isEnableTabTax = false
      }

      // this.dataDefaultCurrency = this.dataBuyingDownPayment.currency
      // console.log(this.dataBuyingDownPayment.currency)
    },
    changeTerm(value) {
      console.log(this.dataBuyingDownPayment.term)
    },
    changeTermBuyingCopy(value) {
      console.log(this.dataBuyingDownPayment.term)
    },
    changeTermBuying(value) {
      console.log(this.dataBuyingCopy.term)
    },
    formatRupiah(angka, prefix){
      console.log(angka, 'THIS ANGKAA')
      angka = angka.replace(/\./g, ',')
			let numberString = angka
      // console.log(numberString, 'THIS ANGKAA 2')
			let split   		= numberString.split(',')
			let sisa     		= split[0].length % 3
			let rupiah     		= split[0].substr(0, sisa)
			let ribuan     		= split[0].substr(sisa).match(/\d{3}/gi)

			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if(ribuan){
				let separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}

			rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix === undefined ? rupiah : (rupiah ? prefix + ' ' + rupiah : '');
		},
    validateBuyingDownPayment() {
      // Ini pake Promise biar ngga asynchronus, karna klo ga pake promise jadinyaa error
      return new Promise((resolve, reject) => {
        let dataIsEmpty = false;
        if(this.trxSelected === "" || this.trxSelected.length < 1) {
          Swal.fire({
            title: "Failed!",
            text: "Please Choose Transaction First",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })

          dataIsEmpty = true;
          reject(console.log('required'))
        }

        if(!dataIsEmpty) {
          if(this.createFrom === "DOWN_PAYMENT_MULTI_JOBFILE") {
            this.getBalanceDP(this.trxSelected)
              .then((res) => {
                console.log(res, 'THIS BALANCE')
                // this.listAutonumber = []
                // this.listItem = []
                this.getListAutonumber(1)
                this.getListDocCode()
                this.getListJobfile()

                this.getItemByPpn({
                  ppn: this.trxPPN,
                  itemCategory: 5345345
                })

                this.dataBuyingCopy.term = ''
                this.dataBuyingCopy.number = ''
                this.dataBuyingCopy.numberText = ''
                this.dataBuyingCopy.invoice = ''
                this.dataBuyingCopy.vendor = this.trxVendor
                this.dataBuyingCopy.date = ''
                this.dataBuyingCopy.currency = res.data.id_currency
                this.dataBuyingCopy.ppn = ''
                this.dataBuyingCopy.pph = ''
                this.dataBuyingCopy.docCode = ''
                this.dataBuyingCopy.taxType = ''
                this.dataBuyingCopy.commercialRate = ''
                this.dataBuyingCopy.fiscalRate = ''
                this.dataBuyingCopy.inclusiveTax = false
                this.dataBuyingCopy.idDownPayment = this.trxSelected
                // this.dataBuyingCopy.downPayment = downPayment - res.claimed
                this.dataBuyingCopy.downPayment = res.data.down_payment - res.claimed
                console.log(res.data.down_payment ,'isi downpayment')
                console.log(res.claimed ,'isi claimed')
                this.dataBuyingCopy.dateDownPayment = this.dateFormating(this.trxDate)

                if(this.dataBuyingCopy.currency !== res.data.id_default) {
                  this.showRate = true;
                } else {
                  this.showRate = false;
                }

                this.changeCurrency(res.data.id_currency)

                this.itemBuyingCopy = [
                  {
                    item: '',
                    price: '',
                    qty: '',
                    remarks: '',
                    truck: (this.listTruck[0] !== undefined && this.listTruck[0] !== null && this.listTruck[0] !== '') ? this.listTruck[0].id : '',
                    isTruck: 0,
                    idJobfile: '',
                  }
                ]

                let getVendor = this.listVendor.find(x => x.id === this.trxVendor)
                this.getListTerm()
                  .then((res) => {
                    if(getVendor !== undefined) {
                      this.dataBuyingCopy.term = parseInt(getVendor.id_term)
                      // this.$bvModal.show('modalBuying')
                      resolve(true)
                    }
                  })
              })
          } else if(this.createFrom === "PURCHASE_ORDER") {
            this.getlistBalancePOMulti(this.trxSelected).then((res) => {

              this.dataBuyingCopy.commercialRate = res[0].amount_kurs
              this.dataBuyingCopy.fiscalRate = res[0].amount_kurs
              this.dataBuyingCopy.idPurchaseOrder = res[0].id

              this.getItemByTax({
                ppn: this.dataBuyingCopy.ppn,
                pph: this.dataBuyingCopy.pph,
                itemCategory: 1
              })

              this.itemBuyingCopy = []

              res.forEach(el => {
                el.purchase_order_item.forEach((dr) => {
                  this.itemBuyingCopy.push({
                    item: dr.item_id,
                    price: 0,
                    qty: 0,
                    remarks: dr.remark,
                    truck: dr.truck,
                    isTruck: dr.isTruck,
                    idJobfile: dr.jobfile_id,
                    isChecked: 0,
                    stockQty: dr.qty,
                    actualAmount: dr.amount,
                    subTotal: 0,
                    id_detail_po: dr.id,
                    id_purchase_order: dr.purchase_order_id,
                    copied_to: dr.copied_to,
                  })
                })
              });

              resolve(true)
            })
          } else if(this.createFrom === "RECEIVE_ITEM") {
            console.log(this.trxSelected, 'THIS TRX SELECTED')
            this.getlistBalanceRIMulti(this.trxSelected).then((res) => {

              this.dataBuyingCopy.idReceiveItem = res[0].id

              this.getItemByTax({
                ppn: this.dataBuyingCopy.ppn,
                pph: this.dataBuyingCopy.pph,
                itemCategory: 1
              })

              this.itemBuyingCopy = []

              res.forEach(el => {
                el.receiveable_item_item.forEach((dr) => {
                  this.itemBuyingCopy.push({
                    item: dr.item_id,
                    price: 0,
                    qty: 0,
                    remarks: dr.remark,
                    truck: dr.truck,
                    isTruck: dr.truck !== 0 ? '1' : '0',
                    idJobfile: dr.jobfile_id,
                    isChecked: 0,
                    stockQty: dr.qty,
                    actualAmount: dr.amount_po,
                    subTotal: 0,
                    id_detail_ri: dr.id,
                    id_receiveable_item: dr.receiveable_id,
                    copied_to: dr.copied_to == null ? "" : dr.copied_to,
                  })
                })
              });

              resolve(true)
            })
          }
        }
      })
    },
    validateBuying() {
      if(this.companyIsUsingPPH === 1) {
        this.getItemByTax({
          ppn: this.dataBuyingCopy.ppn,
          pph: this.dataBuyingCopy.pph,
          itemCategory: 1
        })
      } else {
        this.getItemByPpn({
          ppn: this.dataBuyingCopy.ppn,
          itemCategory: 1
        })
      }
      return new Promise((resolve, reject) => {
        if(this.dataBuyingCopy.number === "" && this.dataBuyingCopy.numberText === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Number",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'));
        }

        if(this.dataBuyingCopy.vendor === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Vendor",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'));
        }

        if(this.dataBuyingCopy.invoice === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Invoice",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'));
        }

        if(this.dataBuyingCopy.date === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Date",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'));
        }

        if(this.dataBuyingCopy.ppn === "" || this.dataBuyingCopy.ppn === null || this.dataBuyingCopy.ppn === undefined) {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field PPN",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'));
        }

        if(this.companyIsUsingPPH === 1) {
          if(this.dataBuyingCopy.pph === "" || this.dataBuyingCopy.pph === null || this.dataBuyingCopy.pph === undefined) {
            Swal.fire({
              title: "Failed!",
              text: "Please insert field PPH",
              icon: 'warning',
              // showCancelButton: true,
              confirmButtonColor: 'rgb(221, 107, 85)',
              // cancelButtonColor: '#C1C1C1',
              // reverseButtons: true,
              confirmButtonText: 'Ok'
            })

            reject(console.log('required'));
          }
        }

        // this.dataBuyingCopy.currency = this.dataDefaultCurrency.id

        if(this.dataBuyingCopy.currency !== this.dataDefaultCurrency.id) {
          if(this.dataBuyingCopy.commercialRate === "") {
            Swal.fire({
              title: "Failed!",
              text: "Please insert field Commercial Rate",
              icon: 'warning',
              // showCancelButton: true,
              confirmButtonColor: 'rgb(221, 107, 85)',
              // cancelButtonColor: '#C1C1C1',
              // reverseButtons: true,
              confirmButtonText: 'Ok'
            })

            reject(console.log('required'))
          }
          if(this.dataBuyingCopy.fiscalRate === "") {
            Swal.fire({
              title: "Failed!",
              text: "Please insert field Fiscal Rate",
              icon: 'warning',
              // showCancelButton: true,
              confirmButtonColor: 'rgb(221, 107, 85)',
              // cancelButtonColor: '#C1C1C1',
              // reverseButtons: true,
              confirmButtonText: 'Ok'
            })

            reject(console.log('required'))
          }
        }

        let itemIsValid = true;

        // this.itemBuyingCopy.forEach((dt, idx) => {
        //   if(dt.item === "" || dt.price === "" || dt.qty === "" || dt.idJobfile === "") {
        //     Swal.fire({
        //       title: "Failed!",
        //       text: "Please insert field jobfile, item, price, and qty in item row " + (idx+1),
        //       icon: 'warning',
        //       // showCancelButton: true,
        //       confirmButtonColor: 'rgb(221, 107, 85)',
        //       // cancelButtonColor: '#C1C1C1',
        //       // reverseButtons: true,
        //       confirmButtonText: 'Ok'
        //     })

        //     itemIsValid = false;
        //   }
        // })

        // this.dataBuyingCopy.term = ''
        //  this.dataBuyingCopy.number = ''
        //  this.dataBuyingCopy.numberText = ''
        //  this.dataBuyingCopy.invoice = ''
        //  this.dataBuyingCopy.vendor = ''
        //  this.dataBuyingCopy.date = ''
        //  this.dataBuyingCopy.ppn = ''
        //  this.dataBuyingCopy.docCode = ''
        //  this.dataBuyingCopy.taxType = ''
        //  this.dataBuyingCopy.commercialRate = ''
        //  this.dataBuyingCopy.fiscalRate = ''
        //  this.dataBuyingCopy.inclusiveTax = false
        //  this.dataBuyingCopy.idPurchaseOrder = 0
        //  this.dataBuyingCopy.downPayment = 0
        //  this.dataBuyingCopy.dateDownPayment = ''

         if(this.createFrom === "DOWN_PAYMENT_MULTI_JOBFILE") {
           // this.$bvModal.show('modalBuyingDownPayment')
        } else if(this.createFrom === "PURCHASE_ORDER") {
          if(this.companyIsUsingPPH === 1) {
            if(this.dataBuyingCopy.ppn !== "" && this.dataBuyingCopy.ppn !== null && this.dataBuyingCopy.ppn !== undefined && this.dataBuyingCopy.ppn !== "" && this.dataBuyingCopy.ppn !== null && this.dataBuyingCopy.ppn !== undefined) {
              this.moreParamsPO = {
                id_vendor: this.dataBuyingCopy.vendor,
                id_currency: this.dataBuyingCopy.currency,
                ppn: this.dataBuyingCopy.ppn,
                pph: this.dataBuyingCopy.pph
              }

              this.$nextTick(() => this.$refs.vuetablePO.refresh())
              setTimeout(() => {
                this.$nextTick(() => this.$refs.vuetablePO.refresh())
              }, 100)
            }
          } else {
            this.moreParamsPO = {
              id_vendor: this.dataBuyingCopy.vendor,
              id_currency: this.dataBuyingCopy.currency,
              ppn: this.dataBuyingCopy.ppn,
              pph: this.dataBuyingCopy.pph,
            }

            this.$nextTick(() => this.$refs.vuetablePO.refresh())
            setTimeout(() => {
              this.$nextTick(() => this.$refs.vuetablePO.refresh())
            }, 100)
          }
          // console.log(this.moreParamsPO)
          // this.$refs.vuetablePO.refresh()

          // this.$nextTick(() => this.$refs.vuetablePO.refresh())
          // setTimeout(() => {
          //   this.$nextTick(() => this.$refs.vuetablePO.refresh())
          // }, 100)
        } else if(this.createFrom === "RECEIVE_ITEM") {
          if(this.companyIsUsingPPH === 1) {
            if(this.dataBuyingCopy.ppn !== "" && this.dataBuyingCopy.ppn !== null && this.dataBuyingCopy.ppn !== undefined && this.dataBuyingCopy.ppn !== "" && this.dataBuyingCopy.ppn !== null && this.dataBuyingCopy.ppn !== undefined) {
              this.moreParamsRI = {
                id_vendor: this.dataBuyingCopy.vendor,
                id_currency: this.dataBuyingCopy.currency,
                ppn: this.dataBuyingCopy.ppn,
                pph: this.dataBuyingCopy.pph
              }

              this.$nextTick(() => this.$refs.vuetableRI.refresh())
              setTimeout(() => {
                this.$nextTick(() => this.$refs.vuetableRI.refresh())
              }, 100)
            }
          } else {
            this.moreParamsRI = {
              id_vendor: this.dataBuyingCopy.vendor,
              id_currency: this.dataBuyingCopy.currency,
              ppn: this.dataBuyingCopy.ppn
            }

            this.$nextTick(() => this.$refs.vuetableRI.refresh())
            setTimeout(() => {
              this.$nextTick(() => this.$refs.vuetableRI.refresh())
            }, 100)
          }

          // this.$nextTick(() => this.$refs.vuetableRI.refresh())
          // setTimeout(() => {
          //   this.$nextTick(() => this.$refs.vuetableRI.refresh())
          // }, 100)
        }

        if(!itemIsValid) {
          reject(console.log('required'))
        }
        resolve(true)
      })
    },
    validateBuyingUpdate() {
      return new Promise((resolve, reject) => {
        if(this.dataSingleBuying.numberText === "" && (this.dataSingleBuyingNumber === "" || this.dataSingleBuyingNumber === 0 || this.dataSingleBuyingNumber === null)) {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Number",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'));
        }

        if(this.dataSingleBuying.id_vendor === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Vendor",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'));
        }

        if(this.dataSingleBuying.invoice_no === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Invoice",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'));
        }

        if(this.dataSingleBuying.cost_date === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Date",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'));
        }

        // this.dataSingleBuying.currency = this.dataDefaultCurrency.id

        if(this.dataSingleBuying.currency_cost !== this.dataDefaultCurrency.id) {
          if(this.dataSingleBuying.price_kurs === "") {
            Swal.fire({
              title: "Failed!",
              text: "Please insert field Commercial Rate",
              icon: 'warning',
              // showCancelButton: true,
              confirmButtonColor: 'rgb(221, 107, 85)',
              // cancelButtonColor: '#C1C1C1',
              // reverseButtons: true,
              confirmButtonText: 'Ok'
            })

            reject(console.log('required'))
          }
          if(this.dataSingleBuying.fiscal_kurs === "") {
            Swal.fire({
              title: "Failed!",
              text: "Please insert field Fiscal Rate",
              icon: 'warning',
              // showCancelButton: true,
              confirmButtonColor: 'rgb(221, 107, 85)',
              // cancelButtonColor: '#C1C1C1',
              // reverseButtons: true,
              confirmButtonText: 'Ok'
            })

            reject(console.log('required'))
          }
        }

        resolve(true)
      })
    },
    validateBuyingItem() {
      console.log('JALAN')
      return new Promise((resolve, reject) => {
        let itemIsValid = true;
				let itemIsExist = false;

        if((this.dataSingleBuying.id_purchase_order !== "" && this.dataSingleBuying.id_purchase_order !== 0 && this.dataSingleBuying.id_purchase_order !== null && this.dataSingleBuying.id_purchase_order !== undefined) || (this.dataSingleBuying.id_receiveable_item !== "" && this.dataSingleBuying.id_receiveable_item !== 0 && this.dataSingleBuying.id_receiveable_item !== null && this.dataSingleBuying.id_receiveable_item !== undefined)) {
          this.dataSingleBuying.items.forEach((dt, idx) => {
            if(parseInt(dt.isChecked) === 1) {
              if(dt.item === "" || dt.price === "" || dt.qty === "" || dt.id_jobfile === "") {
                Swal.fire({
                title: "Failed!",
                text: "Please insert field jobfile, item, price, and qty in item row " + (idx+1),
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonColor: 'rgb(221, 107, 85)',
                // cancelButtonColor: '#C1C1C1',
                // reverseButtons: true,
                confirmButtonText: 'Ok'
                })

                itemIsValid = false;
              }

              if(parseInt(dt.qty === "" ? 0 : dt.qty) < 1) {
                Swal.fire({
                  title: "Failed!",
                  text: "Qty must be filled in item row " + (idx+1),
                  icon: 'warning',
                  // showCancelButton: true,
                  confirmButtonColor: 'rgb(221, 107, 85)',
                  // cancelButtonColor: '#C1C1C1',
                  // reverseButtons: true,
                  confirmButtonText: 'Ok'
                })

                itemIsValid = false;
              }

              if(parseInt(dt.stockQty === "" ? 0 : dt.stockQty) < (parseInt(dt.qty === "" ? 0 : dt.qty) - parseInt(dt.qtyOld === "" ? 0 : dt.qtyOld))) {
                Swal.fire({
                  title: "Failed!",
                  text: "Qty cannot exceed stock in item row " + (idx+1),
                  icon: 'warning',
                  // showCancelButton: true,
                  confirmButtonColor: 'rgb(221, 107, 85)',
                  // cancelButtonColor: '#C1C1C1',
                  // reverseButtons: true,
                  confirmButtonText: 'Ok'
                })

                itemIsValid = false;
              }

              itemIsExist = true
            }
          })

          if(!itemIsExist) {
            Swal.fire({
              title: "Failed!",
              text: "Item Must Be selected, at least one item!",
              icon: 'warning',
              // showCancelButton: true,
              confirmButtonColor: 'rgb(221, 107, 85)',
              // cancelButtonColor: '#C1C1C1',
              // reverseButtons: true,
              confirmButtonText: 'Ok'
            })

            itemIsValid = false;
          }
        } else {
          this.dataSingleBuying.items.forEach((dt, idx) => {
            if((dt.id_item === "" || dt.price === "" || dt.qty === "" || dt.id_jobfile === "") || (dt.id_item === null || dt.price === null || dt.qty === null || dt.id_jobfile === null)) {
              Swal.fire({
                title: "Failed!",
                text: "Please insert field jobfile, item, price, and qty in item row " + (idx+1),
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonColor: 'rgb(221, 107, 85)',
                // cancelButtonColor: '#C1C1C1',
                // reverseButtons: true,
                confirmButtonText: 'Ok'
              })

              itemIsValid = false;
            }
          })
        }

        if(!itemIsValid) {
          reject(console.log('required'))
        }

        resolve(true)
      })
    },
    validatePurchasePayment() {
      // Ini pake Promise biar ngga asynchronus, karna klo ga pake promise jadinyaa error
      return new Promise((resolve, reject) => {
        if(this.dataBuyingPurchasePayment.number === "" && this.dataBuyingPurchasePayment.numberText === "") {
          console.log('OKEEEDDD')
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Number",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'))
        }

        if(this.dataBuyingPurchasePayment.bank === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Bank",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'))
        }

        if(this.dataBuyingPurchasePayment.paymentMethods === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Payment Method",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'))
        }

        if(this.dataBuyingPurchasePayment.date === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Date",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'))
        }

        resolve(true)
      })
    },
    validatePurchasePaymentTax() {
      // Ini pake Promise biar ngga asynchronus, karna klo ga pake promise jadinyaa error
      return new Promise((resolve, reject) => {
        if(this.dataBuyingPurchasePaymentTax.number === "" && this.dataBuyingPurchasePaymentTax.numberText === "") {
          console.log('OKEEEDDD')
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Number",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'))
        }

        if(this.dataBuyingPurchasePaymentTax.bank === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Bank",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'))
        }

        if(this.dataBuyingPurchasePaymentTax.paymentMethods === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Payment Method",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'))
        }

        if(this.dataBuyingPurchasePaymentTax.date === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Date",
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })

          reject(console.log('required'))
        }

        resolve(true)
      })
    },
    closeModalDownPayment() {
      this.$nextTick(() => {
        this.$bvModal.hide('modalBuyingDownPayment')
      })
    },
    closeModalPurchaseOrder() {
      this.$nextTick(() => {
        this.$bvModal.hide('modalPurchaseOrder')
      })
    },
    closeModalReceiveItem() {
      this.$nextTick(() => {
        this.$bvModal.hide('modalReceiveItem')
      })
    },
    onComplete() {
      this.isLoadingForm = true
      let mergeData = {
        // id_joborder: idJoborder,
        id_default: this.dataDefaultCurrency.id,
        buying: this.dataBuyingCopy,
        itemBuying: this.itemBuyingCopy
      }

      console.log(mergeData, 'THIS MERGE DATA')

      let headers = {
					'Content-Type': 'application/json',

				}

				this.saveBuyingCopy({
						payload: mergeData,
						headers: headers
					})
					.then((res) => {
            this.isLoadingForm = false
						if (res.data.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })

              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })

              this.$nextTick(() => {
                this.$bvModal.hide('modalBuyingDownPayment')
                this.$bvModal.hide('modalTransactionType')
              })

              this.$nextTick(() => this.$refs.vuetable.refresh())
              // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
              // this.getListBuying(idJoborder)
            } else {
              this.$nextTick(() => {
                this.$bvModal.hide('modalBuyingDownPayment')
                this.$bvModal.hide('modalTransactionType')
              })

              this.$nextTick(() => this.$refs.vuetable.refresh())

              Swal.fire({
                title: "Failed!",
                text: res.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            }
					})
					.catch((err) => {
            this.isLoadingForm = false
						Swal.fire({
              title: "Failed!",
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
					})
    },
    onCompleteBuying() {

      if(this.isTaxDateChecked === '0'){
        this.dataSingleBuying.tax_invoice_date = ''
        this.dataSingleBuying.tax_invoice_number = ''
      }
      
      let tempData = JSON.parse(JSON.stringify(this.dataSingleBuying))

      // karna data single buying ga pake data model di sini, jadi number harus di assign dari model baru
      tempData.id_autonumber = this.dataSingleBuyingNumber

      if(this.dataSingleBuying.id_purchase_order !== "" && this.dataSingleBuying.id_purchase_order !== 0 && this.dataSingleBuying.id_purchase_order !== null && this.dataSingleBuying.id_purchase_order !== undefined) {
        tempData.items.forEach((dt, idx) => {
          if(parseInt(dt.isChecked) === 1) {
            if(dt.remarks === undefined) {
              dt.remarks = ''
            }
            // dt.price = dt.actualAmount
            tempData[idx] = dt
          }
        })
      }
      console.log(this.dataSingleBuying, 'THIS DATA SINGLE BUYING');
      console.log(tempData, 'THIS DATA SINGLE BUYING 2');

      this.isLoadingForm = true
      tempData.checkDp = this.checkDp
      console.log(tempData,'tempdatasss');
      let headers = {
        'Content-Type': 'application/json'
      }

      this.updateBuying({
          payload: tempData,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          this.isLoadingForm = false
          if (res.data.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {
              this.$bvModal.hide('modalBuyingEdit')
            })
            this.$nextTick(() => this.$refs.vuetable.refresh())
            // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            // this.getListBuyingItem({
            //   search: this.searchQuery,
            //   idJoborder: idJoborder,
            //   page: this.pageActive,
            //   per_page: this.perPage
            // })
            // .then((res) => {
            //   this.totalData = this.listBuying.total < 20 ? 20 : this.listBuying.total
            // })
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          this.isLoadingForm = false
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    copyVariable(data) {
      return data;
    },
    // editInfoBuying(id) {
    //   try {
    //     this.getListAutonumberPPTax(35)
    //     this.getBuyingDownPayment(id)
    //       .then((res) => {
    //         this.getListDocCode()
    //           .then((res) => {
    //             this.getBuyingPurchasePayment(id)
    //               .then((res) => {
    //                 console.log(this.dataSingleBuyingDownPayment.id_currency, 'THIHDIOWEHD')
    //                 if(this.dataSingleBuyingDownPayment.id_currency !== 0 && this.dataSingleBuyingDownPayment.ppn > 0) {
    //                   this.editTaxAble = true
    //                   this.getBuyingPurchasePaymentTax(id)
    //                     .then((res) => {
    //                       if(this.dataSingleBuyingPurchasePaymentTax.id === undefined) {
    //                         this.editTaxAble = false
    //                         this.getBuyingPurchasePaymentTaxDefault(id)
    //                           .then((res) => {
    //                             this.dataSingleBuyingDownPayment.date = new Date(this.dataSingleBuyingDownPayment.date)
    //                             this.getListTerm(this.dataSingleBuyingDownPayment.id_vendor)
    //                               .then((res) => {
    //                                 this.getListTaxTypeBuying(this.dataSingleBuyingDownPayment.doc_code)
    //                                   .then((res) => {
    //                                     // console.log(this.dataSingleBuyingDownPayment.date.replace(/-/g, '/'))
    //                                   })
    //                                   this.$bvModal.show('modalBuyingDownPaymentEdit')
    //                               })
    //                           })
    //                           .catch((err) => {
    //                             console.log(err)
    //                           })
    //                       } else {
    //                         this.dataSingleBuyingDownPayment.date = new Date(this.dataSingleBuyingDownPayment.date)
    //                         this.getListTerm(this.dataSingleBuyingDownPayment.id_vendor)
    //                           .then((res) => {
    //                             this.getListTaxTypeBuying(this.dataSingleBuyingDownPayment.doc_code)
    //                               .then((res) => {
    //                                 // console.log(this.dataSingleBuyingDownPayment.date.replace(/-/g, '/'))
    //                               })
    //                               this.$bvModal.show('modalBuyingDownPaymentEdit')
    //                           })
    //                       }
    //                     })
    //                     .catch((err) => {
    //                       console.log(err)
    //                     })
    //                 } else {
    //                   this.editTaxAble = false
    //                   this.getBuyingPurchasePaymentTaxDefault(id)
    //                     .then((res) => {
    //                       this.dataSingleBuyingDownPayment.date = new Date(this.dataSingleBuyingDownPayment.date)
    //                       this.getListTerm(this.dataSingleBuyingDownPayment.id_vendor)
    //                         .then((res) => {
    //                           this.getListTaxTypeBuying(this.dataSingleBuyingDownPayment.doc_code)
    //                             .then((res) => {
    //                               // console.log(this.dataSingleBuyingDownPayment.date.replace(/-/g, '/'))
    //                             })
    //                             this.$bvModal.show('modalBuyingDownPaymentEdit')
    //                         })
    //                     })
    //                     .catch((err) => {
    //                       console.log(err)
    //                     })
    //                 }
    //               })
    //               .catch((err) => {
    //                 console.log(err)
    //               })
    //           })
    //           .catch((err) => {
    //             console.log(err)
    //           })
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    submitInfoBuying(bvModalEvent) {
      // this.$bvModal.hide('modalBuyingDownPaymentEdit')
      bvModalEvent.preventDefault()

      // this.$nextTick(() => {
      //   this.$bvModal.hide('modalBuyingDownPaymentEdit')
      // })

      if(this.dataSingleBuyingDownPayment.invoice_no === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Invoice No",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataSingleBuyingDownPayment.date === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Date",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      this.dataSingleBuyingPurchasePayment.currency = this.dataSingleBuyingDownPayment.id_currency
      this.dataSingleBuyingPurchasePayment.commercialRate = this.dataSingleBuyingDownPayment.price_kurs
      this.dataSingleBuyingPurchasePayment.fiscalRate = this.dataSingleBuyingDownPayment.fiscal_kurs
      this.dataSingleBuyingPurchasePayment.amount = this.dataSingleBuyingDownPayment.down_payment
      this.dataSingleBuyingPurchasePayment.vendor = this.dataSingleBuyingDownPayment.id_vendor

      if(!this.editTaxAble) {
        let copyNumber = this.dataSingleBuyingPurchasePaymentTax.number
        this.dataSingleBuyingPurchasePaymentTax.currency = this.dataSingleBuyingDownPayment.id_currency
        this.dataSingleBuyingPurchasePaymentTax.commercialRate = this.dataSingleBuyingDownPayment.price_kurs
        this.dataSingleBuyingPurchasePaymentTax.fiscalRate = this.dataSingleBuyingDownPayment.fiscal_kurs
        // this.dataSingleBuyingPurchasePaymentTax.amount = this.dataSingleBuyingDownPayment.down_payment
        this.dataSingleBuyingPurchasePaymentTax.vendor = this.dataSingleBuyingDownPayment.id_vendor
        this.dataSingleBuyingPurchasePaymentTax.currencyBank = this.dataSingleBuyingPurchasePaymentTax.id_currency_bank
        this.dataSingleBuyingPurchasePaymentTax.number = this.dataSingleBuyingPurchasePaymentTax.id_autonumber
        this.dataSingleBuyingPurchasePaymentTax.numberText = copyNumber
        this.dataSingleBuyingPurchasePaymentTax.paymentMethod = this.dataSingleBuyingPurchasePaymentTax.payment_method
        this.dataSingleBuyingPurchasePaymentTax.discountAccount = this.dataSingleBuyingPurchasePaymentTax.discount_account
        this.dataSingleBuyingPurchasePaymentTax.discountType = this.dataSingleBuyingPurchasePaymentTax.discount_type
      }

      this.dataSingleBuyingPurchasePaymentTax.id_currency = this.dataSingleBuyingDownPayment.id_currency
      this.dataSingleBuyingPurchasePaymentTax.commercial_rate = this.dataSingleBuyingDownPayment.price_kurs
      this.dataSingleBuyingPurchasePaymentTax.fiscal_rate = this.dataSingleBuyingDownPayment.fiscal_kurs
      // this.dataSingleBuyingPurchasePaymentTax.amount = this.dataSingleBuyingDownPayment.down_payment
      this.dataSingleBuyingPurchasePaymentTax.id_vendor = this.dataSingleBuyingDownPayment.id_vendor

      if(parseInt(this.dataSingleBuyingDownPayment.taxable) === 0) {
        this.dataSingleBuyingDownPayment.ppn = 0
        this.dataSingleBuyingDownPayment.inclusiveTax = '0'
      }

      if(parseFloat(this.dataSingleBuyingDownPayment.ppn) > 0) {
        if(this.dataSingleBuyingDownPayment.inclusive_tax === "false" || this.dataSingleBuyingDownPayment.inclusive_tax === "0") {
          let amountPPN = this.dataSingleBuyingDownPayment.down_payment * this.dataSingleBuyingDownPayment.ppn / 100
          if(this.dataSingleBuyingDownPayment.id_currency === 0) {
            // this.dataBuyingPurchasePayment.amount = parseInt(this.dataBuyingDownPayment.downPayment) + amountPPN
            this.dataSingleBuyingPurchasePayment.amount = parseInt(this.dataSingleBuyingDownPayment.down_payment) + amountPPN
          } else {
            this.dataSingleBuyingPurchasePaymentTax.id_currency = 0
            this.dataSingleBuyingPurchasePaymentTax.amount = amountPPN
          }
        }
      }

      let payload = {
        downPayment: this.dataSingleBuyingDownPayment,
        purchasePayment: this.dataSingleBuyingPurchasePayment,
        purchasePaymentTax: this.dataSingleBuyingPurchasePaymentTax
      }

      console.log(payload, 'THIS PAYLOAG')

      let headers = {
        'Content-Type': 'application/json'
      }

      this.updateBuyingDownPayment({
          payload: {
            downPayment: this.dataSingleBuyingDownPayment,
            purchasePayment: this.dataSingleBuyingPurchasePayment,
            purchasePaymentTax: this.dataSingleBuyingPurchasePaymentTax
          },
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {
              this.$bvModal.hide('modalBuyingDownPaymentEdit')
            })

            // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            // this.getListBuyingDownPayment(idJoborder)
            this.$nextTick(() => this.$refs.vuetable.refresh())
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    deleteBuyingData(id) {
      let Permission = JSON.parse(localStorage.getItem('userPermissions'));

      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            'Content-Type': 'application/json'
          }

          this.deleteBuying({
              payload: id,
              headers: headers
            })
            .then((res) => {
              if (res.data.status) {
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })

                Toast.fire({
                  icon: 'success',
                  title: 'successfully'
                })

                this.$nextTick(() => this.$refs.vuetable.refresh())
              } else {
                if (Permission.includes('VENDOR_INVOICE_HARD_DELETE')) {
                  if (res.data.message === 'Delete data aol failed - Faktur Pembelian tidak ditemukan atau sudah dihapus') {
                    Swal.fire({
                      title: 'Are you sure hard delete Data?',
                      text: 'You will not be able to recover this data!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: 'rgb(221, 107, 85)',
                      cancelButtonColor: '#C1C1C1',
                      reverseButtons: true,
                      confirmButtonText: 'Yes, delete it!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.hardDeleteBuying({
                          payload: id,
                          headers: headers
                        })
                            .then((res) => {
                              Swal.fire({
                                title: "Success!",
                                text: "Hard Delete Data",
                                icon: 'success',
                                confirmButtonColor: '#51C1AD',
                                confirmButtonText: 'Ok'
                              }).then((res) => {
                                this.$nextTick(() => {
                                  this.$nextTick(() => this.$refs.vuetable.refresh())
                                  // location.reload();
                                })
                              })
                            })
                      }
                    })
                  } else {
                    Swal.fire({
                      // title: "Failed!",
                      text: res.data.message,
                      icon: 'warning',
                      confirmButtonColor: 'rgb(221, 107, 85)',
                      confirmButtonText: 'Ok'
                    })
                  }

                } else {
                  Swal.fire({
                    // title: "Failed!",
                    text: res.data.message,
                    icon: 'warning',
                    confirmButtonColor: 'rgb(221, 107, 85)',
                    confirmButtonText: 'Ok'
                  })
                }
              }
            })
            .catch((err) => {
              console.log(err.response.data)
              Swal.fire({
                title: "Failed!",
                text: err.response.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            })
        }
      })
    },
    submitToAol(id) {
      let headers = {
        'Content-Type': 'application/json'
      }

      this.submitAOL({
          payload: id,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data === 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            // this.getListBuyingDownPayment(idJoborder)
            this.$nextTick(() => this.$refs.vuetable.refresh())
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    submitToAolPP(id, idDP) {
      console.log(id, 'THIS ID PP')
      console.log(idDP, 'THIS ID DP')
      let headers = {
        'Content-Type': 'application/json'
      }

      this.submitAOLPP({
          payload: {
            id: id,
            idDP: idDP
          },
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data === 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            // this.getListBuyingDownPayment(idJoborder)
            this.$nextTick(() => this.$refs.vuetable.refresh())
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    submitToAolPPTax(id, idDP) {
      console.log(id, 'THIS ID PP')
      console.log(idDP, 'THIS ID DP')
      let headers = {
        'Content-Type': 'application/json'
      }

      this.submitAOLPPTax({
          payload: {
            id: id,
            idDP: idDP
          },
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data === 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            // this.getListBuyingDownPayment(idJoborder)
            this.$nextTick(() => this.$refs.vuetable.refresh())
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    openModalBuying() {
      // this.getBalanceDP(id)
      //   .then((res) => {
          this.statusModal = 'createBuying'
          // console.log(this.dataBalanceDP, 'THIS DPPPP')
          this.listAutonumber = []
          this.listItem = []
          this.getListAutonumber(1)
          this.getListDocCode()
          this.getListJobfile()

          // Buat reset item nyaa --> hrusnyaa sih ga kyk gini
          this.getItemByPpn({
            ppn: this.dataBuying.ppn,
            itemCategory: 5345345
          })

          this.dataBuying.term = ''
          this.dataBuying.number = ''
          this.dataBuying.numberText = ''
          this.dataBuying.invoice = ''
          this.dataBuying.vendor = ''
          this.dataBuying.taxDate = ''
          this.dataBuying.taxNo = ''
          this.dataBuying.date = ''
          // this.dataBuying.currency = this.dataBalanceDP.data.id_currency
          this.dataBuying.currency = this.dataDefaultCurrency.id
          this.dataBuying.ppn = ''
          this.dataBuying.pph = ''
          this.dataBuying.docCode = ''
          this.dataBuying.taxType = ''
          this.dataBuying.commercialRate = ''
          this.dataBuying.fiscalRate = ''
          this.dataBuying.inclusiveTax = false
          this.dataBuying.idDownPayment = 0
          // this.dataBuying.downPayment = downPayment - this.dataBalanceDP.claimed
          this.dataBuying.downPayment = 0
          this.dataBuying.dateDownPayment = ''

          this.showRate = false;
          this.showTaxDetail = false
          this.showTaxInput = false

          // if(this.dataBuying.currency !== this.dataBalanceDP.data.id_default) {
          //   this.showRate = true;
          // } else {
          //   this.showRate = false;
          // }

          this.itemBuying = [
            {
              item: '',
              price: '',
              qty: '',
              remarks: '',
              truck: (this.listTruck[0] !== undefined && this.listTruck[0] !== null && this.listTruck[0] !== '') ? this.listTruck[0].id : '',
              isTruck: 0,
              idJobfile: '',
            }
          ]

          // let getVendor = this.listVendor.find(x => x.id === idVendor)
          this.getListTerm()
            .then((res) => {
              // if(getVendor !== undefined) {
              //   this.dataBuying.term = parseInt(getVendor.id_term)
                this.$bvModal.show('modalBuying')
              // }
            })
        // })
    },
    openModalBuyingCopy(id, idVendor, downPayment, date) {
      this.getBalanceDP(id)
        .then((res) => {
          console.log(downPayment, 'THIS DPPPP')
          console.log(this.dataBalanceDP, 'THIS DPPPP')
          this.listAutonumber = []
          this.listItem = []
          this.getListAutonumber(1)
          this.getListDocCode()
          this.getListJobfile()

          this.getItemByPpn({
            ppn: this.dataBuying.ppn,
            itemCategory: 5345345
          })

          this.dataBuying.term = ''
          this.dataBuying.number = ''
          this.dataBuying.numberText = ''
          this.dataBuying.invoice = ''
          this.dataBuying.taxDate = ''
          this.dataBuying.taxNo = ''
          this.dataBuying.vendor = idVendor
          this.dataBuying.date = ''
          this.dataBuying.currency = this.dataBalanceDP.data.id_currency
          this.dataBuying.ppn = ''
          this.dataBuying.docCode = ''
          this.dataBuying.taxType = ''
          this.dataBuying.commercialRate = ''
          this.dataBuying.fiscalRate = ''
          this.dataBuying.inclusiveTax = false
          this.dataBuying.idDownPayment = id
          // this.dataBuying.downPayment = downPayment - this.dataBalanceDP.claimed
          this.dataBuying.downPayment = this.dataBalanceDP.data.down_payment - this.dataBalanceDP.claimed
          this.dataBuying.dateDownPayment = this.dateFormating(date)
          this.showTaxDetail = false
          this.showTaxDInput = false

          if(this.dataBuying.currency !== this.dataBalanceDP.data.id_default) {
            this.showRate = true;
          } else {
            this.showRate = false;
          }

          this.itemBuying = [
            {
              item: '',
              price: '',
              qty: '',
              remarks: '',
              truck: '',
              isTruck: 0,
              idJobfile: '',
            }
          ]

          let getVendor = this.listVendor.find(x => x.id === idVendor)
          this.getListTerm()
            .then((res) => {
              if(getVendor !== undefined) {
                this.dataBuying.term = parseInt(getVendor.id_term)
                this.$bvModal.show('modalBuying')
              }
            })
        })
    },
    addDetail(k) {
      console.log(k,'kk');
      if(k === 'createNew'){
        this.itemBuying.push({
          item: '',
          price: '',
          qty: '',
          remarks: '',
          isTruck: this.itemBuying[0].isTruck,
				  truck: this.itemBuying[0].truck,
          // truck: '',
          // isTruck: 0,
          idJobfile: '',
        });
      } else if(k === 'createNewFromDp'){
        this.itemBuyingCopy.push({
          item: '',
          price: '',
          qty: '',
          remarks: '',
          isTruck: this.itemBuyingCopy[0].isTruck,
				  truck: this.itemBuyingCopy[0].truck,
          // truck: '',
          // isTruck: 0,
          idJobfile: '',
        });
      } else if(k === 'createNewFromPO') {
        this.itemBuyingCopy.push({
          item: '',
          price: 0,
          qty: 0,
          remarks: '',
          truck: (this.listTruck[0] !== undefined && this.listTruck[0] !== null && this.listTruck[0] !== '') ? this.listTruck[0].id : '',
          isTruck: 0,
          idJobfile: '',
          isChecked: 0,
          stockQty: 0,
          actualAmount: 0,
          subTotal: 0,
          id_detail_po: 0,
          copied_to: ''
        })
      } else if(k === 'createNewFromRI') {
        this.itemBuyingCopy.push({
          item: '',
          price: 0,
          qty: 0,
          remarks: '',
          truck: (this.listTruck[0] !== undefined && this.listTruck[0] !== null && this.listTruck[0] !== '') ? this.listTruck[0].id : '',
          isTruck: 0,
          idJobfile: '',
          isChecked: 0,
          stockQty: 0,
          actualAmount: 0,
          subTotal: 0,
          id_detail_ri: 0,
          copied_to: ''
        })
      }
    },
    addDetailBuyingEdit(k) {
      this.dataSingleBuying.items.push({
        "id_job_order_buying": 0,
        "id_jobfile_cost": 0,
        "shItem": 0,
        "id_mapping": 0,
        "id_jobfile": '',
        "qty": 0,
        "price": 0,
        "id_item": '',
        "item_name": "",
        "truck_name": "",
        "remarks": "",
        "id_department": (this.listTruck[0] !== undefined && this.listTruck[0] !== null && this.listTruck[0] !== '') ? this.listTruck[0].id : '',
        "pph": 0,
        "id_item_aol": 0,
        "subTotal": 0,
        "jobfile_no": "",
        "id_item_reff" : 0,
        "id_detail_po": 0,
        "id_detail_ri": 0,
        "copied_to": '',
        "isTruck" : 0
      });
    },
    removeDetail(d, status) {
      if(status === 'createNew'){
        this.itemBuying.splice(d, 1);
      } else if(status === 'createNewFromDp' || status === 'createNewFromPO' || status === 'createNewFromRI'){
        this.itemBuyingCopy.splice(d, 1);
      }
    },
    removeDetailEdit(d) {
      this.dataSingleBuying.items.splice(d, 1);
    },
    submitBuyingCopy(bvModalEvent) {
      bvModalEvent.preventDefault()

      if(this.dataBuying.number === "" && this.dataBuying.numberText === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Number",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.vendor === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Vendor",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.invoice === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Invoice",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.date === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Date",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      // this.dataBuying.currency = this.dataDefaultCurrency.id

      if(this.dataBuying.currency !== this.dataDefaultCurrency.id) {
        if(this.dataBuying.commercialRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Commercial Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
        if(this.dataBuying.fiscalRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Fiscal Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
      }

      let itemIsValid = true;

      this.itemBuying.forEach((dt, idx) => {
        if(dt.item === "" || dt.price === "" || dt.qty === "" || dt.idJobfile === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field jobfile, item, price, and qty in item row " + (idx+1),
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          itemIsValid = false;
        }
      })

      if(!itemIsValid) {
        return false;
      }

      // let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let mergeData = {
        // id_joborder: idJoborder,
        id_default: this.dataDefaultCurrency.id,
        buying: this.dataBuying,
        itemBuying: this.itemBuying
      }

      console.log(mergeData, 'THIS MERGE DATA')

      let headers = {
					'Content-Type': 'application/json',

				}

				this.saveBuyingCopy({
						payload: mergeData,
						headers: headers
					})
					.then((res) => {
						if (res.data.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })

              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })

              this.$nextTick(() => {
                this.$bvModal.hide('modalBuying')
              })

              this.$nextTick(() => this.$refs.vuetable.refresh())
              // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
              // this.getListBuying(idJoborder)
            } else {
              Swal.fire({
                title: "Failed!",
                text: res.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            }
					})
					.catch((err) => {
						Swal.fire({
              title: "Failed!",
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
					})
    },
    submitBuyingManual(bvModalEvent) {
      bvModalEvent.preventDefault()

      if(this.dataBuying.number === "" && this.dataBuying.numberText === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Number",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.vendor === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Vendor",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.invoice === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Invoice",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.date === "") {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field Date",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.dataBuying.ppn === "" || this.dataBuying.ppn === null || this.dataBuying.ppn === undefined) {
        Swal.fire({
          title: "Failed!",
          text: "Please insert field PPN",
          icon: 'warning',
          // showCancelButton: true,
          confirmButtonColor: 'rgb(221, 107, 85)',
          // cancelButtonColor: '#C1C1C1',
          // reverseButtons: true,
          confirmButtonText: 'Ok'
        })

        return false;
      }

      if(this.companyIsUsingPPH === 1) {
        if(this.dataBuying.pph === "" || this.dataBuying.pph === null || this.dataBuying.pph === undefined) {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field PPH",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false;
        }
      }

      // this.dataBuying.currency = this.dataDefaultCurrency.id

      if(this.dataBuying.currency !== this.dataDefaultCurrency.id) {
        if(this.dataBuying.commercialRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Commercial Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
        if(this.dataBuying.fiscalRate === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field Fiscal Rate",
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          return false
        }
      }

      let itemIsValid = true;

      this.itemBuying.forEach((dt, idx) => {
        if(dt.item === "" || dt.price === "" || dt.qty === "" || dt.idJobfile === "") {
          Swal.fire({
            title: "Failed!",
            text: "Please insert field jobfile, item, price, and qty in item row " + (idx+1),
            icon: 'warning',
            // showCancelButton: true,
            confirmButtonColor: 'rgb(221, 107, 85)',
            // cancelButtonColor: '#C1C1C1',
            // reverseButtons: true,
            confirmButtonText: 'Ok'
          })

          itemIsValid = false;
        }
      })

      if(!itemIsValid) {
        return false;
      }

      this.isLoadingForm = true

      // let idJoborder = window.location.href.split("/").splice(0, 6)[5];

      let mergeData = {
        // id_joborder: idJoborder,
        id_default: this.dataDefaultCurrency.id,
        buying: this.dataBuying,
        itemBuying: this.itemBuying
      }

      console.log(mergeData, 'THIS MERGE DATA')

      let headers = {
					'Content-Type': 'application/json',

				}

				this.saveBuyingManual({
						payload: mergeData,
						headers: headers
					})
					.then((res) => {
            this.isLoadingForm = false
						if (res.data.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })

              Toast.fire({
                icon: 'success',
                title: 'successfully'
              })

              this.$nextTick(() => {
                this.$bvModal.hide('modalBuying')
                this.$bvModal.hide('modalTransactionType')
              })

              this.$nextTick(() => this.$refs.vuetable.refresh())
              // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
              // this.getListBuying(idJoborder)
            } else {
              this.$nextTick(() => {
                this.$bvModal.hide('modalBuying')
                this.$bvModal.hide('modalTransactionType')
              })

              this.$nextTick(() => this.$refs.vuetable.refresh())

              Swal.fire({
                title: "Failed!",
                text: res.data.message,
                icon: 'warning',
                confirmButtonColor: 'rgb(221, 107, 85)',
                confirmButtonText: 'Ok'
              })
            }
					})
					.catch((err) => {
            this.isLoadingForm = false
						Swal.fire({
              title: "Failed!",
              text: err.response.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
					})
    },
    onAction(action, rowData, rowIndex) {
      console.log(action, 'THIS ACTION')
      console.log(rowData, 'THIS ACTION')
      console.log(rowIndex, 'THIS ACTION')
      if (action === 'DetailPI') {
        this.$router.push({
          name: 'MultiPurchaseInvoice Detail PI',
          params: {
            id: rowData.id_jobfile_cost,
          },
        })
      } else if(action === 'DetailPIDP') {
        this.$router.push({
          name: 'MultiBuyingDownPayment Detail PI',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'detail-item') {
        this.$router.push({
          name: 'Quotation Detail',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'view-item') {
        this.$router.push({
          name: 'Quotation View',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'delete-item') {
        this.showConfirmDelete(rowData.id)
      } else if (action === 'view-pdf') {
        let config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        }
        axios({
          url: this.$store.state.rootApi + '/quotation' + '/pdf/' + rowData.id,
          config,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fURL = document.createElement('a')

          fURL.href = fileURL
          fURL.setAttribute('download', 'invoice ' + rowData.id + '.pdf')
          document.body.appendChild(fURL)

          fURL.click()
        })
      }
    },
    submitToAolPI(id) {
      let headers = {
        'Content-Type': 'application/json'
      }

      this.submitAOLPI({
          payload: id,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data.status === true) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            // let idJoborder = window.location.href.split("/").splice(0, 6)[5];
            // this.getListBuying({
            //   search: this.searchQuery,
            //   idJoborder: idJoborder,
            //   page: this.pageActive,
            //   per_page: this.perPage
            // })
            // .then((res) => {
            //   this.totalData = this.listBuying.total < 20 ? 20 : this.listBuying.total
            // })
            this.$nextTick(() => this.$refs.vuetable.refresh())
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    getSettlementAdvance(rowData) {
        let config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        }
        axios({
          url: this.$store.state.rootApi + '/multiPurchaseInvoice' + '/pdf/' + rowData.id_jobfile_cost + '/1',
          config,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          console.log(response, 'this response')
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fURL = document.createElement('a')

          fURL.href = fileURL
          fURL.setAttribute('download', 'Settlement ' + rowData.pi_number + '.pdf')
          document.body.appendChild(fURL)

          fURL.click()
        })
    },
    getVendorExpense(rowData) {
        let config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        }
        axios({
          url: this.$store.state.rootApi + '/multiPurchaseInvoice' + '/pdf/' + rowData.id_jobfile_cost + '/2',
          config,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fURL = document.createElement('a')

          fURL.href = fileURL
          fURL.setAttribute('download', 'Vendor Expense ' + rowData.pi_number + '.pdf')
          document.body.appendChild(fURL)

          fURL.click()
        })
    },
    selectTrx(id, idVendor, downPayment, date, ppn) {
      if(this.trxSelected === id) {
        this.trxSelected = ''
        this.trxVendor = ''
        this.trxDownPayment = ''
        this.trxDate = ''
        this.trxPPN = ''
      } else {
        if(this.createFrom === "PURCHASE_ORDER") {
          if(this.trxSelected === "") {
            this.trxSelected = []
            this.trxSelected.push(id)
          } else {
            let chekData = this.trxSelected.find(x => x === id);
            if(chekData !== undefined) {
              this.trxSelected.splice(this.trxSelected.findIndex(x => x === id), 1)
            } else {
              this.trxSelected.push(id)
            }
          }
        } else if (this.createFrom === "RECEIVE_ITEM") {
          if(this.trxSelected === "") {
            this.trxSelected = []
            this.trxSelected.push(id)
          } else {
            let chekData = this.trxSelected.find(x => x === id);
            if(chekData !== undefined) {
              this.trxSelected.splice(this.trxSelected.findIndex(x => x === id), 1)
            } else {
              this.trxSelected.push(id)
            }
          }
        } else {
          this.trxSelected = id
          this.trxVendor = idVendor
          this.trxDownPayment = downPayment
          this.trxDate = date
          this.trxPPN = ppn
        }
      }

      console.log(this.trxSelected)
      console.log(this.trxVendor)
      console.log(this.trxDownPayment)
      console.log(this.trxDate)
    },
    checkedTrx(props) {
      // chkReceiveItem
      // if(document.getElementById(`receiveItem_${props.rowData.id}`) !== null) {
      //   document.getElementById(`receiveItem_${props.rowData.id}`).checked = false

      //   console.log('NOT OKEE')
      //   console.log(`receiveItem_${props.rowData.id}`)
      // }
      setTimeout(() => {
        if(document.getElementById(`receiveItem_${props.rowData.id}`) !== null) {
          let getTrx = this.trxSelected.find(x => x === props.rowData.id)

          if(getTrx !== undefined) {
            document.getElementById(`receiveItem_${props.rowData.id}`).checked = true
          } else {
            document.getElementById(`receiveItem_${props.rowData.id}`).checked = false
          }

          console.log('NOT OKEE')
          console.log(`receiveItem_${props.rowData.id}`)
        }
      }, 100)
      console.log(props, 'THIS PROPS')
      // console.log($event, 'THIS EVENTT')
      // return false;
    },
    checkedTrxPO(props) {
      setTimeout(() => {
        if(document.getElementById(`purchaseOrder_${props.rowData.id}`) !== null) {
          let getTrx = this.trxSelected.find(x => x === props.rowData.id)

          if(getTrx !== undefined) {
            document.getElementById(`purchaseOrder_${props.rowData.id}`).checked = true
          } else {
            document.getElementById(`purchaseOrder_${props.rowData.id}`).checked = false
          }
        }
      }, 100)
    },
    numberConditionColumn(){
      // ini dijadiin ke model baru karna ga ada v-model nya
      this.dataSingleBuyingNumber = this.dataSingleBuying.id_autonumber
      this.dataSingleBuying.numberText = this.dataSingleBuying.pi_number

        if(this.dataSingleBuying.pi_number !== "" && this.dataSingleBuying.pi_number !== null){
          this.isManualNumberBuyingEdit = true
        } else {
          this.isManualNumberBuyingEdit = false
        }
    },
    editInfoBuying(id) {
      this.statusModal = 'edit'
      this.totalOfSubTotal = 0;
      this.listAutonumber = []
      this.listItem = []
      this.getListAutonumber(1)
      this.getListDocCode()
      this.getListJobfile()

      try {
        this.getBuying(id)
          .then((res) => {
            // this.dataSingleBuying.items.forEach((dt, idx) => {
            //   if(dt.id_department !== 0 && dt.id_department !== "" && dt.id_department !== null) {
            //     dt.isTruck = "1"
            //   } else {
            //     dt.isTruck = "0"
            //   }
            // })
            let headers = {
              'Content-Type': 'application/json'
            }
            this.getListDpByVendor({
              payload: {
                vendor: res.id_vendor,
                currency: res.currency_cost
              },
              headers: headers
            })

            if(res.id_down_payment !== 0){
              this.checkDp = true
            } else {
              this.checkDp = false
            }

            this.numberConditionColumn()
            if(parseInt(this.dataSingleBuying.ppn_cost) > 0 && this.dataSingleBuying.doc_code !== "DIGUNGGUNG") {
              this.showTaxType = true;
              this.showTaxDetail = true;
            } else {
              this.showTaxType = false;
              this.showTaxDetail = false;
              this.showTaxInput = false;
            }

            if(this.companyIsUsingPPH === 1) {
              this.getItemByTax({
                ppn: this.dataSingleBuying.ppn_cost,
                pph: this.dataSingleBuying.pph_cost,
                itemCategory: 1
              })
                .then(() => {
                  this.dataSingleBuying.cost_date = new Date(this.dataSingleBuying.cost_date)
                  this.dataSingleBuying.term = parseInt(this.dataSingleBuying.term)
                  this.dataSingleBuying.tax_invoice_date = new Date(this.dataSingleBuying.tax_invoice_date)
                  this.dataSingleBuying.tax_invoice_number = this.dataSingleBuying.tax_invoice_number

                  console.log(this.dataSingleBuying.tax_invoice_date,'taxinvdate')
                  console.log(this.dataSingleBuying.tax_invoice_number,'taxinvdate')
                  
                  if(this.dataSingleBuying.tax_invoice_date == null || this.dataSingleBuying.tax_invoice_number == null){
                    this.isTaxDateChecked = 0;
                  } else {
                    this.isTaxDateChecked = 1;
                  }
                  if(this.dataSingleBuying.pi_number === ''){
                    this.isManualNumberBuyingEdit = false
                  }

                  this.getListTerm(this.dataSingleBuying.id_vendor)
                    .then((res) => {
                      this.getListTaxTypeBuying(this.dataSingleBuying.doc_code)
                        .then((res) => {
                          if(this.dataSingleBuying.id_purchase_order !== "" && this.dataSingleBuying.id_purchase_order !== 0 && this.dataSingleBuying.id_purchase_order !== null && this.dataSingleBuying.id_purchase_order !== undefined) {

                            let lsPO = []

                            this.dataSingleBuying.items.forEach((mp) => {
                              let checkPO = lsPO.find(x => x === mp.id_purchase_order);

                              if(checkPO === undefined) {
                                lsPO.push(mp.id_purchase_order)
                              }
                            })

                            console.log(lsPO, 'THIS LS PO')

                            lsPO.forEach((d, index) => {
                              if(d !== 0 && d !== '0' && d !== "") {
                                this.getlistBalancePO(d).then((res) => {
                                  this.itemBuying = []
                                    res.purchase_order_item.forEach((dr) => {

                                      let searchData = this.dataSingleBuying.items.find(x => x.id_item_reff === dr.id);

                                      if(searchData !== undefined) {
                                        searchData.isChecked = 1
                                        searchData.stockQty = dr.stock_qty
                                        searchData.actualAmount = dr.amount
                                        // searchData.price = searchData.qty * dr.amount
                                        console.log(searchData,'searchadat');
                                        if(this.dataSingleBuying.price_kurs !== '0'){
                                          searchData.subTotal = searchData.price * searchData.qty * this.dataSingleBuying.price_kurs
                                        } else {
                                          searchData.subTotal = searchData.price * searchData.qty
                                        }
                                        searchData.id_item_reff = dr.id
                                        searchData.qtyOld = searchData.qty
                                        searchData.copied_to = dr.copied_to
                                        searchData.id_detail_po = dr.id
                                      } else {
                                        this.dataSingleBuying.items.push({
                                          id_item: dr.item_id,
                                          price: 0,
                                          qty: 0,
                                          remarks: dr.remarks,
                                          id_department: '',
                                          isTruck: 0,
                                          id_jobfile: dr.jobfile_id,
                                          isChecked: 0,
                                          stockQty: dr.stock_qty,
                                          actualAmount: dr.amount,
                                          subTotal: 0,
                                          id_detail_po: dr.id,
                                          qtyOld: 0,
                                          copied_to: dr.copied_to,
                                        })
                                      }
                                    })

                                })
                              } else {
                                this.dataSingleBuying.items[index].isChecked = 1
                                this.dataSingleBuying.items[index].id_detail_po = 0
                              }
                              console.log(this.dataSingleBuying.items, 'THIS OKE')

                              this.getTotalSub()
                              this.$bvModal.show('modalBuyingEdit')
                            })

                            console.log(this.dataSingleBuying.items, 'THIS OKE DEHH')

                          } else if(this.dataSingleBuying.id_receiveable_item !== "" && this.dataSingleBuying.id_receiveable_item !== 0 && this.dataSingleBuying.id_receiveable_item !== null && this.dataSingleBuying.id_receiveable_item !== undefined) {

                            let lsRI = []

                            this.dataSingleBuying.items.forEach((mp) => {
                              let checkRI = lsRI.find(x => x === mp.id_receiveable_item);

                              if(checkRI === undefined) {
                                lsRI.push(mp.id_receiveable_item)
                              }
                            })

                            lsRI.forEach((d, index) => {
                              if(d !== 0 && d !== '0' && d !== "") {
                                this.getlistBalanceRI(d).then((res) => {
                                  this.itemBuying = []

                                  res.receiveable_item_item.forEach((dr) => {

                                    let searchData = this.dataSingleBuying.items.find(x => x.id_item_reff === dr.id);

                                    if(searchData !== undefined) {
                                      searchData.isChecked = 1
                                      searchData.stockQty = dr.stock_qty
                                      searchData.actualAmount = dr.amount_po
                                      // searchData.price = searchData.qty * dr.amount
                                      searchData.subTotal = searchData.price * searchData.qty
                                      searchData.id_item_reff = dr.id
                                      searchData.qtyOld = searchData.qty
                                      searchData.copied_to = dr.copied_to
                                      searchData.id_detail_ri = dr.id
                                    } else {
                                      this.dataSingleBuying.items.push({
                                          id_item: dr.item_id,
                                          price: 0,
                                          qty: 0,
                                          remarks: dr.remarks,
                                          id_department: '',
                                          isTruck: 0,
                                          id_jobfile: dr.jobfile_id,
                                          isChecked: 0,
                                          stockQty: dr.stock_qty,
                                          actualAmount: dr.amount_po,
                                          subTotal: 0,
                                          id_detail_ri: dr.id,
                                          qtyOld: 0,
                                          copied_to: dr.copied_to,
                                        })
                                    }
                                  })
                                })
                              } else {
                                this.dataSingleBuying.items[index].isChecked = 1
                                this.dataSingleBuying.items[index].id_detail_ri = 0
                              }
                              console.log(this.dataSingleBuying.items, 'THIS OKE WAWE')

                              this.$bvModal.show('modalBuyingEdit')
                            })

                            console.log(this.dataSingleBuying.items, 'THIS OKE DEHH')

                          } else {
                            this.$bvModal.show('modalBuyingEdit')
                          }
                          // console.log(this.dataSingleBuying.date.replace(/-/g, '/'))
                        })
                    })
                })
            } else {
              this.getItemByPpn({
                ppn: this.dataSingleBuying.ppn_cost,
                itemCategory: 1
              })
                .then(() => {
                  this.dataSingleBuying.cost_date = new Date(this.dataSingleBuying.cost_date)
                  // if(this.dataSingleBuying.tax_invoice_date != null)
                  if(this.dataSingleBuying.tax_invoice_date != null){
                    this.dataSingleBuying.tax_invoice_date = new Date(this.dataSingleBuying.tax_invoice_date)
                  } else {
                    this.dataSingleBuying.tax_invoice_date = ''
                  }

                  this.dataSingleBuying.tax_invoice_number = this.dataSingleBuying.tax_invoice_number
                  this.dataSingleBuying.term = parseInt(this.dataSingleBuying.term)

                  if(this.dataSingleBuying.tax_invoice_date == null || this.dataSingleBuying.tax_invoice_date === '' || this.dataSingleBuying.tax_invoice_number == null || this.dataSingleBuying.tax_invoice_number === ''){
                    this.isTaxDateChecked = 0;
                    this.showTaxInput = false;
                  } else {
                    this.isTaxDateChecked = 1;
                    this.showTaxInput = true;
                  }
                  if(this.dataSingleBuying.pi_number === ''){
                    this.isManualNumberBuyingEdit = false
                  }

                  this.getListTerm(this.dataSingleBuying.id_vendor)
                    .then((res) => {
                      this.getListTaxTypeBuying(this.dataSingleBuying.doc_code)
                        .then((res) => {
                          if(this.dataSingleBuying.id_purchase_order !== "" && this.dataSingleBuying.id_purchase_order !== 0 && this.dataSingleBuying.id_purchase_order !== null && this.dataSingleBuying.id_purchase_order !== undefined) {

                            let lsPO = []

                            this.dataSingleBuying.items.forEach((mp) => {
                              let checkPO = lsPO.find(x => x === mp.id_purchase_order);

                              if(checkPO === undefined) {
                                lsPO.push(mp.id_purchase_order)
                              }
                            })

                            console.log(lsPO, 'THIS LS PO')

                            lsPO.forEach((d, index) => {
                              if(d !== 0 && d !== '0' && d !== "") {
                                this.getlistBalancePO(d).then((res) => {
                                  this.itemBuying = []

                                  res.purchase_order_item.forEach((dr) => {

                                    let searchData = this.dataSingleBuying.items.find(x => x.id_item_reff === dr.id);

                                    if(searchData !== undefined) {
                                      searchData.isChecked = 1
                                      searchData.stockQty = dr.stock_qty
                                      searchData.actualAmount = dr.amount
                                      // searchData.price = searchData.qty * dr.amount
                                      searchData.subTotal = searchData.price * searchData.qty
                                      searchData.id_item_reff = dr.id
                                      searchData.qtyOld = searchData.qty
                                      searchData.copied_to = dr.copied_to
                                      searchData.id_detail_po = dr.id
                                    } else {
                                      this.dataSingleBuying.items.push({
                                          id_item: dr.item_id,
                                          price: 0,
                                          qty: 0,
                                          remarks: dr.remarks,
                                          id_department: '',
                                          isTruck: 0,
                                          id_jobfile: dr.jobfile_id,
                                          isChecked: 0,
                                          stockQty: dr.stock_qty,
                                          actualAmount: dr.amount,
                                          subTotal: 0,
                                          id_detail_po: dr.id,
                                          qtyOld: 0,
                                          copied_to: dr.copied_to,
                                        })
                                    }
                                  })

                                })
                              } else {
                                this.dataSingleBuying.items[index].isChecked = 1
                                this.dataSingleBuying.items[index].id_detail_po = 0
                              }
                              console.log(this.dataSingleBuying.items, 'THIS OKE')


                              this.$bvModal.show('modalBuyingEdit')
                            })

                            console.log(this.dataSingleBuying.items, 'THIS OKE DEHH')

                          } else if(this.dataSingleBuying.id_receiveable_item !== "" && this.dataSingleBuying.id_receiveable_item !== 0 && this.dataSingleBuying.id_receiveable_item !== null && this.dataSingleBuying.id_receiveable_item !== undefined) {

                            let lsRI = []

                            this.dataSingleBuying.items.forEach((mp) => {
                              let checkRI = lsRI.find(x => x === mp.id_receiveable_item);

                              if(checkRI === undefined) {
                                lsRI.push(mp.id_receiveable_item)
                              }
                            })

                            lsRI.forEach((d, index) => {
                              if(d !== 0 && d !== '0' && d !== "") {
                                this.getlistBalanceRI(d).then((res) => {
                                  this.itemBuying = []

                                  res.receiveable_item_item.forEach((dr) => {

                                    let searchData = this.dataSingleBuying.items.find(x => x.id_item_reff === dr.id);

                                    if(searchData !== undefined) {
                                      searchData.isChecked = 1
                                      searchData.stockQty = dr.stock_qty
                                      searchData.actualAmount = dr.amount_po
                                      // searchData.price = searchData.qty * dr.amount
                                      searchData.subTotal = searchData.price * searchData.qty
                                      searchData.id_item_reff = dr.id
                                      searchData.qtyOld = searchData.qty
                                      searchData.copied_to = dr.copied_to
                                      searchData.id_detail_ri = dr.id
                                    } else {
                                      this.dataSingleBuying.items.push({
                                          id_item: dr.item_id,
                                          price: 0,
                                          qty: 0,
                                          remarks: dr.remarks,
                                          id_department: '',
                                          isTruck: 0,
                                          id_jobfile: dr.jobfile_id,
                                          isChecked: 0,
                                          stockQty: dr.stock_qty,
                                          actualAmount: dr.amount_po,
                                          subTotal: 0,
                                          id_detail_ri: dr.id,
                                          qtyOld: 0,
                                          copied_to: dr.copied_to,
                                        })
                                    }
                                  })

                                })
                              } else {
                                this.dataSingleBuying.items[index].isChecked = 1
                                this.dataSingleBuying.items[index].id_detail_ri = 0
                              }
                              console.log(this.dataSingleBuying.items, 'THIS OKE')

                              this.$bvModal.show('modalBuyingEdit')
                            })

                            console.log(this.dataSingleBuying.items, 'THIS OKE DEHH')

                          } else {
                            this.$bvModal.show('modalBuyingEdit')
                          }
                          // console.log(this.dataSingleBuying.date.replace(/-/g, '/'))
                        })
                    })
                })
            }


          })
          .catch((err) => {
            console.log(err)
          })
      } catch (error) {
        console.log(error)
      }
    },
    isNumber: function(evt) {
      // evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    selectItem(idx) {
			this.itemBuyingCopy[idx].qty = 0
			this.itemBuyingCopy[idx].price = 0

      if(parseInt(this.itemBuyingCopy[idx].isChecked) === 1) {
        if(this.itemBuyingCopy[idx].copied_to !== 'PI' && this.itemBuyingCopy[idx].copied_to !== '' && this.itemBuyingCopy[idx].copied_to !== null){
          this.itemBuyingCopy[idx].isChecked = '0'
          Swal.fire({
            title: "Failed!",
            text: 'Item already used for ' + this.itemBuyingCopy[idx].copied_to,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
          document.getElementById(`selectedPO${idx}`).checked = false
        }
      }


			console.log(this.itemBuyingCopy[idx])
		},
    selectItemEdit(idx) {
      console.log(this.dataSingleBuying.items[idx].isChecked)
			// this.dataSingleBuying.items[idx].qty = 0
			// this.dataSingleBuying.items[idx].price = 0
			// this.dataSingleBuying.items[idx].stockQty = this.dataSingleBuying.items[idx].stockQty + this.dataSingleBuying.items[idx].qtyOld
      if(parseInt(this.dataSingleBuying.items[idx].isChecked) === 1) {
        if(this.dataSingleBuying.items[idx].copied_to !== 'PI' && this.dataSingleBuying.items[idx].copied_to !== '' && this.dataSingleBuying.items[idx].copied_to !== null){
          this.dataSingleBuying.items[idx].isChecked = '0'
          Swal.fire({
            title: "Failed!",
            text: 'Item already used for ' + this.dataSingleBuying.items[idx].copied_to,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
          document.getElementById(`selectedPOEdit${idx}`).checked = false
        }
      }
		},
    validateBuyingItemPO() {
			return new Promise((resolve, reject) => {

				let itemIsValid = true;
				let itemIsExist = false;
				this.itemBuyingCopy.forEach((dt, idx) => {
					if(parseInt(dt.isChecked) === 1 || (dt.id_detail_po === 0 || dt.id_detail_po === null || dt.id_detail_po === undefined)) {
            if(dt.copied_to !== "" && dt.copied_to !== null && dt.copied_to !== undefined && dt.copied_to !== "PI") {
							Swal.fire({
								title: "Failed!",
								text: "Sorry you can't copy, because PO is already used in " + dt.copied_to,
								icon: 'warning',
								// showCancelButton: true,
								confirmButtonColor: 'rgb(221, 107, 85)',
								// cancelButtonColor: '#C1C1C1',
								// reverseButtons: true,
								confirmButtonText: 'Ok'
							})

							itemIsValid = false;
						}

						if(dt.item === "" || dt.price === "" || dt.qty === "" || dt.idJobfile === "") {
							Swal.fire({
							title: "Failed!",
							text: "Please insert field jobfile, item, price, and qty in item row " + (idx+1),
							icon: 'warning',
							// showCancelButton: true,
							confirmButtonColor: 'rgb(221, 107, 85)',
							// cancelButtonColor: '#C1C1C1',
							// reverseButtons: true,
							confirmButtonText: 'Ok'
							})

							itemIsValid = false;
						}

						if(parseInt(dt.qty === "" ? 0 : dt.qty) < 1) {
							Swal.fire({
								title: "Failed!",
								text: "Qty must be filled in item row " + (idx+1),
								icon: 'warning',
								// showCancelButton: true,
								confirmButtonColor: 'rgb(221, 107, 85)',
								// cancelButtonColor: '#C1C1C1',
								// reverseButtons: true,
								confirmButtonText: 'Ok'
							})

							itemIsValid = false;
						}

            if(dt.id_detail_po !== 0 && dt.id_detail_po !== null && dt.id_detail_po !== undefined) {
              if(parseInt(dt.stockQty === "" ? 0 : dt.stockQty) < parseInt(dt.qty === "" ? 0 : dt.qty)) {
                Swal.fire({
                  title: "Failed!",
                  text: "Qty cannot exceed stock in item row " + (idx+1),
                  icon: 'warning',
                  // showCancelButton: true,
                  confirmButtonColor: 'rgb(221, 107, 85)',
                  // cancelButtonColor: '#C1C1C1',
                  // reverseButtons: true,
                  confirmButtonText: 'Ok'
                })

                itemIsValid = false;
              }
            }

						itemIsExist = true
					}
				})

				if(!itemIsExist) {
					Swal.fire({
						title: "Failed!",
						text: "Item Must Be selected, at least one item!",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					itemIsValid = false;
				}

				if(!itemIsValid) {
					reject(console.log('required'))
				}

				resolve(true)
			})
		},
    validateBuyingItemRI() {
			return new Promise((resolve, reject) => {

				let itemIsValid = true;
				let itemIsExist = false;
				this.itemBuyingCopy.forEach((dt, idx) => {
					if(parseInt(dt.isChecked) === 1 || (dt.id_detail_ri === 0 || dt.id_detail_ri === null || dt.id_detail_ri === undefined)) {
            if(dt.copied_to !== "" && dt.copied_to !== null && dt.copied_to !== undefined && dt.copied_to !== "PI") {
							Swal.fire({
								title: "Failed!",
								text: "Sorry you can't copy, because PO is already used in " + dt.copied_to,
								icon: 'warning',
								// showCancelButton: true,
								confirmButtonColor: 'rgb(221, 107, 85)',
								// cancelButtonColor: '#C1C1C1',
								// reverseButtons: true,
								confirmButtonText: 'Ok'
							})

							itemIsValid = false;
						}

						if(dt.item === "" || dt.price === "" || dt.qty === "" || dt.idJobfile === "") {
							Swal.fire({
							title: "Failed!",
							text: "Please insert field jobfile, item, price, and qty in item row " + (idx+1),
							icon: 'warning',
							// showCancelButton: true,
							confirmButtonColor: 'rgb(221, 107, 85)',
							// cancelButtonColor: '#C1C1C1',
							// reverseButtons: true,
							confirmButtonText: 'Ok'
							})

							itemIsValid = false;
						}

						if(parseInt(dt.qty === "" ? 0 : dt.qty) < 1) {
							Swal.fire({
								title: "Failed!",
								text: "Qty must be filled in item row " + (idx+1),
								icon: 'warning',
								// showCancelButton: true,
								confirmButtonColor: 'rgb(221, 107, 85)',
								// cancelButtonColor: '#C1C1C1',
								// reverseButtons: true,
								confirmButtonText: 'Ok'
							})

							itemIsValid = false;
						}

            if(dt.id_detail_ri !== 0 && dt.id_detail_ri !== null && dt.id_detail_ri !== undefined) {
              if(parseInt(dt.stockQty === "" ? 0 : dt.stockQty) < parseInt(dt.qty === "" ? 0 : dt.qty)) {
                Swal.fire({
                  title: "Failed!",
                  text: "Qty cannot exceed stock in item row " + (idx+1),
                  icon: 'warning',
                  // showCancelButton: true,
                  confirmButtonColor: 'rgb(221, 107, 85)',
                  // cancelButtonColor: '#C1C1C1',
                  // reverseButtons: true,
                  confirmButtonText: 'Ok'
                })

                itemIsValid = false;
              }
            }

						itemIsExist = true
					}
				})

				if(!itemIsExist) {
					Swal.fire({
						title: "Failed!",
						text: "Item Must Be selected, at least one item!",
						icon: 'warning',
						// showCancelButton: true,
						confirmButtonColor: 'rgb(221, 107, 85)',
						// cancelButtonColor: '#C1C1C1',
						// reverseButtons: true,
						confirmButtonText: 'Ok'
					})

					itemIsValid = false;
				}

				if(!itemIsValid) {
					reject(console.log('required'))
				}

				resolve(true)
			})
		},
		isChangeQty: function(idx) {
			if(this.itemBuyingCopy[idx].qty !== "" && this.itemBuyingCopy[idx].price !== "") {
				this.itemBuyingCopy[idx].subTotal = this.itemBuyingCopy[idx].qty * this.itemBuyingCopy[idx].price
			}
      this.getTotalSub()
			console.log(this.itemBuyingCopy[idx].qty, 'this qty')
			// evt = (evt) ? evt : window.event;
			// var charCode = (evt.which) ? evt.which : evt.keyCode;
			// if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
			// 	evt.preventDefault();
			// } else {
			// 	console.log(this.itemBuying[idx].qty)
			// 	return true;
			// }
		},
    isChangeQtyEdit: function(idx) {
			if(this.dataSingleBuying.items[idx].qty !== "" && this.dataSingleBuying.items[idx].price !== "") {
				this.dataSingleBuying.items[idx].subTotal = this.dataSingleBuying.items[idx].qty * this.dataSingleBuying.items[idx].price
			}
			console.log(this.dataSingleBuying.items[idx].qty, 'this qty')
      this.getTotalSub()
			// evt = (evt) ? evt : window.event;
			// var charCode = (evt.which) ? evt.which : evt.keyCode;
			// if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
			// 	evt.preventDefault();
			// } else {
			// 	console.log(this.itemBuying[idx].qty)
			// 	return true;
			// }
		},
    isChangePrice: function(idx) {
      if(this.itemBuyingCopy[idx].price !== "" && this.itemBuyingCopy[idx].qty !== '' && this.dataBuyingCopy.commercialRate){
				this.itemBuyingCopy[idx].subTotal = this.itemBuyingCopy[idx].qty * this.itemBuyingCopy[idx].price * this.dataBuyingCopy.commercialRate
      }
			else if(this.itemBuyingCopy[idx].qty !== "" && this.itemBuyingCopy[idx].price !== "") {
				this.itemBuyingCopy[idx].subTotal = this.itemBuyingCopy[idx].qty * this.itemBuyingCopy[idx].price
			}
      this.getTotalSub()
			// evt = (evt) ? evt : window.event;
			// var charCode = (evt.which) ? evt.which : evt.keyCode;
			// if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
			// 	evt.preventDefault();
			// } else {
			// 	console.log(this.itemBuying[idx].qty)
			// 	return true;
			// }
		},
    isChangePriceEdit: function(idx) {
      console.log(this.dataSingleBuying,'datasingleitem');
      if(this.dataSingleBuying.items[idx].price !== "" && this.dataSingleBuying.items[idx].qty !== '' && this.dataSingleBuying.price_kurs !== '' && this.dataSingleBuying.price_kurs !== '0' && this.dataSingleBuying.price_kurs !== 0){
				this.dataSingleBuying.items[idx].subTotal = parseInt(this.dataSingleBuying.items[idx].qty) * parseInt(this.dataSingleBuying.items[idx].price) * parseInt(this.dataSingleBuying.price_kurs)
      }
			else if(this.dataSingleBuying.items[idx].qty !== "" && this.dataSingleBuying.items[idx].price !== "") {
        this.dataSingleBuying.items[idx].subTotal = parseInt(this.dataSingleBuying.items[idx].qty) * parseInt(this.dataSingleBuying.items[idx].price)
			}
      this.getTotalSub()
			// evt = (evt) ? evt : window.event;
			// var charCode = (evt.which) ? evt.which : evt.keyCode;
			// if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
			// 	evt.preventDefault();
			// } else {
			// 	console.log(this.itemBuying[idx].qty)
			// 	return true;
			// }
		},
    getTotalSub() {
			this.totalOfSubTotal = 0;
      console.log(this.statusModal,'statusmodal');
			if (this.statusModal === 'createPo') {
					this.currencySymbol = this.dataDefaultCurrency.symbol
          for (let a = 0; a < this.itemBuying.length; a++) {
            this.totalOfSubTotal = this.itemBuyingCopy[a].subTotal + this.totalOfSubTotal
          }
			} else if (this.statusModal === 'edit'){
          this.currencySymbol = this.dataDefaultCurrency.symbol
          for (let a = 0; a < this.dataSingleBuying.items.length; a++) {
            this.totalOfSubTotal = this.dataSingleBuying.items[a].subTotal + this.totalOfSubTotal
          }
      }
			console.log(this.currencySymbol,'currsymb');
			this.totalOfSubTotal = this.formatRupiah(this.totalOfSubTotal.toString(), this.currencySymbol + ' ')
		},
    onCompletePO() {
			let itemSelected = []
			this.itemBuyingCopy.forEach((dt, idx) => {
				if(parseInt(dt.isChecked) === 1 || ((dt.id_detail_ri === 0 || dt.id_detail_ri === null || dt.id_detail_ri === undefined) && (dt.id_detail_po === 0 || dt.id_detail_po === null || dt.id_detail_po === undefined))) {
					if(dt.remarks === undefined) {
						dt.remarks = ''
					}
					itemSelected.push(dt)
				}
			})

      // itemSelected.forEach((dtSelect) => {
      //   dtSelect.price = dtSelect.actualAmount
      // })

			// this.isLoadingForm = true

			// let idJoborder = window.location.href.split("/").splice(0, 6)[5];

			let mergeData = {
				// id_joborder: idJoborder,
				id_default: this.dataDefaultCurrency.id,
				buying: this.dataBuyingCopy,
				itemBuying: itemSelected
			}

			console.log(mergeData, 'THIS MERGE DATA')

			let headers = {
				'Content-Type': 'application/json',
			}

			this.saveBuyingManual({
					payload: mergeData,
					headers: headers
				})
				.then((res) => {
					this.isLoadingForm = false
					if (res.data.status) {
					const Toast = Swal.mixin({
						toast: true,
						position: 'bottom-end',
						showConfirmButton: false,
						timer: 2000,
						timerProgressBar: true,
						onOpen: (toast) => {
						toast.addEventListener('mouseenter', Swal.stopTimer)
						toast.addEventListener('mouseleave', Swal.resumeTimer)
						}
					})

					Toast.fire({
						icon: 'success',
						title: 'successfully'
					})

					this.$nextTick(() => {
						this.$bvModal.hide('modalPurchaseOrder')
						this.$bvModal.hide('modalReceiveItem')
						this.$bvModal.hide('modalTransactionType')
					})

					this.$nextTick(() => this.$refs.vuetable.refresh())
					// let idJoborder = window.location.href.split("/").splice(0, 6)[5];
					// this.getListBuying(idJoborder)
					} else {
						this.$nextTick(() => {
							this.$bvModal.hide('modalPurchaseOrder')
              this.$bvModal.hide('modalReceiveItem')
							this.$bvModal.hide('modalTransactionType')
						})

						this.$nextTick(() => this.$refs.vuetable.refresh())

						Swal.fire({
							title: "Failed!",
							text: res.data.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
				.catch((err) => {
					this.isLoadingForm = false
					Swal.fire({
						title: "Failed!",
						text: err.response.data.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				})
		},
    showOriginalNumber(data) {
      this.getListNumberReff(data.id_jobfile_cost)
        .then((res) => {
          console.log(res, 'THIS RES')
          this.$bvModal.show('modalNumberReff')
        })
    },
    showModalSwapItem(data) {
      console.log(data,'asdas')
      this.swap.ppn = ''
      this.swap.pph=''
      this.swap.docCode=''
      this.swap.taxType=''
      this.swap.taxNo=''
      this.swap.taxDate=''
      this.swap.id_jobfile_cost = data.id_jobfile_cost
      this.swap.id_down_payment = data.id_down_payment
      this.showTaxDetail = false
      this.showTaxInput = false

      this.getListDocCode()
      this.$bvModal.show('modalSetupTaxes')
    },
    submitInfoBuyingUpdate() {

    },
    openModalJournalVoucher(id) {
      this.getListAutonumberJV(4)

      this.dataJournalVoucher.id_buying = id

      this.getBuying(id)
          .then((res) => {
            this.dataJournalVoucher.memo = this.dataSingleBuying.pi_number
            this.dataJournalVoucher.date = new Date()

            this.$nextTick(() => {
              this.$bvModal.show('modalJournalVoucher')
            })
          })
    },
    sendToJournalVoucher(bvModalEvent) {
      bvModalEvent.preventDefault()

      let headers = {
        'Content-Type': 'application/json'
      }

      this.sendToJV({
          payload: this.dataJournalVoucher,
          headers: headers
        })
        .then((res) => {
          console.log(res.data, 'datanya')
          if (res.data.status === true) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'successfully'
            })

            this.$nextTick(() => {
              this.$bvModal.hide('modalJournalVoucher')
            })

            this.$nextTick(() => this.$refs.vuetable.refresh())
          } else {
            Swal.fire({
              title: "Failed!",
              text: res.data.message,
              icon: 'warning',
              confirmButtonColor: 'rgb(221, 107, 85)',
              confirmButtonText: 'Ok'
            })

            this.$nextTick(() => {
              this.$bvModal.hide('modalJournalVoucher')
            })

            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        })
        .catch((err) => {
          console.log(err.response.data)
          Swal.fire({
            title: "Failed!",
            text: err.response.data.message,
            icon: 'warning',
            confirmButtonColor: 'rgb(221, 107, 85)',
            confirmButtonText: 'Ok'
          })
        })
    },
    filterPI() {
      this.moreParams = {
        status: this.statusType,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    // canAccess(permissionName) {
		//   let Permission =  JSON.parse(localStorage.getItem('userPermissions'))

		// 	return Permission.includes(permissionName)
		// },
  },
}
</script>
<style>
  .modal-xl {
    max-width: 95% !important;
  }
  .btn-cancel {
      /* width: 90px; */
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      height: 34px;
      background-color: #E0E0E0;
      color: #817F7F;
      border: 0;
      border-radius: 3px;
  }

  .btn-next {
      /* width: 90px; */
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      height: 34px;
      background-color: #51C1AD !important;
      color: #fff;
      border: 0;
      border-radius: 3px;
  }

  .btn-finish {
      /* width: 120px; */
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      height: 34px;
      background-color: #51C1AD;
      color: #fff;
      border: 0;
      border-radius: 3px;
  }

  .add-loading {
      background-color: #FFF2D9;
      border-radius: 5px;
      padding: 1em;
  }

  .add-unloading {
      background-color: #FFF2D9;
      border-radius: 5px;
      padding: 1em;
  }

  .btn-new-transaction {
      background-color: #F9A707;
      margin-right: -3.5rem;
  }

  .btn-new-transaction:hover {
      color: white;
  }

  .box {
      height: 200px;
      width: 100%;
      background-color: white;
      margin: 0 auto;
      top: 200px;
      text-align: center;
      align-content: center;
      margin-top: 120px;
  }

  .image-truck {
      width: 60px;
      margin-left: 35px;
  }

  .wizard-icon-container i {
      font-style: normal;
  }

  .wizard-icon-circle i {
      font-style: normal;
  }

  /* .wizard-icon-circle.checked {
    color: rgb(249, 167, 7);
  } */

  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
      border-color: #f1556c;

  }
  .vs__dropdown-option {
    white-space :inherit !important;
  }
  .__style-dete-required {
      border: 1px solid #f1556c;
      border-radius: 4px;
  }

  #modalBuyingDownPayment___BV_modal_footer_ {
    display: none;
  }

  #modalPurchaseOrder___BV_modal_footer_ {
    display: none;
  }

  #modalReceiveItem___BV_modal_footer_ {
    display: none;
  }

  #modalBuyingEdit___BV_modal_footer_ {
    display: none;
  }

  #modalNumberReff___BV_modal_footer_ {
    display: none;
  }

  .btn-action {
    /* width: 25px;
    height: 25px;
    padding: 0; */
  }

  .table-button-container {
    min-width: 150px;
  }

  .pagination .btn {
    color: #000 !important;
  }

  .pagination .btn.text-white {
    color: #FFF !important;
  }

  #modalBuying___BV_modal_body_ {
    padding: 0;
  }

  .information-dp {
    width: 100%;
    height: 50px;
    background: #E2F4F1;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .new-card .card-body {
    padding-left: 10px;
    padding-right: 10px;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: var(--vs-disabled-bg);
    opacity: 1;
  }

  .dropdown-toggle{
    white-space: nowrap;
    padding: 0;
  }
  .button-po .btn-btn-drop {
    background-color: #cffdf5;
    border-color: #cffdf5;
  }

  .button-po .show>.btn-btn-drop.dropdown-toggle {
    background-color: #cffdf5;
    border-color: #cffdf5;
  }

  .button-po .btn-btn-drop:hover {
    background-color: #cffdf5;
    border-color: #cffdf5;
  }

  .button-po .btn-btn-drop:not(:disabled):not(.disabled):active,
  .btn-btn-drop:not(:disabled):not(.disabled).active,
  .show>.btn-btn-drop.dropdown-toggle {
    background-color: #cffdf5;
    border-color: #cffdf5;
  }
</style>
